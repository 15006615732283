var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var yG,DG,ada,bda,cda,dda,eda,fda;
$CLJS.wG=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.$B([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.xG=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.YF(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};yG=function(a){return $CLJS.yd(a)&&$CLJS.B(a)instanceof $CLJS.K};
$CLJS.AG=function(a){var b=function(){var c=yG(a);return c?(c=$CLJS.wd($CLJS.hd(a)))?(c=$CLJS.yi.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.iD.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.zG.h(a)};
DG=function(a,b){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,2,[$CLJS.vu,"valid MBQL clause",$CLJS.xu,function(c){c=$CLJS.O(c);c=$CLJS.I.g(c,$CLJS.wj);return["invalid MBQL clause: ",$CLJS.Eh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ke(yG)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.BG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,b],null),function(c){c=$CLJS.AG(c);
return CG(c,a)}],null)],null)};$CLJS.EG=new $CLJS.K(null,"right-join","right-join",-56349359);ada=new $CLJS.K("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);bda=new $CLJS.K("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);cda=new $CLJS.K("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.FG=new $CLJS.K(null,"fields","fields",-1932066230);
$CLJS.GG=new $CLJS.K(null,"expr","expr",745722291);$CLJS.HG=new $CLJS.K("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.IG=new $CLJS.K("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.JG=new $CLJS.K(null,"alias","alias",-2039751630);$CLJS.KG=new $CLJS.K("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.LG=new $CLJS.K("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.MG=new $CLJS.K("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.NG=new $CLJS.K("lib","expression-name","lib/expression-name",-1799326590);$CLJS.OG=new $CLJS.K("mbql.clause","field","mbql.clause/field",1497292735);dda=new $CLJS.K("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.PG=new $CLJS.K(null,"left-join","left-join",-672831855);$CLJS.QG=new $CLJS.K("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.RG=new $CLJS.K("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.SG=new $CLJS.K("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
eda=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);fda=new $CLJS.K("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.TG=new $CLJS.K(null,"inner-join","inner-join",659431740);$CLJS.UG=new $CLJS.K("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);
$CLJS.BG=new $CLJS.K("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.VG=new $CLJS.K("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.WG=new $CLJS.K(null,"full-join","full-join",1305476385);$CLJS.XG=new $CLJS.K("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);
$CLJS.YG=new $CLJS.K("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.zG=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.aj,$CLJS.WD],null),$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.UD(f);return $CLJS.F.g(k,$CLJS.hD)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.Y(bda,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.MG],null),$CLJS.oG],null));$CLJS.zG.m(null,$CLJS.Wh,function(a){throw $CLJS.ai($CLJS.YF("{0}: Don''t know how to determine the type of {1}",$CLJS.G([eda,$CLJS.Eh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.GG,a],null));});$CLJS.zG.m(null,$CLJS.RG,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.AG(a)});
var CG=function CG(a,b){return $CLJS.ud(a)?$CLJS.Je(function(d){return CG.g?CG.g(d,b):CG.call(null,d,b)},a):$CLJS.ud(b)?$CLJS.Je(function(d){return CG.g?CG.g(a,d):CG.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.MG)?!0:$CLJS.hE(a,b)};$CLJS.Y($CLJS.KG,DG($CLJS.Fj,"expression returning a boolean"));$CLJS.Y($CLJS.VG,DG($CLJS.Xj,"expression returning a string"));$CLJS.Y($CLJS.YG,DG($CLJS.pj,"expression returning an integer"));$CLJS.Y(cda,DG($CLJS.DF,"expression returning a non-integer real number"));
$CLJS.Y($CLJS.XG,DG($CLJS.Jj,"expression returning a number"));$CLJS.Y(dda,DG($CLJS.IE,"expression returning a date"));$CLJS.Y(fda,DG($CLJS.GF,"expression returning a time"));$CLJS.Y(ada,DG($CLJS.gF,"expression returning a date time"));$CLJS.Y($CLJS.IG,DG($CLJS.Qj,"expression returning a date, time, or date time"));$CLJS.ZG=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.Jj,null,$CLJS.Xj,null,$CLJS.Qj,null],null),null);$CLJS.Y($CLJS.SG,DG($CLJS.ZG,"an expression that can be compared with :\x3e or :\x3c"));
var gda=new $CLJS.Tg(null,new $CLJS.h(null,7,[$CLJS.rE,null,$CLJS.Jj,null,$CLJS.Xj,null,$CLJS.YE,null,$CLJS.Qj,null,$CLJS.Fj,null,$CLJS.BF,null],null),null);$CLJS.Y($CLJS.UG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null),DG($CLJS.YE,"expression returning a BSONID")],null));$CLJS.Y($CLJS.QG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,DG(gda,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.Y($CLJS.LG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,DG($CLJS.tj,"any type of expression")],null));
$CLJS.Y($CLJS.HG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.On,1],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.zj,$CLJS.ei,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NG,$CLJS.Tj],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ns,$CLJS.ei],null)],null)],null)],null));