var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var JF,KF,LF,Zca,MF,OF,PF;
$CLJS.IF=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.P.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};JF=function(a,b){this.db=a;this.vh=b;this.C=393216;this.I=0};KF=function(a){for(var b=null;;){var c;a=$CLJS.no.g(a,b);a=c=$CLJS.Gn(a)?$CLJS.An(a):a;if(!$CLJS.Gn(c))return a}};LF=function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return!($CLJS.wd(a)&&!0===$CLJS.Et.h(a))};
Zca=function(a,b,c){var d=KF($CLJS.no.g(a,c));return $CLJS.tq($CLJS.rn(d),$CLJS.on(d),function(){var e=$CLJS.qn(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.pn($CLJS.no.g(d,null));return $CLJS.n(e)?e:c}())};MF=function(a){return function(b,c,d){var e=$CLJS.z(c);b=$CLJS.B(e);e=$CLJS.C(e);c=$CLJS.Hk.g(function(f){return $CLJS.no.g(f,d)},c);return new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.Hk.g($CLJS.cu,c),$CLJS.ab(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.NF=function(a,b,c,d,e,f,k,l,m,t,u,v,x,A,D,J,M,V,Z){this.form=a;this.Me=b;this.options=c;this.qe=d;this.forms=e;this.U=f;this.Xd=k;this.ya=l;this.Ue=m;this.children=t;this.Li=u;this.min=v;this.Eb=x;this.Rb=A;this.parent=D;this.type=J;this.cache=M;this.max=V;this.Uh=Z;this.C=393216;this.I=0};OF=function(a,b,c,d,e,f,k,l,m){this.Ue=a;this.qe=b;this.type=c;this.min=d;this.max=e;this.Xd=f;this.Eb=k;this.Rb=l;this.Me=m;this.C=393216;this.I=0};
PF=function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.fj),d=$CLJS.I.g(b,$CLJS.On),e=$CLJS.I.g(b,$CLJS.ck),f=$CLJS.I.g(b,$CLJS.yu),k=$CLJS.I.g(b,$CLJS.Vp),l=$CLJS.I.g(b,$CLJS.et);return new OF(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.fj,$CLJS.Ap],null))};$CLJS.Y=function(a,b){$CLJS.Gh.v(QF,$CLJS.S,a,b);$CLJS.Pe($CLJS.RF,$CLJS.N);return null};JF.prototype.P=function(a,b){return new JF(this.db,b)};JF.prototype.O=function(){return this.vh};JF.prototype.rd=$CLJS.r;
JF.prototype.bd=function(a,b){return $CLJS.bn($CLJS.dn($CLJS.q(this.db)),b)};var SF=function SF(a){switch(arguments.length){case 2:return SF.g(arguments[0],arguments[1]);case 3:return SF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};SF.g=function(a,b){return SF.j(a,b,null)};
SF.j=function(a,b,c){function d(v,x){return $CLJS.F.g($CLJS.bk,v)?new $CLJS.Q(null,2,5,$CLJS.R,[null,x],null):$CLJS.Ye.g(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Iq.h(x)],null),$CLJS.Kq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.Xk.l($CLJS.G([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?KF($CLJS.no.g(a,c)):null;b=$CLJS.n(b)?KF($CLJS.no.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.Un.h(a):null,k=$CLJS.n(b)?$CLJS.Un.h(b):null,l=$CLJS.O(c),m=$CLJS.I.j(l,$CLJS.tu,function(v,x){return x}),t=$CLJS.I.j(l,$CLJS.wu,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Sa(function(){var v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ot,null,$CLJS.bk,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.ot,null,$CLJS.bk,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.F.g(f,$CLJS.bk)&&$CLJS.F.g(k,$CLJS.bk)){l=e($CLJS.on(a),$CLJS.on(b));var u=$CLJS.Oe($CLJS.Ug);a=$CLJS.ab(function(v,x){var A=
$CLJS.H(x,0,null);if($CLJS.n(function(){var D=$CLJS.q(u);return D.h?D.h(A):D.call(null,A)}()))return $CLJS.ab(function(D,J){var M=$CLJS.H(J,0,null),V=$CLJS.$d.g;if($CLJS.F.g(M,A)){M=$CLJS.H(J,0,null);var Z=$CLJS.H(J,1,null),fa=$CLJS.H(J,2,null);$CLJS.H(x,0,null);var Da=$CLJS.H(x,1,null),Ha=$CLJS.H(x,2,null);J=LF(J);var mb=LF(x);J=t.g?t.g(J,mb):t.call(null,J,mb);Z=$CLJS.Xk.l($CLJS.G([Z,Da]));Ha=new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.S.j(Z,$CLJS.Et,$CLJS.Sa(J)),SF.j?SF.j(fa,Ha,c):SF.call(null,fa,Ha,
c)],null);M=$CLJS.H(Ha,0,null);fa=$CLJS.H(Ha,1,null);Ha=$CLJS.H(Ha,2,null);M=$CLJS.Sa(Ha)?new $CLJS.Q(null,2,5,$CLJS.R,[M,fa],null):$CLJS.n($CLJS.n(fa)?!1===$CLJS.Et.h(fa)&&$CLJS.F.g(1,$CLJS.E(fa)):fa)?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):$CLJS.Sa($CLJS.z(fa))?new $CLJS.Q(null,2,5,$CLJS.R,[M,Ha],null):!1===$CLJS.Et.h(fa)?new $CLJS.Q(null,3,5,$CLJS.R,[M,$CLJS.xk.g(fa,$CLJS.Et),Ha],null):new $CLJS.Q(null,3,5,$CLJS.R,[M,fa,Ha],null)}else M=J;return V.call($CLJS.$d,D,M)},$CLJS.tf,v);$CLJS.Gh.j(u,
$CLJS.$d,A);return $CLJS.$d.g(v,x)},$CLJS.tf,$CLJS.Wf.g($CLJS.qn(a),$CLJS.qn(b)));return $CLJS.tq($CLJS.bk,l,a,c)}return function(v,x){var A=$CLJS.z(v);v=$CLJS.B(A);var D=$CLJS.C(A);A=$CLJS.B(D);D=$CLJS.C(D);var J=$CLJS.z(x);x=$CLJS.B(J);var M=$CLJS.C(J);J=$CLJS.B(M);M=$CLJS.C(M);return $CLJS.tq($CLJS.ot,e(v,x),$CLJS.Ye.l(new $CLJS.Q(null,1,5,$CLJS.R,[SF.j(A,J,c)],null),D,$CLJS.G([M])),c)}(d(f,a),d(k,b))};SF.A=3;
var TF=function TF(a){switch(arguments.length){case 2:return TF.g(arguments[0],arguments[1]);case 3:return TF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};TF.g=function(a,b){return TF.j(a,b,null)};
TF.j=function(a,b,c){return SF.j(a,b,$CLJS.Ik.j($CLJS.Ik.j(c,$CLJS.tu,$CLJS.IF($CLJS.Rd,function(d,e,f){var k=$CLJS.F.g($CLJS.cu.g(d,null),$CLJS.cu.g(e,null));return $CLJS.n(k)?d:$CLJS.no.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,d,e],null),f)})),$CLJS.wu,$CLJS.IF($CLJS.Rd,function(d,e){return $CLJS.n(d)?e:d})))};TF.A=3;
var UF=function UF(a){switch(arguments.length){case 2:return UF.g(arguments[0],arguments[1]);case 3:return UF.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};UF.g=function(a,b){return UF.j(a,b,null)};UF.j=function(a,b,c){var d=$CLJS.Yg(b);return Zca(a,function(e){return $CLJS.bf(function(f){f=$CLJS.H(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};UF.A=3;$CLJS.g=$CLJS.NF.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.NF(this.form,this.Me,this.options,this.qe,this.forms,this.U,this.Xd,this.ya,this.Ue,this.children,this.Li,this.min,this.Eb,this.Rb,this.parent,this.type,this.cache,this.max,b)};$CLJS.g.O=function(){return this.Uh};$CLJS.g.Ya=$CLJS.r;$CLJS.g.xa=function(){return $CLJS.ln(this.ya)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.Wo(this,new $CLJS.Q(null,1,5,$CLJS.R,[this.ya],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ta=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.mn(this.ya,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.r;$CLJS.g.ia=$CLJS.ta(5);$CLJS.g.ie=$CLJS.r;$CLJS.g.Kd=function(){return this.ya};$CLJS.g=OF.prototype;$CLJS.g.P=function(a,b){return new OF(this.Ue,this.qe,this.type,this.min,this.max,this.Xd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Me};$CLJS.g.Xa=$CLJS.r;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.Pn(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.uf(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.H(a,0,null),k=$CLJS.H(a,1,null),l=$CLJS.H(a,2,null);return new $CLJS.NF(new $CLJS.Bh(function(){return $CLJS.to(e.type,b,k,d)}),e.Me,d,e.qe,k,b,e.Xd,l,e.Ue,f,a,e.min,e.Eb,e.Rb,this,e.type,$CLJS.so(),e.max,new $CLJS.h(null,1,[$CLJS.fj,$CLJS.Zp],null))};
$CLJS.VF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.WF=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);var QF;$CLJS.RF=$CLJS.Oe($CLJS.N);
QF=$CLJS.Oe($CLJS.Xk.l($CLJS.G([$CLJS.At(),new $CLJS.h(null,3,[$CLJS.uu,PF(new $CLJS.h(null,2,[$CLJS.fj,$CLJS.uu,$CLJS.et,MF(SF)],null)),$CLJS.su,PF(new $CLJS.h(null,2,[$CLJS.fj,$CLJS.su,$CLJS.et,MF(TF)],null)),$CLJS.zu,PF(new $CLJS.h(null,5,[$CLJS.fj,$CLJS.zu,$CLJS.yu,1,$CLJS.On,2,$CLJS.ck,2,$CLJS.et,function(a,b,c){return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ik.j(b,0,function(d){return $CLJS.no.g(d,c)}),$CLJS.Ik.j(b,0,function(d){return $CLJS.cu.g(d,c)}),$CLJS.P.g(UF,$CLJS.$d.g(b,c))],null)}],null))],
null)])));$CLJS.Pe($CLJS.ll,$CLJS.dn(new JF(QF,$CLJS.N)));