var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var Rla,Sla,s3,t3,Tla,u3,v3;
$CLJS.o3=function(a){return $CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.Q0,a):$CLJS.X0.call(null,$CLJS.Q0,a))?$CLJS.b3:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.M0,a):$CLJS.X0.call(null,$CLJS.M0,a))?$CLJS.b3:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.J0,a):$CLJS.X0.call(null,$CLJS.J0,a))?$CLJS.Bla:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.T0,a):$CLJS.X0.call(null,$CLJS.T0,a))?$CLJS.Cla:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.S0,a):$CLJS.X0.call(null,$CLJS.S0,a))?$CLJS.Dla:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.I0,a):$CLJS.X0.call(null,
$CLJS.I0,a))?$CLJS.Ela:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.N0,a):$CLJS.X0.call(null,$CLJS.N0,a))?$CLJS.Hla:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.F0,a):$CLJS.X0.call(null,$CLJS.F0,a))?$CLJS.Fla:$CLJS.n($CLJS.X0.g?$CLJS.X0.g($CLJS.H0,a):$CLJS.X0.call(null,$CLJS.H0,a))?$CLJS.Gla:$CLJS.Ila};$CLJS.p3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.K?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.q3=function(a,b){a=$CLJS.p3(a);b=$CLJS.n(b)?b:$CLJS.pv;return 0===a?$CLJS.F.g(b,$CLJS.pv)?$CLJS.xG("Today"):$CLJS.YF("This {0}",$CLJS.G([$CLJS.j3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.xG("Tomorrow"):$CLJS.YF("Next {0}",$CLJS.G([$CLJS.j3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.pv)?$CLJS.xG("Yesterday"):$CLJS.YF("Previous {0}",$CLJS.G([$CLJS.j3.h(b)])):0>a?$CLJS.YF("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.j3.g(Math.abs(a),b)])):0<a?$CLJS.YF("Next {0} {1}",$CLJS.G([a,$CLJS.j3.g(a,
b)])):null};$CLJS.r3=function(a){var b=$CLJS.o3(a);b=$CLJS.De(b);return null==b?a:$CLJS.S.j(a,$CLJS.$M,b)};Rla=new $CLJS.K(null,"legacy-filter","legacy-filter",-948552546);Sla=new $CLJS.K(null,"query-filters","query-filters",409521440);s3=new $CLJS.K("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);t3=new $CLJS.K("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);Tla=new $CLJS.K(null,"matching-filters","matching-filters",-326129659);
u3=new $CLJS.K("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);v3=new $CLJS.K("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var w3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,$CLJS.ht],null)),x3=null,y3=0,z3=0;;)if(z3<y3){var Ula=x3.X(null,z3);$CLJS.eH(Ula,u3);z3+=1}else{var A3=$CLJS.z(w3);if(A3){var B3=A3;if($CLJS.zd(B3)){var C3=$CLJS.kc(B3),Vla=$CLJS.lc(B3),Wla=C3,Xla=$CLJS.E(C3);w3=Vla;x3=Wla;y3=Xla}else{var Yla=$CLJS.B(B3);$CLJS.eH(Yla,u3);w3=$CLJS.C(B3);x3=null;y3=0}z3=0}else break}
for(var D3=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.nI],null)),E3=null,F3=0,G3=0;;)if(G3<F3){var Zla=E3.X(null,G3);$CLJS.eH(Zla,t3);G3+=1}else{var H3=$CLJS.z(D3);if(H3){var I3=H3;if($CLJS.zd(I3)){var J3=$CLJS.kc(I3),$la=$CLJS.lc(I3),ama=J3,bma=$CLJS.E(J3);D3=$la;E3=ama;F3=bma}else{var cma=$CLJS.B(I3);$CLJS.eH(cma,t3);D3=$CLJS.C(I3);E3=null;F3=0}G3=0}else break}
for(var K3=$CLJS.z(new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.Gs,$CLJS.Is,$CLJS.Cs,$CLJS.Es,$CLJS.NH,$CLJS.gI,$CLJS.VH,$CLJS.OH],null)),L3=null,M3=0,N3=0;;)if(N3<M3){var dma=L3.X(null,N3);$CLJS.eH(dma,v3);N3+=1}else{var O3=$CLJS.z(K3);if(O3){var P3=O3;if($CLJS.zd(P3)){var Q3=$CLJS.kc(P3),ema=$CLJS.lc(P3),fma=Q3,gma=$CLJS.E(Q3);K3=ema;L3=fma;M3=gma}else{var hma=$CLJS.B(P3);$CLJS.eH(hma,v3);K3=$CLJS.C(P3);L3=null;M3=0}N3=0}else break}
for(var R3=$CLJS.z(new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.wH,$CLJS.IH,$CLJS.yH,$CLJS.FH,$CLJS.jt],null)),S3=null,T3=0,U3=0;;)if(U3<T3){var ima=S3.X(null,U3);$CLJS.eH(ima,s3);U3+=1}else{var V3=$CLJS.z(R3);if(V3){var W3=V3;if($CLJS.zd(W3)){var X3=$CLJS.kc(W3),jma=$CLJS.lc(W3),kma=X3,lma=$CLJS.E(X3);R3=jma;S3=kma;T3=lma}else{var mma=$CLJS.B(W3);$CLJS.eH(mma,s3);R3=$CLJS.C(W3);S3=null;T3=0}U3=0}else break}
$CLJS.V1.m(null,$CLJS.LX,function(a,b){var c=$CLJS.De($CLJS.LX.h($CLJS.hY(a,b)));return $CLJS.n(c)?$CLJS.YF("Filtered by {0}",$CLJS.G([$CLJS.g3($CLJS.xG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.u1.v(a,b,v,$CLJS.v1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.u1.v(a,b,t,$CLJS.v1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});
$CLJS.S1.m(null,u3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.g3(function(){var k=e instanceof $CLJS.K?e.T:null;switch(k){case "and":return $CLJS.xG("and");case "or":return $CLJS.xG("or");default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}(),function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.zd(t)){var u=$CLJS.kc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.kd(u,A);D=$CLJS.u1.v(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.lc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.u1.v(a,b,x,d),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())});
$CLJS.S1.m(null,t3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.Ze.g(function(k){return $CLJS.u1.v(a,b,k,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.YF("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.YF("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.g3($CLJS.xG("or"),
$CLJS.Kc(e)),c=c instanceof $CLJS.K?c.T:null,c){case "\x3d":return $CLJS.YF("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.YF("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.S1.m(null,v3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.u1.v(a,b,f,d);a=$CLJS.u1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "\x3c":return $CLJS.YF("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.YF("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.YF("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.YF("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.YF("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.YF("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.YF("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.YF("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.S1.m(null,$CLJS.xH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.YF("{0} is between {1} and {2}",$CLJS.G([$CLJS.u1.v(a,b,e,d),$CLJS.u1.v(a,b,f,d),$CLJS.u1.v(a,b,c,d)]))});
$CLJS.S1.m(null,$CLJS.DH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),k=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.u1.v(a,b,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,e,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xH,e,f,t,l],null),new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.xH,e,k,m,c],null)],null),d)});
$CLJS.S1.m(null,s3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.u1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "is-null":return $CLJS.YF("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.YF("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.YF("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.YF("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.YF("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.S1.m(null,$CLJS.hI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.YF("{0} is within {1}",$CLJS.G([$CLJS.u1.v(a,b,e,d),$CLJS.q3(f,c).toLowerCase()]))});
$CLJS.Y3=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.f3($CLJS.Js,$CLJS.ce(c,$CLJS.ce(d,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Z3=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.UD(f),$CLJS.uM))f=$CLJS.TX(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.UX.h(f),$CLJS.qY.l(d,e,$CLJS.Ik,$CLJS.G([$CLJS.LX,$CLJS.IF($CLJS.$d,$CLJS.tf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.$3=function(){function a(d,e){return $CLJS.De($CLJS.LX.h($CLJS.hY(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.a4=function(){function a(d,e){var f=$CLJS.hY(d,e);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Fk.g($CLJS.Ze.h($CLJS.r3),$CLJS.af($CLJS.$M)),$CLJS.c2.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.b4=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){c=$CLJS.wd(c)?$CLJS.PH.h(c):$CLJS.rh.h(c);return $CLJS.SX($CLJS.Wf.g(new $CLJS.Q(null,3,5,$CLJS.R,[c,$CLJS.N,$CLJS.UX.h(d)],null),$CLJS.Ze.g($CLJS.UX,e)))}a.A=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.nma=function(){function a(d,e,f){var k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.hY(d,e);l=$CLJS.c2.j(d,e,l);d=$CLJS.Y2.v(d,e,f,l);d=$CLJS.fJ(function(m){return $CLJS.F.g($CLJS.PH.h(m),k)},$CLJS.o3(d));return $CLJS.n(d)?d:$CLJS.a3.h(k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;
c.j=a;return c}();
$CLJS.oma=function(){function a(d,e,f){var k=$CLJS.e_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.zH],null),f);d=$CLJS.uf($CLJS.$3.g(d,e));e=$CLJS.bf(function(l){return $CLJS.F.g($CLJS.e_(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jO,$CLJS.zH],null),$CLJS.o_.h(l)),k)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.ai("Multiple matching filters found",new $CLJS.h(null,3,[Rla,k,Sla,d,Tla,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.pma=function(){function a(d,e,f){f=$CLJS.U_.j(d,e,f);d=$CLJS.a4.g(d,e);return $CLJS.Y2.g(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();