var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var V_,zka,Aka,Bka,Cka,Dka,a0,Eka,Fka,Gka,d0,Hka,Ika,Jka,i0,j0,Kka,Lka,Mka,s0,Nka,Oka,Pka,v0,Qka,Rka,Ska,Tka;V_=function(){};$CLJS.W_=function(a){return null!=a?$CLJS.r===a.fi?!0:a.Vc?!1:$CLJS.Wa(V_,a):$CLJS.Wa(V_,a)};zka=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.X_=new $CLJS.K("lib","source-column-alias","lib/source-column-alias",1494821386);
Aka=new $CLJS.K("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Bka=new $CLJS.K("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.Y_=new $CLJS.K("source","previous-stage","source/previous-stage",1962610878);$CLJS.Z_=new $CLJS.K(null,"database-id","database-id",1883826326);Cka=new $CLJS.w("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.$_=new $CLJS.K("source","table-defaults","source/table-defaults",909975194);Dka=new $CLJS.K(null,"human-readable-values","human-readable-values",-624842907);a0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Eka=new $CLJS.K("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Fka=new $CLJS.K(null,"dbms-version","dbms-version",-69238036);
Gka=new $CLJS.K(null,"field-name","field-name",1300687948);$CLJS.b0=new $CLJS.K("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.c0=new $CLJS.K(null,"dataset","dataset",1159262238);d0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Hka=new $CLJS.K(null,"field-definitions","field-definitions",1650155116);$CLJS.e0=new $CLJS.K("source","expressions","source/expressions",-458367840);
$CLJS.f0=new $CLJS.K(null,"definition","definition",-1198729982);Ika=new $CLJS.K(null,"state","state",-1988618099);$CLJS.g0=new $CLJS.K("source","implicitly-joinable","source/implicitly-joinable",-729101197);Jka=new $CLJS.K(null,"is-audit","is-audit",327058420);$CLJS.h0=new $CLJS.K("source","joins","source/joins",1225821486);i0=new $CLJS.K("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
j0=new $CLJS.K("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.k0=new $CLJS.K("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.l0=new $CLJS.K(null,"fk-target-field-id","fk-target-field-id",2019750287);Kka=new $CLJS.K("lib","external-remap","lib/external-remap",543358316);$CLJS.m0=new $CLJS.K(null,"selected?","selected?",-742502788);
Lka=new $CLJS.K("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Mka=new $CLJS.K("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.n0=new $CLJS.K(null,"table-name","table-name",-2117866341);$CLJS.o0=new $CLJS.K("source","card","source/card",-139977973);$CLJS.p0=new $CLJS.K(null,"active","active",1895962068);$CLJS.q0=new $CLJS.K(null,"dataset-query","dataset-query",1851066427);
$CLJS.r0=new $CLJS.K(null,"result-metadata","result-metadata",2017934672);s0=new $CLJS.K("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Nka=new $CLJS.K(null,"details","details",1956795411);$CLJS.t0=new $CLJS.K("source","fields","source/fields",-649667981);Oka=new $CLJS.K("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.u0=new $CLJS.K("source","native","source/native",-1444604147);
Pka=new $CLJS.K("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);v0=new $CLJS.K("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.w0=new $CLJS.K("lib","card-id","lib/card-id",-1770167062);Qka=new $CLJS.K("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Rka=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
Ska=new $CLJS.K("lib","internal-remap","lib/internal-remap",-220033801);Tka=new $CLJS.K("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.x0=new $CLJS.K(null,"source-alias","source-alias",1652088724);$CLJS.y0=new $CLJS.K(null,"engine","engine",1459054265);$CLJS.Y(i0,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.dt,$CLJS.o0,$CLJS.u0,$CLJS.Y_,$CLJS.$_,$CLJS.t0,$CLJS.LM,$CLJS.FM,$CLJS.h0,$CLJS.e0,$CLJS.g0],null));$CLJS.Y(s0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,Tka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.yM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zM,$CLJS.JM],null)],null));
$CLJS.Y(a0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,Qka],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.yM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$t,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,$CLJS.ei],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[Dka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,$CLJS.ei],null)],null)],null));
$CLJS.Y($CLJS.b0,new $CLJS.Q(null,18,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid column metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.qM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.Tj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iD,$CLJS.oG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.JM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.gt,$CLJS.Tj],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.oG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.CM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.JM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.l0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.JM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.x0,
new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,i0],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.w0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.AM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.X_,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],
null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.k0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,2,[$CLJS.On,1,$CLJS.ck,60],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.m0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[Kka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,s0],null)],null)],
null),new $CLJS.Q(null,3,5,$CLJS.R,[Ska,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,a0],null)],null)],null)],null));
$CLJS.Y(v0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n0,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[Hka,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[Gka,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.iD,$CLJS.oG],null)],null)],null)],null)],null)],null));
$CLJS.Y(d0,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.p0,$CLJS.yt],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ika,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n0,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.f0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,v0],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,
$CLJS.gG],null)],null)],null));
$CLJS.Y(Oka,new $CLJS.Q(null,11,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid Card metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.KM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.AM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Z_,$CLJS.BM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.q0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.bk],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.r0,
new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,$CLJS.bk],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.c0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.vM],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Bka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,d0],null)],null)],null)],null));
$CLJS.Y(Lka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid Segment metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.uM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.tM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DM,$CLJS.vM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.bk],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Su,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null)],null));
$CLJS.Y(Mka,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid Metric metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.wM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.rM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.DM,$CLJS.vM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.f0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.bk],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.Su,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null)],null));
$CLJS.Y(Pka,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid Table metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.xM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.vM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fl,new $CLJS.h(null,
1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null)],null));
$CLJS.Y(Aka,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"Valid Database metadata"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.HM],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,$CLJS.BM],null),new $CLJS.Q(null,3,5,$CLJS.R,[Fka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.bk],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Nka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.bk],null),new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.y0,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.Pi],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ZB,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Si,$CLJS.Pi],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[Jka,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EM,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.bk],null)],null)],null));
$CLJS.Y(j0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,"Valid MetadataProvider"],null),new $CLJS.Fc(function(){return $CLJS.W_},Cka,$CLJS.Ag([$CLJS.Oi,$CLJS.T,$CLJS.hk,$CLJS.oi,$CLJS.Mi,$CLJS.gj,$CLJS.ok,$CLJS.ni,$CLJS.U,$CLJS.gk],[zka,Rka,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Pq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.W_)?$CLJS.W_.H:null]))],null));
$CLJS.Y(Eka,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,j0],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.h(null,1,[$CLJS.vu,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ZF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,j0],null)],null)],null)],null));