var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.uL=new $CLJS.K("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.vL=new $CLJS.K("operator","filter","operator/filter",-1518842858);$CLJS.wL=new $CLJS.K(null,"display-name-variant","display-name-variant",-1831788853);for(var xL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ot,$CLJS.ht],null)),yL=null,zL=0,AL=0;;)if(AL<zL){var Rea=yL.X(null,AL);$CLJS.nH(Rea,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null)],null)]));AL+=1}else{var BL=$CLJS.z(xL);if(BL){var CL=BL;if($CLJS.zd(CL)){var DL=$CLJS.kc(CL),Sea=$CLJS.lc(CL),
Tea=DL,Uea=$CLJS.E(DL);xL=Sea;yL=Tea;zL=Uea}else{var Vea=$CLJS.B(CL);$CLJS.nH(Vea,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null)],null)]));xL=$CLJS.C(CL);yL=null;zL=0}AL=0}else break}$CLJS.lH($CLJS.jt,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)]));
for(var EL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.nI],null)),FL=null,GL=0,HL=0;;)if(HL<GL){var Wea=FL.X(null,HL);$CLJS.nH(Wea,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null)],null)],null)]));HL+=1}else{var IL=$CLJS.z(EL);if(IL){var JL=IL;if($CLJS.zd(JL)){var KL=$CLJS.kc(JL),Xea=$CLJS.lc(JL),
Yea=KL,Zea=$CLJS.E(KL);EL=Xea;FL=Yea;GL=Zea}else{var $ea=$CLJS.B(JL);$CLJS.nH($ea,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.QG],null)],null)],null)],null)]));EL=$CLJS.C(JL);FL=null;GL=0}HL=0}else break}
for(var LL=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Gs,$CLJS.Is,$CLJS.Cs,$CLJS.Es],null)),ML=null,NL=0,OL=0;;)if(OL<NL){var afa=ML.X(null,OL);$CLJS.lH(afa,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)]));OL+=1}else{var PL=$CLJS.z(LL);if(PL){var QL=PL;if($CLJS.zd(QL)){var RL=$CLJS.kc(QL),bfa=$CLJS.lc(QL),cfa=RL,dfa=$CLJS.E(RL);LL=bfa;ML=cfa;NL=dfa}else{var efa=$CLJS.B(QL);$CLJS.lH(efa,$CLJS.G([$CLJS.lu,$CLJS.Fj,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)]));LL=$CLJS.C(QL);ML=null;NL=0}OL=0}else break}$CLJS.lH($CLJS.xH,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)]));
$CLJS.lH($CLJS.DH,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)]));
for(var SL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wH,$CLJS.IH],null)),TL=null,UL=0,VL=0;;)if(VL<UL){var ffa=TL.X(null,VL);$CLJS.lH(ffa,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)]));VL+=1}else{var WL=$CLJS.z(SL);if(WL){var XL=WL;if($CLJS.zd(XL)){var YL=$CLJS.kc(XL),gfa=$CLJS.lc(XL),hfa=YL,ifa=$CLJS.E(YL);SL=gfa;TL=hfa;UL=ifa}else{var jfa=$CLJS.B(XL);$CLJS.lH(jfa,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)]));SL=$CLJS.C(XL);
TL=null;UL=0}VL=0}else break}
for(var ZL=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yH,$CLJS.FH],null)),$L=null,aM=0,bM=0;;)if(bM<aM){var kfa=$L.X(null,bM);$CLJS.lH(kfa,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));bM+=1}else{var cM=$CLJS.z(ZL);if(cM){var dM=cM;if($CLJS.zd(dM)){var eM=$CLJS.kc(dM),lfa=$CLJS.lc(dM),mfa=eM,nfa=$CLJS.E(eM);ZL=lfa;$L=mfa;aM=nfa}else{var ofa=$CLJS.B(dM);$CLJS.lH(ofa,$CLJS.G([$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.UG],null)]));ZL=$CLJS.C(dM);
$L=null;aM=0}bM=0}else break}
for(var fM=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.dI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null)],null),gM=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.NH,$CLJS.gI,$CLJS.VH,$CLJS.OH],null)),hM=null,iM=0,jM=0;;)if(jM<iM){var kM=hM.X(null,jM);$CLJS.wI.v(kM,$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,kM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.lG,fM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,
$CLJS.VG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null));jM+=1}else{var lM=$CLJS.z(gM);if(lM){var mM=lM;if($CLJS.zd(mM)){var nM=$CLJS.kc(mM),pfa=$CLJS.lc(mM),qfa=nM,rfa=$CLJS.E(nM);gM=pfa;hM=qfa;iM=rfa}else{var oM=$CLJS.B(mM);$CLJS.wI.v(oM,$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,oM],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.lG,fM],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null),new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.VG],null)],null));gM=$CLJS.C(mM);hM=null;iM=0}jM=0}else break}
$CLJS.wI.v($CLJS.hI,$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.hI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,$CLJS.lG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.kI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.vx,$CLJS.Oz,$CLJS.CH],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,$CLJS.YG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WF],null)],null));$CLJS.wI.v($CLJS.tH,$CLJS.lu,$CLJS.Fj,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.kt,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.tH],null),$CLJS.lG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.$F,$CLJS.gG],null)],null));
$CLJS.Y($CLJS.uL,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.vL],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PH,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.dt,$CLJS.Js,$CLJS.nI,$CLJS.DH,$CLJS.xH,$CLJS.Gs,$CLJS.Cs,$CLJS.Is,$CLJS.Es,$CLJS.wH,$CLJS.IH,$CLJS.yH,$CLJS.FH,$CLJS.VH,$CLJS.OH,$CLJS.NH,$CLJS.gI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wL,$CLJS.Pi],null)],null));