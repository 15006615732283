import styled from "@emotion/styled";

import { Icon } from "metabase/core/components/Icon";

import { alpha, color } from "metabase/lib/colors";
import { FullWidthContainer } from "metabase/styled-components/layout/FullWidthContainer";

export const Root = styled(FullWidthContainer)<{ admin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  // background-color: ${props => alpha(color(props.admin ? "accent7" : "brand"), 0.85)};
  background-color: ${color("admin-navbar")};                                   // Change Color for Header in Dashboard Editor Page

  .Button {
    // color: ${color("text-white")};
    // background-color: ${alpha(color("bg-white"), 0.1)};
    color: ${color("admin-navbar")};                                            // Change Color for Button Cancel in Model Editor Page
    background-color: ${color("accent7-light")};                                // Change Color for Button Cancel in Model Editor Page

    border: none;
    font-size: 1em;
    margin-left: 0.75em;
  }

  .Button--primary {
    // color: ${props => color(props.admin ? "text-dark" : "brand")};
    // background-color: ${color("bg-white")};
    color: ${props => color(props.admin ? "text-dark" : "admin-navbar")};       // Change Color for Button Save in Model Editor Page
    background-color: #FFAB00;                                                  // Change Color for Button Save in Model Editor Page
  }

  .Button:hover {
    // color: ${color("text-white")};
    // background-color: ${props => color(props.admin ? "accent7" : "brand")};
    color: ${color("admin-navbar")};                                            // Change Color for Button Cancel when Hover in Model Editor Page
    background-color: ${color("accent7")};                                      // Change Color for Button Cancel when Hover in Model Editor Page
  }

  .Button--primary:hover {
    color: ${color("admin-navbar")};                                            // Change Color for Button Save when Hover in Model Editor Page
    background-color: #D58E00;                                                  // Change Color for Button Save when Hover in Model Editor Page
  }
`;

export const EditIcon = styled(Icon)`
  color: ${color("text-white")};
  margin-right: 0.5rem;
`;

export const Title = styled.span`
  color: ${color("text-white")};
  font-weight: 700;
`;

export const Subtitle = styled.span`
  color: ${alpha(color("text-white"), 0.5)};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
