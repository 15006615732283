var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var O4,Q4,R4,S4,$ma;O4=function(a,b,c){var d=$CLJS.hY(a,b);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.LH);d=$CLJS.fJ($CLJS.Fk.j($CLJS.Vg([c]),$CLJS.mG,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ai($CLJS.YF("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.xt,c,$CLJS.jO,a,$CLJS.nY,b],null));return d};$CLJS.P4=function(a){return $CLJS.f3($CLJS.iI,new $CLJS.Q(null,1,5,$CLJS.R,[a],null))};$CLJS.Zma=new $CLJS.K(null,"aggregation-index","aggregation-index",-1057045587);
Q4=new $CLJS.K("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);R4=new $CLJS.K("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);S4=new $CLJS.K("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);$ma=new $CLJS.K(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.V1.m(null,$CLJS.LH,function(a,b){var c=$CLJS.De($CLJS.LH.h($CLJS.hY(a,b)));return $CLJS.n(c)?$CLJS.g3($CLJS.xG("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.zd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.u1.v(a,b,v,$CLJS.v1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.lc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.u1.v(a,
b,t,$CLJS.v1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.Y1.m(null,$CLJS.LH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.iD);e=$CLJS.I.g(e,$CLJS.yi);c=$CLJS.H(c,2,null);c=O4(a,b,c);return $CLJS.Xk.l($CLJS.G([$CLJS.t1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.sM,$CLJS.LM,$CLJS.Q1,$CLJS.mG.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.iD,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.yi,e],null):null]))});
$CLJS.S1.m(null,$CLJS.LH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.u1.v(a,b,O4(a,b,c),d)});$CLJS.eH(R4,S4);
for(var T4=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Kz,$CLJS.XH],null)),U4=null,V4=0,W4=0;;)if(W4<V4){var ana=U4.X(null,W4);$CLJS.eH(ana,R4);W4+=1}else{var X4=$CLJS.z(T4);if(X4){var Y4=X4;if($CLJS.zd(Y4)){var Z4=$CLJS.kc(Y4),bna=$CLJS.lc(Y4),cna=Z4,dna=$CLJS.E(Z4);T4=bna;U4=cna;V4=dna}else{var ena=$CLJS.B(Y4);$CLJS.eH(ena,R4);T4=$CLJS.C(Y4);U4=null;V4=0}W4=0}else break}
$CLJS.S1.m(null,R4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.u1.v(a,b,c,d),e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.YF("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.YF("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.K?e.T:null,e){case "count":return $CLJS.xG("Count");case "cum-count":return $CLJS.xG("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.T1.m(null,R4,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.K?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.Y1.m(null,R4,function(a,b,c){var d=$CLJS.S.j,e=$CLJS.Uh($CLJS.Y1,S4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.S,a,$CLJS.Pj,$CLJS.JE)});$CLJS.S1.m(null,$CLJS.EH,function(){return $CLJS.xG("Case")});
$CLJS.T1.m(null,$CLJS.EH,function(){return"case"});$CLJS.eH(Q4,S4);for(var $4=$CLJS.z(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.QH,$CLJS.sI,$CLJS.AH,$CLJS.ck,$CLJS.jI,$CLJS.On,$CLJS.lI,$CLJS.iI,$CLJS.BH],null)),a5=null,b5=0,c5=0;;)if(c5<b5){var fna=a5.X(null,c5);$CLJS.eH(fna,Q4);c5+=1}else{var d5=$CLJS.z($4);if(d5){var e5=d5;if($CLJS.zd(e5)){var f5=$CLJS.kc(e5),gna=$CLJS.lc(e5),hna=f5,ina=$CLJS.E(f5);$4=gna;a5=hna;b5=ina}else{var jna=$CLJS.B(e5);$CLJS.eH(jna,Q4);$4=$CLJS.C(e5);a5=null;b5=0}c5=0}else break}
$CLJS.T1.m(null,Q4,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.K?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.S1.m(null,Q4,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.u1.v(a,b,c,d);e=e instanceof $CLJS.K?e.T:null;switch(e){case "avg":return $CLJS.YF("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.YF("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.YF("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.YF("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.YF("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.YF("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.YF("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.YF("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.YF("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.S1.m(null,$CLJS.HH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.YF("{0}th percentile of {1}",$CLJS.G([c,$CLJS.u1.v(a,b,e,d)]))});$CLJS.T1.m(null,$CLJS.HH,function(){return"percentile"});
$CLJS.eH($CLJS.HH,S4);$CLJS.S1.m(null,$CLJS.qI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.YF("Sum of {0} matching condition",$CLJS.G([$CLJS.u1.v(a,b,e,d)]))});$CLJS.T1.m(null,$CLJS.qI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.U1.j(a,b,d))].join("")});$CLJS.eH($CLJS.qI,S4);$CLJS.S1.m(null,$CLJS.cI,function(){return $CLJS.xG("Share of rows matching condition")});
$CLJS.T1.m(null,$CLJS.cI,function(){return"share"});$CLJS.eH($CLJS.cI,S4);$CLJS.S1.m(null,$CLJS.eI,function(){return $CLJS.xG("Count of rows matching condition")});$CLJS.T1.m(null,$CLJS.eI,function(){return"count-where"});$CLJS.eH($CLJS.eI,S4);
$CLJS.Y1.m(null,S4,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.Xk.l;d=$CLJS.n(d)?$CLJS.Ul($CLJS.t1.j(a,b,d),new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.EM],null)):null;var f=$CLJS.Uh($CLJS.Y1,$CLJS.Wh);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.Xk,$CLJS.G([d,a]))});
$CLJS.kna=function(){function a(d){return $CLJS.f3($CLJS.Kz,new $CLJS.Q(null,1,5,$CLJS.R,[d],null))}function b(){return $CLJS.f3($CLJS.Kz,$CLJS.tf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.RX.m(null,$CLJS.LH,function(a){return a});
$CLJS.g5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.UD(f),$CLJS.wM))f=$CLJS.TX(f);else return $CLJS.Q2(d,e,$CLJS.LH,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.h5=function(){function a(d,e){return $CLJS.De($CLJS.LH.h($CLJS.hY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.i5=function(){function a(d,e){var f=$CLJS.De($CLJS.LH.h($CLJS.hY(d,e)));return null==f?null:$CLJS.Wf.j($CLJS.tf,$CLJS.Ze.h(function(k){var l=$CLJS.t1.j(d,e,k),m=$CLJS.S.l,t=$CLJS.iD.h(l);return m.call($CLJS.S,$CLJS.e3(l,$CLJS.yi,$CLJS.n(t)?t:$CLJS.tj),$CLJS.sM,$CLJS.LM,$CLJS.G([$CLJS.Q1,$CLJS.mG.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.S1.m(null,$CLJS.EI,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.AI);return $CLJS.cG.h(a.o?a.o():a.call(null))});$CLJS.Z1.m(null,$CLJS.EI,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.I.g(d,$CLJS.PH);b=$CLJS.I.g(d,$CLJS.AI);c=$CLJS.I.g(d,$CLJS.zI);d=$CLJS.I.g(d,$CLJS.m0);a=$CLJS.S.l(b.o?b.o():b.call(null),$CLJS.P1,$CLJS.PC(a),$CLJS.G([$CLJS.N1,c]));return null!=d?$CLJS.S.j(a,$CLJS.C1,d):a});
$CLJS.lna=function(){function a(d,e){var f=function(){var m=$CLJS.ZB.h($CLJS.j2($CLJS.p1(d)));return $CLJS.n(m)?m:$CLJS.Ug}(),k=$CLJS.hY(d,e),l=$CLJS.c2.j(d,e,k);return $CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Fk.j($CLJS.af(function(m){m=$CLJS.CI.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ml(function(m){m=$CLJS.O(m);var t=$CLJS.I.g(m,$CLJS.zI),u=$CLJS.I.g(m,$CLJS.xI);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.ei))return $CLJS.S.j(m,$CLJS.eY,l);t=$CLJS.De($CLJS.d3(function(v){return $CLJS.X0(u,
v)},l));return $CLJS.n(t)?$CLJS.S.j(m,$CLJS.eY,t):null}),$CLJS.Ze.h(function(m){return $CLJS.S.j(m,$CLJS.xj,$CLJS.EI)})),$CLJS.FI))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.mna=function(){function a(d,e){return $CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.PH.h(d),$CLJS.N,$CLJS.UX.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.zI.h(d)))return $CLJS.SX(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PH.h(d),$CLJS.N],null));var e=$CLJS.PH.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ai(e,new $CLJS.h(null,1,[$ma,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();