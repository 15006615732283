var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var rK,tK,vK,HK,LK,TK;$CLJS.qK=new $CLJS.K(null,"get-month","get-month",-369374731);rK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.sK=new $CLJS.K(null,"convert-timezone","convert-timezone",-124803001);tK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.uK=new $CLJS.K(null,"iso","iso",-1366207543);vK=new $CLJS.K("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.wK=new $CLJS.K(null,"get-second","get-second",-2065946318);$CLJS.xK=new $CLJS.K(null,"get-year","get-year",-936011274);$CLJS.yK=new $CLJS.K(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.zK=new $CLJS.K("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.AK=new $CLJS.K(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.BK=new $CLJS.K(null,"datetime-diff","datetime-diff",1133112155);$CLJS.CK=new $CLJS.K(null,"interval","interval",1708495417);$CLJS.DK=new $CLJS.K(null,"us","us",746429226);$CLJS.EK=new $CLJS.K(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.FK=new $CLJS.K(null,"datetime","datetime",494675702);$CLJS.GK=new $CLJS.K(null,"now","now",-1650525531);HK=new $CLJS.K("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.IK=new $CLJS.K(null,"instance","instance",-2121349050);$CLJS.JK=new $CLJS.K(null,"get-day","get-day",127568857);$CLJS.KK=new $CLJS.K(null,"get-week","get-week",-888059349);
LK=new $CLJS.K("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.MK=new $CLJS.K(null,"datetime-add","datetime-add",209603411);$CLJS.NK=new $CLJS.K(null,"get-minute","get-minute",1468086343);$CLJS.OK=new $CLJS.K(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.PK=new $CLJS.K("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.QK=new $CLJS.K(null,"temporal-extract","temporal-extract",1781686405);$CLJS.RK=new $CLJS.K(null,"get-hour","get-hour",622714059);$CLJS.SK=new $CLJS.K(null,"datetime-subtract","datetime-subtract",275307697);TK=new $CLJS.K("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.UK=new $CLJS.K(null,"get-quarter","get-quarter",1327778593);$CLJS.VK=new $CLJS.K(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.WK=new $CLJS.K(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.XK=new $CLJS.K(null,"target","target",253001721);$CLJS.lH($CLJS.CK,$CLJS.G([$CLJS.lu,$CLJS.TE,$CLJS.ut,$CLJS.WF]));$CLJS.zG.m(null,$CLJS.zK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.AG(a);return $CLJS.ud(a)?(a=$CLJS.zv.g(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.IE,null,$CLJS.gF,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.eH($CLJS.zK,$CLJS.RG);
for(var YK=$CLJS.z(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.MK,$CLJS.SK],null)),ZK=null,$K=0,aL=0;;)if(aL<$K){var bL=ZK.X(null,aL);$CLJS.lH(bL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null),$CLJS.ut,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WF],null)]));$CLJS.eH(bL,$CLJS.zK);aL+=1}else{var cL=$CLJS.z(YK);if(cL){var dL=cL;if($CLJS.zd(dL)){var eL=$CLJS.kc(dL),Eea=$CLJS.lc(dL),Fea=eL,Gea=$CLJS.E(eL);YK=Eea;ZK=Fea;$K=Gea}else{var fL=$CLJS.B(dL);$CLJS.lH(fL,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.X,$CLJS.IG],null),$CLJS.ut,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WF],null)]));$CLJS.eH(fL,$CLJS.zK);YK=$CLJS.C(dL);ZK=null;$K=0}aL=0}else break}
for(var gL=$CLJS.z(new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.xK,$CLJS.qK,$CLJS.JK,$CLJS.RK,$CLJS.NK,$CLJS.wK,$CLJS.UK],null)),hL=null,iL=0,jL=0;;)if(jL<iL){var Hea=hL.X(null,jL);$CLJS.lH(Hea,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));jL+=1}else{var kL=$CLJS.z(gL);if(kL){var lL=kL;if($CLJS.zd(lL)){var mL=$CLJS.kc(lL),Iea=$CLJS.lc(lL),Jea=mL,Kea=$CLJS.E(mL);gL=Iea;hL=Jea;iL=Kea}else{var Lea=$CLJS.B(lL);$CLJS.lH(Lea,$CLJS.G([$CLJS.lu,
$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));gL=$CLJS.C(lL);hL=null;iL=0}jL=0}else break}$CLJS.lH($CLJS.BK,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.NI],null)]));
for(var nL=$CLJS.z(new $CLJS.Tg(null,new $CLJS.h(null,8,[$CLJS.UK,null,$CLJS.NK,null,$CLJS.RK,null,$CLJS.OK,null,$CLJS.wK,null,$CLJS.qK,null,$CLJS.xK,null,$CLJS.JK,null],null),null)),oL=null,pL=0,qL=0;;)if(qL<pL){var Mea=oL.X(null,qL);$CLJS.lH(Mea,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));qL+=1}else{var rL=$CLJS.z(nL);if(rL){var sL=rL;if($CLJS.zd(sL)){var tL=$CLJS.kc(sL),Nea=$CLJS.lc(sL),Oea=tL,Pea=$CLJS.E(tL);
nL=Nea;oL=Oea;pL=Pea}else{var Qea=$CLJS.B(sL);$CLJS.lH(Qea,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)]));nL=$CLJS.C(sL);oL=null;pL=0}qL=0}else break}$CLJS.Y(TK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.uK,$CLJS.DK,$CLJS.IK],null));
$CLJS.nH($CLJS.KK,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,TK],null)],null)],null)],null)]));
$CLJS.Y($CLJS.PK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,$CLJS.gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,2,[$CLJS.vu,"valid timezone ID",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.wj);return["invalid timezone ID: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.HI($CLJS.Hd,$CLJS.pK.tz.names())),$CLJS.bK],null)],null));
$CLJS.nH($CLJS.sK,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TB,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.PK],null)],null)],null)],
null)]));$CLJS.eH($CLJS.sK,$CLJS.zK);$CLJS.lH($CLJS.GK,$CLJS.G([$CLJS.lu,$CLJS.LE]));$CLJS.Y(vK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.oG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Je(function(b){return $CLJS.hE(a,b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.IE,$CLJS.gF],null))}],null)],null));
$CLJS.Y(rK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,vK],null)],null)],null)],null));
$CLJS.wI.g($CLJS.yK,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.vu,"valid :absolute-datetime clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.yK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,rK],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Xs,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,
4,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.fK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.hK],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.$J],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.OI],null)],null)],null)],null),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.vu,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.vx],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.jK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.Wh],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KI],null)],null)],null)],null)],null)],null));
$CLJS.zG.m(null,$CLJS.yK,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.vx)?$CLJS.F.g(a,$CLJS.Wh)?$CLJS.gF:$CLJS.n($CLJS.dH($CLJS.OI,a))?$CLJS.IE:$CLJS.gF:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.fh($CLJS.nK,b))?$CLJS.IE:$CLJS.n($CLJS.fh($CLJS.oK,b))?$CLJS.IE:null:null;if($CLJS.n(a))return a;b=$CLJS.AG(b);b=$CLJS.ud(b)?$CLJS.Wf.j($CLJS.Ug,$CLJS.af(function(c){return $CLJS.hE(c,$CLJS.Qj)}),b):b;return $CLJS.ud(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.Y(LK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.vx],null),$CLJS.ut],null));
$CLJS.nH($CLJS.EK,$CLJS.G([$CLJS.lu,$CLJS.gF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,LK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.WF],null)],null)],null)],null)]));
$CLJS.lH($CLJS.yA,$CLJS.G([$CLJS.lu,$CLJS.GF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.VF],null)]));$CLJS.Y(tK,new $CLJS.Q(null,12,5,$CLJS.R,[$CLJS.dt,$CLJS.MI,$CLJS.ti,$CLJS.ek,$CLJS.AK,$CLJS.VK,$CLJS.WK,$CLJS.Aj,$CLJS.zi,$CLJS.pi,$CLJS.bj,$CLJS.JI],null));$CLJS.Y(HK,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.uK,$CLJS.DK,$CLJS.IK],null));
$CLJS.nH($CLJS.QK,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.FK,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,tK],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ri,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,HK],null)],null)],
null)],null)]));