var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var c6,g6,h6,s6,u6,v6,Yna,w6,F6,Q6,R6,S6;c6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);$CLJS.d6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.e6=new $CLJS.K("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);$CLJS.f6=new $CLJS.K(null,"row","row",-570139521);
g6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details.fk-column","metabase.lib.schema.drill-thru/drill-thru.fk-details.fk-column",-380335831);h6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row.value","metabase.lib.schema.drill-thru/context.row.value",1438731480);$CLJS.i6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.j6=new $CLJS.K(null,"pivots","pivots",90109371);
$CLJS.k6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);$CLJS.l6=new $CLJS.K("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.m6=new $CLJS.K(null,"location","location",1815599388);$CLJS.n6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);
$CLJS.o6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);$CLJS.p6=new $CLJS.K(null,"initial-op","initial-op",918033121);$CLJS.q6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.r6=new $CLJS.K("drill-thru","column-filter","drill-thru/column-filter",1535293733);
s6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);$CLJS.t6=new $CLJS.K("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);u6=new $CLJS.K("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);v6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries.next-unit","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries.next-unit",-2018118498);
Yna=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);w6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.object-details","metabase.lib.schema.drill-thru/drill-thru.object-details",-725614444);$CLJS.x6=new $CLJS.K("drill-thru","sort","drill-thru/sort",511059541);$CLJS.y6=new $CLJS.K("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);$CLJS.z6=new $CLJS.K(null,"sort-directions","sort-directions",300459345);
$CLJS.A6=new $CLJS.K(null,"aggregations","aggregations",-1081114338);$CLJS.B6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);$CLJS.C6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);$CLJS.D6=new $CLJS.K(null,"row-count","row-count",1060167988);$CLJS.E6=new $CLJS.K("drill-thru","distribution","drill-thru/distribution",1480876450);
F6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.quick-filter.operator","metabase.lib.schema.drill-thru/drill-thru.quick-filter.operator",-1269703311);$CLJS.G6=new $CLJS.K("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);$CLJS.H6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
$CLJS.I6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);$CLJS.J6=new $CLJS.K(null,"next-unit","next-unit",166270362);$CLJS.K6=new $CLJS.K("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);$CLJS.L6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);
$CLJS.M6=new $CLJS.K("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.N6=new $CLJS.K("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);$CLJS.O6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);$CLJS.P6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);
Q6=new $CLJS.K("metabase.lib.schema.drill-thru","context.row","metabase.lib.schema.drill-thru/context.row",-1467255282);R6=new $CLJS.K("metabase.lib.schema.drill-thru","drill-thru.summarize-column.aggregation-type","metabase.lib.schema.drill-thru/drill-thru.summarize-column.aggregation-type",1395206778);S6=new $CLJS.K("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.Y(S6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.pI,$CLJS.m6,$CLJS.yA],null));$CLJS.Y(c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,$CLJS.ee],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.eN],null)],null)],null));
$CLJS.Y(w6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.YM,$CLJS.ei],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.XM,$CLJS.yt],null)],null)],null));
$CLJS.Y($CLJS.d6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,w6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.bN],null)],null)],null)],null));$CLJS.Y(g6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.l0,$CLJS.JM],null)],null)],null));
$CLJS.Y($CLJS.B6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,w6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.cN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,g6],null)],null)],null)],null));
$CLJS.Y($CLJS.I6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,w6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.gN],null)],null)],null)],null));$CLJS.Y(F6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.T,$CLJS.gG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)],null));
$CLJS.Y($CLJS.C6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.e6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.$M,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,F6],null)],null)],null)],null));
$CLJS.Y($CLJS.H6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.t6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zH,$CLJS.KG],null)],null)],null));
$CLJS.Y($CLJS.i6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.E6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null)],null)],null));
$CLJS.Y($CLJS.k6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.M6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.j6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mt,S6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.P6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.x6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,$CLJS.ZM],null)],null)],null)],null));$CLJS.Y(R6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dt,$CLJS.QH,$CLJS.AH,$CLJS.iI],null));
$CLJS.Y($CLJS.q6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.y6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,R6],null)],null)],null)],null)],null));
$CLJS.Y($CLJS.o6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.K6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.KH,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,$CLJS.QI],null)],null)],null));
$CLJS.Y($CLJS.n6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.r6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.p6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.uL],null)],null)],null)],null));
$CLJS.Y($CLJS.O6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.G6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.D6,$CLJS.ql],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.n0,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.Va],null)],null)],null)],null));
$CLJS.Y(s6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,u6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.eN],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null)],null)],null));
$CLJS.Y(v6,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dt,$CLJS.ii,$CLJS.qj,$CLJS.hj,$CLJS.pv,$CLJS.ov,$CLJS.nv],null));
$CLJS.Y($CLJS.L6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,c6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.N6],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,$CLJS.Ta],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.J6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,v6],null)],null)],null)],null));
$CLJS.Y(Yna,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,c6,new $CLJS.Q(null,16,5,$CLJS.R,[$CLJS.nt,new $CLJS.h(null,2,[$CLJS.Ii,$CLJS.fj,$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.wj);return["Invalid drill thru (unknown :type): ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bN,$CLJS.d6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.cN,$CLJS.B6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gN,$CLJS.I6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.e6,$CLJS.C6],null),new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.t6,$CLJS.H6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E6,$CLJS.i6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.M6,$CLJS.k6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.x6,$CLJS.P6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.y6,$CLJS.q6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.K6,$CLJS.o6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.r6,$CLJS.n6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.G6,$CLJS.O6],null),new $CLJS.Q(null,2,5,$CLJS.R,[u6,s6],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.N6,
$CLJS.L6],null)],null)],null));$CLJS.Y(h6,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GH,$CLJS.Va],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,$CLJS.ei],null)],null));$CLJS.Y(Q6,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,h6],null)],null));
$CLJS.Y($CLJS.l6,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mi,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.b0],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.f6,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,Q6],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,Q6],null)],null)],null)],null));