var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var YJ,ZJ,sea,tea,uea,cK,dK,eK,gK,vea,iK;YJ=function(a){switch(arguments.length){case 2:return $CLJS.dH(arguments[0],arguments[1]);case 3:return $CLJS.cH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};ZJ=function(a){return["(?:",$CLJS.p.h($CLJS.P.g($CLJS.p,a)),")?"].join("")};sea=new $CLJS.K("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
tea=new $CLJS.K("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.$J=new $CLJS.K("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);uea=new $CLJS.K("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.aK=new $CLJS.K("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.bK=new $CLJS.K("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);cK=new $CLJS.K("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);dK=new $CLJS.K("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);eK=new $CLJS.K("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.fK=new $CLJS.K("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);gK=new $CLJS.K("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);vea=new $CLJS.K("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.hK=new $CLJS.K("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
iK=new $CLJS.K("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.jK=new $CLJS.K("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.zG.m(null,$CLJS.lD,function(){return $CLJS.tj});$CLJS.Y(iK,$CLJS.yt);$CLJS.zG.m(null,$CLJS.oD,function(){return $CLJS.Fj});$CLJS.Y(iK,$CLJS.yt);$CLJS.Y(vea,$CLJS.ut);$CLJS.zG.m(null,$CLJS.aD,function(){return $CLJS.pj});$CLJS.Y(tea,$CLJS.tt);$CLJS.zG.m(null,$CLJS.ZC,function(){return $CLJS.DF});$CLJS.Y(uea,$CLJS.Tj);
var wea=[":\\d{2}",ZJ($CLJS.G(["\\.\\d{1,6}"]))].join(""),kK=["\\d{2}:\\d{2}",ZJ($CLJS.G([wea]))].join(""),lK=["\\d{4}-\\d{2}-\\d{2}T",kK].join(""),mK=["(?:Z|(?:[+-]",kK,"))"].join(""),xea=$CLJS.hh(["(?:Z|(?:[+-]",kK,"))"].join("")),yea=$CLJS.hh("^\\d{4}-\\d{2}-\\d{2}$"),zea=$CLJS.hh(["^",kK,"$"].join("")),Aea=$CLJS.hh(["^",kK,mK,"$"].join("")),Bea=$CLJS.hh(["^",lK,"$"].join("")),Cea=$CLJS.hh(["^",lK,mK,"$"].join(""));
$CLJS.Y(dK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"date string literal"],null),yea],null));$CLJS.Y($CLJS.bK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"timezone offset string literal"],null),xea],null));
$CLJS.Y(cK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"local time string literal"],null),zea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"offset time string literal"],null),Aea],null)],null));
$CLJS.Y(eK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"local date time string literal"],null),Bea],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"offset date time string literal"],null),Cea],null)],null));
$CLJS.zG.m(null,$CLJS.$C,function(a){return $CLJS.n($CLJS.dH?$CLJS.dH(eK,a):YJ.call(null,eK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.Xj,null,$CLJS.gF,null],null),null):$CLJS.n($CLJS.dH?$CLJS.dH(dK,a):YJ.call(null,dK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.IE,null,$CLJS.Xj,null],null),null):$CLJS.n($CLJS.dH?$CLJS.dH(cK,a):YJ.call(null,cK,a))?new $CLJS.Tg(null,new $CLJS.h(null,2,[$CLJS.GF,null,$CLJS.Xj,null],null),null):$CLJS.Xj});$CLJS.Y($CLJS.fK,dK);$CLJS.Y($CLJS.aK,cK);
$CLJS.Y($CLJS.jK,eK);$CLJS.Y(sea,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ht,$CLJS.fK,$CLJS.aK,$CLJS.jK],null));$CLJS.nK=$CLJS.hh("^\\d{4}-\\d{2}$");$CLJS.Y($CLJS.hK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"year-month string literal"],null),$CLJS.nK],null));$CLJS.oK=$CLJS.hh("^\\d{4}$");$CLJS.Y($CLJS.$J,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.it,new $CLJS.h(null,1,[$CLJS.vu,"year string literal"],null),$CLJS.oK],null));
$CLJS.Y(gK,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.uu,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.yi,$CLJS.oG],null)],null)],null));$CLJS.wI.g($CLJS.wj,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.vu,"Value :value clause"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.wj],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,gK],null),$CLJS.sl],null));