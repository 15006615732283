var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var yda,zda,Ada,Bda,Cda,Dda,Eda,Fda,Gda;yda=function(a,b){a.sort(b||$CLJS.Ca)};zda=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ca;yda(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.GI=function(a){return $CLJS.F.g(a,$CLJS.Hd)?$CLJS.Hd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.HI=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);zda(c,$CLJS.GI(a));return $CLJS.od($CLJS.z(c),$CLJS.pd(b))}return $CLJS.Jc};$CLJS.II=new $CLJS.K(null,"second","second",-444702010);$CLJS.JI=new $CLJS.K(null,"second-of-minute","second-of-minute",222734326);
$CLJS.KI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.LI=new $CLJS.K(null,"millisecond","millisecond",-540123566);$CLJS.MI=new $CLJS.K(null,"year-of-era","year-of-era",682445876);Ada=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Bda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.NI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.OI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
Cda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Dda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Eda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
Fda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.PI=new $CLJS.K("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.QI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.RI=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Gda=new $CLJS.K("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var SI=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.zi,$CLJS.Aj,$CLJS.yj,$CLJS.ji,$CLJS.ek,$CLJS.ti,$CLJS.ci,$CLJS.MI],null),TI=$CLJS.Yg(SI),Hda,Ida,Jda;$CLJS.Y(Eda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid date extraction unit"],null)],null),TI));var UI=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pv,$CLJS.hj,$CLJS.qj,$CLJS.ii,$CLJS.ci],null),VI=$CLJS.Yg(UI);
$CLJS.Y(Dda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid date truncation unit"],null)],null),VI));$CLJS.WI=$CLJS.Wf.j($CLJS.tf,$CLJS.Zk.o(),$CLJS.Ye.g(UI,SI));Hda=$CLJS.Yg($CLJS.WI);$CLJS.Y($CLJS.OI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid date bucketing unit"],null)],null),Hda));var XI=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.JI,$CLJS.bj,$CLJS.pi],null),YI=$CLJS.Yg(XI);
$CLJS.Y(Cda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid time extraction unit"],null)],null),YI));var ZI=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.LI,$CLJS.II,$CLJS.nv,$CLJS.ov],null),$I=$CLJS.Yg(ZI);$CLJS.Y(Gda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid time truncation unit"],null)],null),$I));$CLJS.aJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Zk.o(),$CLJS.Ye.g(ZI,XI));Ida=$CLJS.Yg($CLJS.aJ);
$CLJS.Y(Bda,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid time bucketing unit"],null)],null),Ida));$CLJS.bJ=$CLJS.Wf.j($CLJS.tf,$CLJS.Zk.o(),$CLJS.Ye.l(ZI,UI,$CLJS.G([XI,SI])));Jda=$CLJS.Yg($CLJS.bJ);$CLJS.Y($CLJS.KI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime bucketing unit"],null)],null),$CLJS.bJ));var Kda=$CLJS.$d.g(Jda,$CLJS.Wh);
$CLJS.Y($CLJS.QI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid temporal bucketing unit"],null)],null),Kda));var Lda=$CLJS.qv.g(VI,$I);$CLJS.Y($CLJS.NI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime truncation unit"],null)],null),Lda));$CLJS.cJ=$CLJS.qv.g(TI,YI);$CLJS.Y(Ada,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime extraction unit"],null)],null),$CLJS.cJ));
var dJ=$CLJS.$d.g(VI,$CLJS.ci);$CLJS.Y($CLJS.RI,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid date interval unit"],null)],null),dJ));$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid time interval unit"],null)],null),$I));var Mda=$CLJS.qv.g(dJ,$I);$CLJS.Y($CLJS.WF,$CLJS.Wf.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.vu,"Valid datetime interval unit"],null)],null),Mda));
$CLJS.Y(Fda,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.PI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.li,$CLJS.QI],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Wh,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.yt],null)],null));