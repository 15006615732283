var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ida,jda,kda,lda,mda,nda,oda,BI,pda,qda,rda,sda,tda,uda,vda,wda,DI,xda;$CLJS.xI=new $CLJS.K(null,"supported-field","supported-field",-2061545519);ida=new $CLJS.K("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.yI=new $CLJS.K("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);jda=new $CLJS.K("mbql.clause","count-where","mbql.clause/count-where",2010666417);
kda=new $CLJS.K("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.zI=new $CLJS.K(null,"requires-column?","requires-column?",1623131448);lda=new $CLJS.K("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.AI=new $CLJS.K(null,"display-info","display-info",-816930907);mda=new $CLJS.K("mbql.clause","max","mbql.clause/max",1615385533);nda=new $CLJS.K("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
oda=new $CLJS.K("mbql.clause","median","mbql.clause/median",-1026386338);BI=new $CLJS.K("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);pda=new $CLJS.K("mbql.clause","min","mbql.clause/min",2074448531);qda=new $CLJS.K("mbql.clause","percentile","mbql.clause/percentile",2064253651);rda=new $CLJS.K("mbql.clause","stddev","mbql.clause/stddev",659381076);sda=new $CLJS.K("mbql.clause","var","mbql.clause/var",809434920);
tda=new $CLJS.K("mbql.clause","share","mbql.clause/share",-37921592);uda=new $CLJS.K("mbql.clause","count","mbql.clause/count",-454225910);vda=new $CLJS.K("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.CI=new $CLJS.K(null,"driver-feature","driver-feature",1905324248);wda=new $CLJS.K("mbql.clause","avg","mbql.clause/avg",1826863173);DI=new $CLJS.K("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.EI=new $CLJS.K("operator","aggregation","operator/aggregation",-1961510924);xda=new $CLJS.K("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.nH($CLJS.Kz,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)],null)],null)]));$CLJS.nH($CLJS.XH,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fD,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Os,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)],null)],null)]));
$CLJS.lH($CLJS.QH,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.lH($CLJS.AH,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.LG],null)],null)]));$CLJS.lH($CLJS.eI,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));
$CLJS.lH($CLJS.ck,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)]));$CLJS.eH($CLJS.ck,$CLJS.RG);$CLJS.lH($CLJS.jI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.eH($CLJS.jI,$CLJS.RG);$CLJS.lH($CLJS.On,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.SG],null)],null)]));$CLJS.eH($CLJS.On,$CLJS.RG);
$CLJS.Y(BI,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.vu,"valid percentile"],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.lH($CLJS.HH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,BI],null)]));$CLJS.eH($CLJS.HH,$CLJS.RG);
$CLJS.lH($CLJS.cI,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));$CLJS.lH($CLJS.lI,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.lH($CLJS.iI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));
$CLJS.lH($CLJS.sI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.eH($CLJS.iI,$CLJS.RG);$CLJS.eH($CLJS.sI,$CLJS.RG);$CLJS.lH($CLJS.qI,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.KG],null)],null)]));$CLJS.eH($CLJS.qI,$CLJS.RG);
$CLJS.lH($CLJS.BH,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.Y(DI,new $CLJS.Q(null,17,5,$CLJS.R,[$CLJS.ht,wda,uda,nda,jda,ida,mda,oda,pda,qda,tda,rda,xda,vda,kda,sda,$CLJS.sl],null));$CLJS.Y($CLJS.yI,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.sj,new $CLJS.h(null,1,[$CLJS.On,1],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,DI],null)],null));
$CLJS.FI=new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.h(null,4,[$CLJS.PH,$CLJS.Kz,$CLJS.zI,!1,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Count of rows"),$CLJS.GH,$CLJS.xG("Count"),$CLJS.Su,$CLJS.xG("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.iI,$CLJS.xI,$CLJS.Zj,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Sum of ..."),$CLJS.GH,$CLJS.xG("Sum"),$CLJS.Su,$CLJS.xG("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.QH,$CLJS.xI,$CLJS.Zj,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Average of ..."),$CLJS.GH,$CLJS.xG("Average"),$CLJS.Su,$CLJS.xG("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.jI,$CLJS.xI,$CLJS.Zj,$CLJS.zI,!0,$CLJS.CI,$CLJS.SH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Median of ..."),$CLJS.GH,$CLJS.xG("Median"),$CLJS.Su,$CLJS.xG("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.AH,$CLJS.xI,$CLJS.ei,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Number of distinct values of ..."),$CLJS.GH,$CLJS.xG("Distinct values"),$CLJS.Su,$CLJS.xG("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.sI,$CLJS.xI,$CLJS.Zj,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Cumulative sum of ..."),
$CLJS.GH,$CLJS.xG("Sum"),$CLJS.Su,$CLJS.xG("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.PH,$CLJS.XH,$CLJS.zI,!1,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Cumulative count of rows"),$CLJS.GH,$CLJS.xG("Count"),$CLJS.Su,$CLJS.xG("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.lI,$CLJS.xI,$CLJS.Zj,$CLJS.zI,
!0,$CLJS.CI,$CLJS.fI,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Standard deviation of ..."),$CLJS.GH,$CLJS.xG("SD"),$CLJS.Su,$CLJS.xG("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.On,$CLJS.xI,$CLJS.Qi,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Minimum of ..."),$CLJS.GH,$CLJS.xG("Min"),$CLJS.Su,$CLJS.xG("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.PH,$CLJS.ck,$CLJS.xI,$CLJS.Qi,$CLJS.zI,!0,$CLJS.CI,$CLJS.qH,$CLJS.AI,function(){return new $CLJS.h(null,3,[$CLJS.cG,$CLJS.xG("Maximum of ..."),$CLJS.GH,$CLJS.xG("Max"),$CLJS.Su,$CLJS.xG("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(lda,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.EI],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PH,$CLJS.Wf.j(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.dt],null),$CLJS.Ze.h($CLJS.PH),$CLJS.FI)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.xI,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.Pi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zI,$CLJS.yt],null),new $CLJS.Q(null,2,5,
$CLJS.R,[$CLJS.CI,$CLJS.Pi],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.AI,$CLJS.md],null)],null));