var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var $ca,eG,hG,rG;$CLJS.XF=function(a){return $CLJS.Fk.g($CLJS.Ze.h(a),$CLJS.gE)};$CLJS.YF=function(a,b){a=$CLJS.cv($CLJS.vC(a,/''/,"'"),/\{\d+\}/);return $CLJS.P.j($CLJS.Au.t,$CLJS.$k(a),$CLJS.$k(b))};$CLJS.ZF=new $CLJS.K("lib","metadata","lib/metadata",1798917220);$CLJS.$F=new $CLJS.K("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.aG=new $CLJS.K(null,"num-bins","num-bins",847672055);$CLJS.bG=new $CLJS.K(null,"mbql","mbql",69346710);
$CLJS.cG=new $CLJS.K(null,"display-name","display-name",694513143);$ca=new $CLJS.K("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.dG=new $CLJS.K("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);eG=new $CLJS.K("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.fG=new $CLJS.K("lib","external-op","lib/external-op",-1470923877);
$CLJS.gG=new $CLJS.K("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);hG=new $CLJS.K("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.iG=new $CLJS.K(null,"database-type","database-type",-426840562);$CLJS.jG=new $CLJS.K(null,"operator","operator",-1860875338);
$CLJS.kG=new $CLJS.K("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.lG=new $CLJS.K("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.mG=new $CLJS.K("lib","uuid","lib/uuid",-2145250720);$CLJS.nG=new $CLJS.K(null,"x","x",2099068185);$CLJS.oG=new $CLJS.K("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.pG=new $CLJS.K(null,"bin-width","bin-width",1377922579);$CLJS.qG=new $CLJS.K(null,"strategy","strategy",-1471631918);rG=new $CLJS.K("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.gG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.On,1],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,"non-blank string"],null),$CLJS.Ke($CLJS.wC)],null)],null));$CLJS.Y($CLJS.kG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.On,0],null)],null));$CLJS.Y($CLJS.$F,$CLJS.El);$CLJS.Y(rG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Tj,new $CLJS.h(null,2,[$CLJS.On,36,$CLJS.ck,36],null)],null));
$CLJS.Y(hG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,2,[$CLJS.vu,"valid semantic type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.wj);return["Not a valid semantic type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.hE(a,$CLJS.lj)}],null));
$CLJS.Y(eG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,2,[$CLJS.vu,"valid relation type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.wj);return["Not a valid relation type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.hE(a,$CLJS.gi)}],null));$CLJS.Y($CLJS.dG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,hG],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,eG],null)],null));
$CLJS.Y($CLJS.oG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,2,[$CLJS.vu,"valid base type",$CLJS.xu,function(a){a=$CLJS.O(a);a=$CLJS.I.g(a,$CLJS.wj);return["Not a valid base type: ",$CLJS.Eh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.hE(a,$CLJS.tj)}],null));
$CLJS.Y($CLJS.lG,new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.mG,rG],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iD,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.oG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.oG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pj,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,
$CLJS.dG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.iG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.cG,new $CLJS.h(null,1,[$CLJS.Et,!0],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.gt,$CLJS.gG],null)],null)],null));
$CLJS.Y($ca,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bk,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,$CLJS.fG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jG,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ht,$CLJS.Tj,$CLJS.Pi],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.sj,$CLJS.ei],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Et,!0],null),$CLJS.lG],null)],null));