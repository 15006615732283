var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var w7=function(a){return $CLJS.n(v7)?["(",$CLJS.p.h(a),")"].join(""):a},zoa=function(a,b,c){var d=$CLJS.hY(a,b);a=$CLJS.c2.j(a,b,d);var e=$CLJS.QC(c);return $CLJS.Je(function(f){return $CLJS.F.g($CLJS.QC($CLJS.T.h(f)),e)},a)},Aoa=function(a,b){var c=$CLJS.Ik.v(a,$CLJS.BR,$CLJS.IF($CLJS.$d,$CLJS.tf),b);return $CLJS.yd($CLJS.FG.h(a))?$CLJS.Ik.v(c,$CLJS.FG,$CLJS.$d,$CLJS.SX(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fD,$CLJS.N,$CLJS.aY(b)],null))):c},Boa=function(a,b,c){var d=$CLJS.aY(c);return $CLJS.S.l($CLJS.t1.j(a,
b,c),$CLJS.sM,$CLJS.e0,$CLJS.G([$CLJS.T,d,$CLJS.cG,d]))},x7=new $CLJS.K("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var Coa=function(){function a(d,e,f){var k=$CLJS.hY(d,e);k=$CLJS.fJ($CLJS.Fk.g($CLJS.Vg([f]),$CLJS.aY),$CLJS.BR.h(k));if($CLJS.n(k))return k;throw $CLJS.ai($CLJS.YF("No expression named {0}",$CLJS.G([$CLJS.Eh.l($CLJS.G([f]))])),new $CLJS.h(null,3,[$CLJS.CT,f,$CLJS.jO,d,$CLJS.nY,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.W1.m(null,$CLJS.fD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);c=Coa.j(a,b,c);return $CLJS.X1.j(a,b,c)});$CLJS.Y1.m(null,$CLJS.fD,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.H(c,2,null);return new $CLJS.h(null,7,[$CLJS.xj,$CLJS.qM,$CLJS.Q1,$CLJS.mG.h(d),$CLJS.T,e,$CLJS.NG,e,$CLJS.cG,$CLJS.u1.j(a,b,c),$CLJS.iD,$CLJS.X1.j(a,b,c),$CLJS.sM,$CLJS.e0],null)});$CLJS.S1.m(null,$CLJS.aD,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.S1.m(null,$CLJS.ZC,function(a,b,c){return $CLJS.p.h(c)});$CLJS.S1.m(null,$CLJS.$C,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.S1.m(null,$CLJS.fD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});$CLJS.T1.m(null,$CLJS.fD,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});
for(var v7=!1,Doa=new $CLJS.h(null,4,[$CLJS.Ms,"+",$CLJS.lu,"-",$CLJS.Ns,"×",$CLJS.rI,"÷"],null),y7=$CLJS.z(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ms,$CLJS.lu,$CLJS.rI,$CLJS.Ns],null)),z7=null,A7=0,B7=0;;)if(B7<A7){var Eoa=z7.X(null,B7);$CLJS.eH(Eoa,x7);B7+=1}else{var C7=$CLJS.z(y7);if(C7){var D7=C7;if($CLJS.zd(D7)){var E7=$CLJS.kc(D7),Foa=$CLJS.lc(D7),Goa=E7,Hoa=$CLJS.E(E7);y7=Foa;z7=Goa;A7=Hoa}else{var Ioa=$CLJS.B(D7);$CLJS.eH(Ioa,x7);y7=$CLJS.C(D7);z7=null;A7=0}B7=0}else break}
$CLJS.S1.m(null,x7,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.I.g(Doa,c);a:{var e=v7;v7=!0;try{var f=$CLJS.Gu([" ",$CLJS.Zg(c)," "].join(""),$CLJS.Ze.g($CLJS.rv($CLJS.u1,a,b),d));break a}finally{v7=e}f=void 0}return w7(f)});$CLJS.T1.m(null,x7,function(){return"expression"});
$CLJS.W1.m(null,$CLJS.lJ,function(a,b,c){c=$CLJS.z(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Od($CLJS.gH,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.X1.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce($CLJS.X1.j(a,
b,u),k($CLJS.Kc(l)))}return null}},null,null)}(d)}())});$CLJS.S1.m(null,$CLJS.MK,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.u1.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.j3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=w7(f);return[a," ",d.call(b,f)].join("")});
$CLJS.T1.m(null,$CLJS.MK,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.U1.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.j3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.S1.m(null,$CLJS.ZH,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.u1.v(a,b,e,d)});$CLJS.T1.m(null,$CLJS.ZH,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.U1.j(a,b,d)});
$CLJS.Joa=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(zoa(d,e,f)))throw $CLJS.ai("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.CT,f],null));return $CLJS.qY.l(d,e,Aoa,$CLJS.G([$CLJS.bY($CLJS.UX.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Koa=function(){function a(d,e){var f=$CLJS.De($CLJS.BR.h($CLJS.hY(d,e)));return null==f?null:$CLJS.Hk.g($CLJS.rv(Boa,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.F7=function(){function a(d,e){return $CLJS.De($CLJS.BR.h($CLJS.hY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.RX.m(null,$CLJS.fD,function(a){return a});
$CLJS.Loa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Tl(function(m,t){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aY(t),m],null)},$CLJS.F7.g(d,e))),l=$CLJS.hY(d,e);d=$CLJS.c2.j(d,e,l);return $CLJS.De($CLJS.d3(function(m){return $CLJS.Sa(f)||$CLJS.Dk.g($CLJS.sM.h(m),$CLJS.e0)||$CLJS.I.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();