var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Ev,Gv,Hv,Iv,Jv,Kv,Lv,Sv,Tv,Uv,Vv,Wv,Xv,$v,cw,dw,ew,gw,hw,iw,jw,kw,lw,qw,tw,vw,yw,Aw,Bw,Fw,Gw,Jw,Kw,Lw,Rw,Sw,cx,gx,mx,ox,tx,xx,Cx,Ex,Fx,zx,Hx,Ix,Kx,Jx,Lx,Qx,Rx,ay,by,Vx,cy,gy,jy,ly,my,oy,qy,ry,uy,yy,zy,Ay,By,Cy,Hy,Ky,Ly,Ny,Qy,Ry,Sy,Vy,Wy,Xy,aaa,caa,Yy,baa,bz,daa,cz,eaa,faa,dz,gaa,ez,haa,iaa,jaa,laa,maa,oaa,paa,raa,taa,jz,mz,vaa,uaa,waa,yx,Bx,tz,uz,vz,wz,zz,Az,zaa,Aaa,Baa,Dz,Caa,Ez,Cz,Fz,ow,Gz,Ew,Jy,Daa,qx,Eaa,Faa,rw,Cw,xz,Gaa,ex,Px,Mw,Haa,Iaa,bx,Jaa,kz,Qw,Ox,$w,Oy,Kaa,Dw,Laa,az,hz,
Hz,Maa,py,Jz,ty,dx,Nx,nw,gz,Yw,px,Ww,uw,Naa,Gx,Oaa,Yx,sy,oz,Paa,qz,Xw,Qaa,Raa,Saa,ux,Taa,Zy,sx,Uaa,Zx,Xx,Vaa,ww,Waa,yaa,rz,Nw,Lz,wy,Mz,Nz,dy,Xaa,Pw,Yaa,xaa,Zaa,wx,Uy,Mx,$aa,xy,yz,aba,Ty,bba,Wx,zw,cba,fx,Pz,vy,dba,sw,fz,rx,Vw,iz,xw,Dy,lz,Iy,Ey,Gy,$y,ey,Qz,Rz,eba,fw,fba,bw,mw,gba,Ow,hba;Ev=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Fv=function(){};
Gv=function(a){if(null!=a&&null!=a.pc)a=a.pc(a);else{var b=Gv[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Gv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IWriter.-flush",a);}return a};Hv=function(){};Iv=function(a){if(null!=a&&null!=a.Jc)a=a.Jc(a);else{var b=Iv[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Iv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPending.-realized?",a);}return a};
Jv=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Kv=function(a,b){return a-b*$CLJS.Td(a,b)};Lv=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=Lv[$CLJS.va(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Lv._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Ya("IMultiFn.-add-method",a);}};
$CLJS.Mv=function(a,b){var c=$CLJS.cc($CLJS.N);a=$CLJS.z(a);for(b=$CLJS.z(b);;)if(a&&b)c=$CLJS.Zf.j(c,$CLJS.B(a),$CLJS.B(b)),a=$CLJS.C(a),b=$CLJS.C(b);else return $CLJS.ec(c)};$CLJS.Nv=function(a,b){return $CLJS.dh(a,b)};$CLJS.Ov=function(a,b){return new $CLJS.le(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ce($CLJS.B(c),$CLJS.Ov(a,$CLJS.Kc(c))):null}else c=null;return c},null,null)};
$CLJS.Pv=function(a,b){return $CLJS.Ze.j(function(c){return c},b,$CLJS.Te(a,b))};Sv=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.P.g(Qv,0<b.length?new $CLJS.y(b.slice(0),0,null):null);$CLJS.$b($CLJS.Rv,"\n")};Tv=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Uv=function(a,b,c){var d=c;for(c=$CLJS.tf;;){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null);var e=$CLJS.B(d);d=$CLJS.C(d);e=$CLJS.P.g(a,new $CLJS.Q(null,2,5,$CLJS.R,[e,b],null));b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);c=$CLJS.$d.g(c,b);b=e}};Vv=function(a,b){var c=b;for(b=$CLJS.tf;;){var d=$CLJS.P.g(a,new $CLJS.Q(null,1,5,$CLJS.R,[c],null));c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.Sa(c))return new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null);b=$CLJS.$d.g(b,c);c=d}};
Wv=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);var v=$CLJS.H(t,0,null);$CLJS.H(t,1,null);l.add(new $CLJS.Q(null,2,5,$CLJS.R,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,k],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()),$CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);l.add(new $CLJS.Q(null,
2,5,$CLJS.R,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())],null)};
Xv=function(a,b){return $CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.zd(f)){var k=$CLJS.kc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);m.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,new $CLJS.Q(null,2,5,$CLJS.R,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.lc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);
k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[m,new $CLJS.Q(null,2,5,$CLJS.R,[k,b],null)],null),e($CLJS.Kc(f)))}return null}},null,null)}(a)}())};$CLJS.Yv=function(){var a=$CLJS.Rv;if(null!=a&&null!=a.sf)a.sf(a);else{var b=$CLJS.Yv[$CLJS.va(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Yv._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPrettyFlush.-ppflush",a);}};$CLJS.Zv=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
$v=function(a,b,c){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,b,c)};cw=function(a,b){$CLJS.F.g(b,"\n")?($v(a,$CLJS.aw,0),$v(a,$CLJS.gj,$CLJS.Zv(a,$CLJS.gj)+1)):$v(a,$CLJS.aw,$CLJS.Zv(a,$CLJS.aw)+1);return $CLJS.$b($CLJS.Zv(a,bw),b)};dw=function(a,b,c,d){this.Ba=a;this.Jf=b;this.Xc=c;this.Xh=d;this.C=1074167808;this.I=0};
ew=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};gw=function(a,b){for(b=fw.h(b);;){if(null==b)return!1;if(a===b)return!0;b=fw.h(b)}};hw=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.$b=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
iw=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};jw=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};kw=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
lw=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Vb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
qw=function(a,b,c){b=$CLJS.z(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.F.g(mw.h(k),nw)){var l=ow.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),l)}pw.g(a,k);$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,ow.h(k));k=ow.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,null));f+=1}else if(b=$CLJS.z(b))$CLJS.zd(b)?(d=$CLJS.kc(b),b=$CLJS.lc(b),k=d,e=$CLJS.E(d),d=k):(k=$CLJS.B(b),$CLJS.F.g(mw.h(k),nw)||
(d=ow.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),d)),pw.g(a,k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,ow.h(k)),k=ow.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),k),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,null)),b=$CLJS.C(b),d=null,e=0),f=0;else break};tw=function(a,b){var c=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(a))),$CLJS.ck),d;(d=null==c)||(a=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(a))),$CLJS.aw),b=(b=$CLJS.z(b))?rw.h($CLJS.id(b))-sw.h($CLJS.B(b)):0,d=a+b<c);return d};
vw=function(a,b,c){b=$CLJS.q(uw.h(b));return $CLJS.n(b)?b:!tw(a,c)};yw=function(a,b,c){var d=ww.h($CLJS.q($CLJS.q(a))),e=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(a))),$CLJS.ck);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(xw.h(b))>=e-d)?vw(a,b,c):d:e:d};Aw=function(a){var b=$CLJS.B(a),c=zw.h(b);b=$CLJS.z($CLJS.Ov(function(d){return!($CLJS.F.g(mw.h(d),nw)&&gw(zw.h(d),c))},$CLJS.C(a)));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.Te($CLJS.E(b)+1,a))],null)};
Bw=function(a){var b=$CLJS.B(a),c=zw.h(b);return $CLJS.z($CLJS.Ov(function(d){var e=zw.h(d);return!($CLJS.F.g(mw.h(d),nw)&&($CLJS.F.g(e,c)||gw(e,c)))},$CLJS.C(a)))};
Fw=function(a,b){$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,null);b=zw.h(b);var c=Cw.h(b);$CLJS.n(c)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.P.g($CLJS.p,$CLJS.Ve($CLJS.q(Dw.h(b))-$CLJS.E(c)," "));$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Pe(Ew.h(b),!0),$CLJS.Pe(uw.h(b),!0),a=fw.h(b);;)if($CLJS.n(a))$CLJS.Pe(uw.h(a),!0),$CLJS.Pe(Ew.h(a),!0),a=fw.h(a);else break a;return null};
Gw=function(a){var b=$CLJS.z($CLJS.Ov(function(c){return!$CLJS.F.g(mw.h(c),nw)},a));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.z($CLJS.Te($CLJS.E(b),a))],null)};Jw=function(a){for(var b=$CLJS.Ku.h($CLJS.q($CLJS.q(a)));;){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Ku,$CLJS.Wf.g($CLJS.tf,b));if(tw(a,b))return null;var c=Hw(a,b);if(b!==c)b=c;else return null}};
Kw=function(a,b){$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Ku,$CLJS.$d.g($CLJS.Ku.h($CLJS.q($CLJS.q(a))),b));return tw(a,$CLJS.Ku.h($CLJS.q($CLJS.q(a))))?null:Jw(a)};Lw=function(a){var b=ow.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),b),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,null)):null};
Rw=function(a,b){var c=$CLJS.Iu(b,"\n",-1);if($CLJS.F.g($CLJS.E(c),1))return b;b=Cw.h($CLJS.B(Mw.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.B(c);if($CLJS.F.g(Nw,$CLJS.ri.h($CLJS.q($CLJS.q(a))))){var e=Ow.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.E(d);$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Ow,f);Kw(a,new hw(Pw,d,null,e,f,null,null,null));Jw(a);d=$CLJS.Ku.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(qw(a,d,!0),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,$CLJS.Ku,$CLJS.tf))}else Lw(a),$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),d);$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.z($CLJS.C($CLJS.Du(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),l);$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.z(d))e=d,$CLJS.zd(e)?(d=$CLJS.kc(e),k=$CLJS.lc(e),e=d,f=$CLJS.E(d),d=k):(d=$CLJS.B(e),$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),d),$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.C(e),e=null,f=0),k=0;else break;$CLJS.Gh.v($CLJS.q(a),
$CLJS.S,Nw,Qw);return $CLJS.id(c)};Sw=function(a,b,c,d,e,f){this.Ba=a;this.Jf=b;this.gi=c;this.hh=d;this.Xc=e;this.Yh=f;this.C=1074167808;this.I=0};
$CLJS.Zw=function(a){var b=$CLJS.Tw,c=Uw,d=new ew(null,null,$CLJS.Oe(0),$CLJS.Oe(0),$CLJS.Oe(!1),$CLJS.Oe(!1),null,null,null,null,null,null,null),e=$CLJS.Oe($CLJS.Ag([Mw,ww,Vw,Ww,Xw,$CLJS.ri,Ow,ow,bw,Yw,$CLJS.Ku],[d,c,d,!0,null,Qw,0,null,new dw(a,b,$CLJS.Oe(new $CLJS.h(null,4,[$CLJS.ck,b,$CLJS.aw,0,$CLJS.gj,0,bw,a],null)),$CLJS.N),1,$CLJS.tf]));return new Sw(a,b,c,d,e,$CLJS.N)};
cx=function(a,b){var c=$CLJS.Rv;b=new ew(Mw.h($CLJS.q($CLJS.q(c))),null,$CLJS.Oe(0),$CLJS.Oe(0),$CLJS.Oe(!1),$CLJS.Oe(!1),a,null,b,null,null,null,null);$CLJS.Gh.v($CLJS.q(c),$CLJS.S,Mw,b);if($CLJS.F.g($CLJS.ri.h($CLJS.q($CLJS.q(c))),Qw)){Lw(c);var d=$w.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.ax):d.call(null,$CLJS.ax));$CLJS.n(a)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(c))),$CLJS.aw);$CLJS.Pe(b.tb,c);$CLJS.Pe(b.sb,c)}else d=Ow.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.E(a):0),$CLJS.Gh.v($CLJS.q(c),$CLJS.S,Ow,a),Kw(c,new jw(bx,b,d,a,null,null,null))};
gx=function(){var a=$CLJS.Rv,b=Mw.h($CLJS.q($CLJS.q(a))),c=dx.h(b);if($CLJS.F.g($CLJS.ri.h($CLJS.q($CLJS.q(a))),Qw)){Lw(a);$CLJS.n(c)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),c);var d=$w.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(ex):d.call(null,ex))}else d=Ow.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.E(c):0),$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Ow,c),Kw(a,new kw(fx,b,d,c,null,null,null));$CLJS.Gh.v($CLJS.q(a),$CLJS.S,Mw,fw.h(b))};
$CLJS.hx=function(a){var b=null!=a?a.C&32768||$CLJS.r===a.Wf?!0:a.C?!1:$CLJS.Wa($CLJS.Fv,a):$CLJS.Wa($CLJS.Fv,a);return b?Ww.h($CLJS.q($CLJS.q(a))):b};$CLJS.lx=function(a){var b=ix;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?ix>=$CLJS.Ma:b);$CLJS.Sa($CLJS.jx)?Qv.call(null,a):$CLJS.n(b)?$CLJS.$b($CLJS.Rv,"..."):($CLJS.n(ix)&&(ix+=1),kx.call(null,a));return b};mx=function(a,b){if($CLJS.Sa(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};
ox=function(){var a=$CLJS.ih;return $CLJS.n(a)?nx>=$CLJS.ih:a};tx=function(a){mx(a,new $CLJS.Tg(null,new $CLJS.h(null,4,[px,null,qx,null,rx,null,sx,null],null),null));var b=$CLJS.Rv;$CLJS.Gh.v($CLJS.q(b),$CLJS.S,$CLJS.ri,Nw);var c=Ow.h($CLJS.q($CLJS.q(b))),d=Mw.h($CLJS.q($CLJS.q(b)));Kw(b,new iw(nw,a,d,c,c,null,null,null))};
xx=function(a,b){mx(a,new $CLJS.Tg(null,new $CLJS.h(null,2,[ux,null,$CLJS.vx,null],null),null));var c=$CLJS.Rv,d=Mw.h($CLJS.q($CLJS.q(c)));if($CLJS.F.g($CLJS.ri.h($CLJS.q($CLJS.q(c))),Qw)){Lw(c);var e=Dw.h(d);if($CLJS.n($CLJS.F.g?$CLJS.F.g(ux,a):$CLJS.F.call(null,ux,a)))a=$CLJS.q(xw.h(d));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.vx,a):$CLJS.F.call(null,$CLJS.vx,a)))a=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(c))),$CLJS.aw);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Pe(e,b+a)}else e=
Ow.h($CLJS.q($CLJS.q(c))),Kw(c,new lw(wx,d,a,b,e,e,null,null,null))};Cx=function(a,b,c){b="string"===typeof b?yx(b):b;c=zx(c);a:{var d=new $CLJS.Ea,e=$CLJS.Sa(a)||!0===a?new $CLJS.sc(d):a,f=Ax(b)&&$CLJS.Sa($CLJS.hx(e))?$CLJS.n($CLJS.hx(e))?e:$CLJS.Zw(e):e,k=$CLJS.Rv;$CLJS.Rv=f;try{try{Bx(b,c)}finally{e!==f&&Gv(f)}var l=$CLJS.Sa(a)?$CLJS.p.h(d):!0===a?$CLJS.mh($CLJS.p.h(d)):null;break a}finally{$CLJS.Rv=k}l=void 0}return l};
Ex=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(Dx),"\n",$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(b," "))),"^\n"].join("");throw Error(a);};Fx=function(a,b,c,d,e,f){this.sc=a;this.pb=b;this.Zb=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};zx=function(a){a=$CLJS.z(a);return new Fx(a,a,0,null,null,null)};
Hx=function(a){var b=$CLJS.Ji.h(a);if($CLJS.n(b))return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),new Fx(Gx.h(a),$CLJS.C(b),Ow.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};Ix=function(a){var b=Hx(a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);a="string"===typeof a?yx(a):a;return new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null)};Kx=function(a,b){return b>=Ow.h(a)?(b=Ow.h(a)-b,Jx.g?Jx.g(a,b):Jx.call(null,a,b)):new Fx(Gx.h(a),$CLJS.Te(b,Gx.h(a)),b,null,null,null)};
Jx=function(a,b){var c=Ow.h(a)+b;return 0>b?Kx(a,c):new Fx(Gx.h(a),$CLJS.Te(b,$CLJS.Ji.h(a)),c,null,null,null)};Lx=function(a,b,c,d,e,f,k){this.cc=a;this.bc=b;this.jc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Qx=function(a,b){var c=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);d=$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,[Mx,null,Nx,null],null),null),c)?new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null):$CLJS.F.g(d,Ox)?Hx(b):$CLJS.F.g(d,Px)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E($CLJS.Ji.h(b)),b],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Q(null,2,5,$CLJS.R,
[b,a],null)],null),d],null)};Rx=function(a,b){b=Uv(Qx,b,a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Wf.g($CLJS.N,a),b],null)};
ay=function(a,b,c){c=Hx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Ed(d))if($CLJS.F.g(Sx,10))var e=[$CLJS.p.h(d),$CLJS.n(Tx)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(Tx)){var k=$CLJS.I.g(Ux,Sx);k=$CLJS.n(k)?k:["#",$CLJS.p.h(Sx),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(Vx(Sx,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+Wx.h(b);e=e>=Xx.h(b)?e:e+($CLJS.Td(Xx.h(b)-e-1,Yx.h(b))+1)*Yx.h(b);d=$CLJS.P.g($CLJS.p,$CLJS.Ve(e-d,Zx.h(b)));
$CLJS.n(Nx.h(b))?$x.l($CLJS.G([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):$x.l($CLJS.G([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};by=function(a,b){return $CLJS.ae($CLJS.B(Vv(function(c){return 0<c?new $CLJS.Q(null,2,5,$CLJS.R,[Kv(c,a),$CLJS.Td(c,a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null)},b)))};Vx=function(a,b){return 0===b?"0":$CLJS.P.g($CLJS.p,$CLJS.Ze.g(function(c){return 10>c?Jv(Tv("0")+c):Jv(Tv("a")+(c-10))},by(a,b)))};
cy=function(a,b){return $CLJS.ae($CLJS.B(Vv(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.z($CLJS.ae($CLJS.Se(a,c))),$CLJS.z($CLJS.Te(a,c))],null)},$CLJS.ae(b))))};
gy=function(a,b,c){c=Hx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Ed(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.g(d,Math.floor(d))){var e=0>d,f=Vx(a,e?-d:d);a=$CLJS.n(Mx.h(b))?function(){var k=$CLJS.Ze.g(function(m){return $CLJS.P.g($CLJS.p,m)},cy(dy.h(b),f)),l=$CLJS.Ve($CLJS.E(k),ey.h(b));return $CLJS.P.g($CLJS.p,$CLJS.C($CLJS.We.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(Nx.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<Xx.h(b)?
[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(Xx.h(b)-a.length,Zx.h(b)))),$CLJS.p.h(a)].join(""):a;$x.l($CLJS.G([a]))}else ay($CLJS.fy,new $CLJS.h(null,5,[Xx,Xx.h(b),Yx,1,Wx,0,Zx,Zx.h(b),Nx,!0],null),zx(new $CLJS.Q(null,1,5,$CLJS.R,[d],null)));return c};
jy=function(a){var b=$CLJS.Td(a,100);a=Kv(a,100);var c=0<b?[$CLJS.p.h($CLJS.bd(hy,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.bd(hy,a);else{f=$CLJS.Td(a,10);var k=Kv(a,10);f=[$CLJS.p.h(0<f?$CLJS.bd(iy,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.bd(hy,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
ly=function(a,b){var c=$CLJS.E(a),d=$CLJS.tf;--c;var e=$CLJS.B(a);for(a=$CLJS.C(a);;){if(null==a)return[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Xe(", ",d))),$CLJS.sd(e)||$CLJS.sd(d)?null:", ",$CLJS.p.h(e),!$CLJS.sd(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.bd(ky,c+b))].join(""):null].join("");d=$CLJS.sd(e)?d:$CLJS.$d.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.bd(ky,c+b))].join(""));--c;e=$CLJS.B(a);a=$CLJS.C(a)}};
my=function(a,b){b=Hx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=by(10,c);c=$CLJS.tf;for(var e=$CLJS.E(d)-1;;)if($CLJS.sd(d)){$x.l($CLJS.G([$CLJS.P.g($CLJS.p,c)]));break}else{var f=$CLJS.B(d);c=$CLJS.F.g(0,f)?c:$CLJS.$d.g(c,$CLJS.bd($CLJS.bd(a,e),f-1));--e;d=$CLJS.C(d)}}else gy(10,new $CLJS.h(null,5,[Xx,0,Zx," ",ey,",",dy,3,Mx,!0],null),zx(new $CLJS.Q(null,1,5,$CLJS.R,[c],null)));return b};
oy=function(a,b){a=Hx(b);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var c=Tv(b);b=c&127;c&=128;var d=$CLJS.I.g(ny,b);0<c&&$x.l($CLJS.G(["Meta-"]));$x.l($CLJS.G([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(Jv(b+64))].join(""):$CLJS.F.g(b,127)?"Control-?":Jv(b)]));return a};
qy=function(a,b){var c=Hx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);a=py.h(a);if($CLJS.n($CLJS.F.g?$CLJS.F.g("o",a):$CLJS.F.call(null,"o",a)))Cx(!0,"\\o~3,'0o",$CLJS.G([Tv(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g("u",a):$CLJS.F.call(null,"u",a)))Cx(!0,"\\u~4,'0x",$CLJS.G([Tv(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g(null,a):$CLJS.F.call(null,null,a)))$CLJS.$b($CLJS.Rv,$CLJS.n($CLJS.F.g?$CLJS.F.g("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.F.g?$CLJS.F.g(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.F.g?$CLJS.F.g("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.F.g?$CLJS.F.g("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.F.g?$CLJS.F.g("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.F.g?$CLJS.F.g("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.F.g?$CLJS.F.g('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.n($CLJS.F.g?$CLJS.F.g("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};ry=function(a,b){b=Hx(b);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);$x.l($CLJS.G([a]));return b};uy=function(a){a=$CLJS.B(a);return $CLJS.F.g(sy,a)||$CLJS.F.g(ty,a)};
yy=function(a,b,c){return $CLJS.hd(Uv(function(d,e){if(uy(e))return new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=Rx(vy.h(d),e);var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=Wv(f);f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);f=$CLJS.S.j(f,wy,c);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.g(xy.h(d),new $CLJS.Q(null,3,5,$CLJS.R,[f,e,k],null))],null)},b,a))};
zy=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.g($CLJS.bd(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.g($CLJS.bd(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.g($CLJS.bd(c,0),"0"))for(var e=0;;){if($CLJS.F.g(e,d)||!$CLJS.F.g($CLJS.bd(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.g($CLJS.bd(a,0),"+")?a.substring(1):a;return $CLJS.sd(c)?new $CLJS.Q(null,2,5,$CLJS.R,["0",0],null):new $CLJS.Q(null,2,5,$CLJS.R,[c,parseInt(a,10)-b],null)};
Ay=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.g(c,0)?new $CLJS.Q(null,4,5,$CLJS.R,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.Q(null,4,5,$CLJS.R,[a,b,c,e],null);c=$CLJS.H(f,0,null);e=$CLJS.H(f,1,null);d=$CLJS.H(f,2,null);f=$CLJS.H(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.Q(null,3,5,$CLJS.R,["0",0,!1],null);if(f>d){b=$CLJS.bd(c,d);a=c.substring(0,d);if(Tv(b)>=Tv("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.P.j($CLJS.p,"1",$CLJS.Ve(b+1,"0"));break a}if($CLJS.F.g("9",a.charAt(c)))--c;else{b=$CLJS.P.v($CLJS.p,a.substring(0,c),Jv(Tv(a.charAt(c))+1),$CLJS.Ve(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.R;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.Q(null,3,5,c,[b,e,a],null)}return new $CLJS.Q(null,3,5,$CLJS.R,[a,e,!1],null)}}}return new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null)};
By=function(a,b,c){var d=0>b?new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);a=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);d=$CLJS.E(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Cy=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
Hy=function(a,b){var c=Dy.h(a),d=Ey.h(a);b=Hx(b);var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var f=0>e?new $CLJS.Q(null,2,5,$CLJS.R,["-",-e],null):new $CLJS.Q(null,2,5,$CLJS.R,["+",e],null),k=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);f=zy(f);var l=$CLJS.H(f,0,null),m=$CLJS.H(f,1,null)+$CLJS.Fy.h(a);f=function(){var x=Nx.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Sa(d)&&$CLJS.E(l)-1<=m,u=Ay(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.H(u,0,null);m=$CLJS.H(u,1,null);u=$CLJS.H(u,2,null);l=By(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.F.g(l.charAt(0),"0")&&$CLJS.F.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.F.g($CLJS.B(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?Gy.h(a):x}())?$x.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(c,Gy.h(a)))])):$x.l($CLJS.G([[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(c-v,Zx.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else $x.l($CLJS.G([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
Ky=function(a,b){b=Hx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=zy(0>c?-c:c);$CLJS.H(d,0,null);for($CLJS.H(d,1,null);;){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=Dy.h(a),l=Ey.h(a),m=Iy.h(a),t=$CLJS.Fy.h(a),u=function(){var M=Jy.h(a);return $CLJS.n(M)?M:"E"}();d=function(){var M=Nx.h(a);return $CLJS.n(M)?M:0>c}();var v=0>=t,x=f-(t-1),A=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.P.g($CLJS.p,$CLJS.Ve(m-A.length,"0")):null),A].join("");var D=u.length;
x=$CLJS.E(e);e=[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.P.g($CLJS.p,$CLJS.Ve(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-D:null;e=Ay(e,0,$CLJS.F.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.H(e,0,null);$CLJS.H(e,1,null);A=$CLJS.H(e,2,null);e=Cy(x,t);l=$CLJS.F.g(t,$CLJS.E(x))&&null==l;if($CLJS.Sa(A)){if($CLJS.n(k)){f=e.length+D;f=$CLJS.n(d)?f+1:f;var J=(v=v&&!$CLJS.F.g(f,k))?f+1:f;f=l&&J<k;$CLJS.n(function(){var M=
J>k;M||(M=m,M=$CLJS.n(M)?D-2>m:M);return $CLJS.n(M)?Gy.h(a):M}())?$x.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(k,Gy.h(a)))])):$x.l($CLJS.G([[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(k-J-(f?1:0),Zx.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else $x.l($CLJS.G([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.Q(null,2,5,$CLJS.R,[x,f+1],null)}return b};
Ly=function(a,b,c){var d=Hx(b),e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);d=zy(0>e?-e:e);var f=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var k=Dy.h(a),l=Ey.h(a),m=Iy.h(a);d=$CLJS.F.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=Hy(new $CLJS.h(null,6,[Dy,k,Ey,d,$CLJS.Fy,0,Gy,Gy.h(a),Zx,Zx.h(a),Nx,Nx.h(a)],null),b,c),$x.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(e," "))])),a):Ky(a,b,c)};
Ny=function(a,b){b=Hx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=zy(Math.abs(c)),e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=Ey.h(a),l=$CLJS.My.h(a);d=Dy.h(a);var m=function(){var u=Nx.h(a);return $CLJS.n(u)?u:0>c}(),t=Ay(e,f,k,null);e=$CLJS.H(t,0,null);f=$CLJS.H(t,1,null);t=$CLJS.H(t,2,null);k=By(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);$x.l($CLJS.G([[$CLJS.n(function(){var u=Mx.h(a);return $CLJS.n(u)?m:
u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.P.g($CLJS.p,$CLJS.Ve(d-k,Zx.h(a)))),$CLJS.n(function(){var u=$CLJS.Sa(Mx.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Qy=function(a,b){var c=Oy.h(a);c=$CLJS.n(c)?new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null):Hx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.Py.h(a);b=0>b||b>=$CLJS.E(d)?$CLJS.B($CLJS.Lu.h(a)):$CLJS.bd(d,b);return $CLJS.n(b)?yy(b,c,wy.h(a)):c};
Ry=function(a,b){var c=Hx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.Py.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.B(d);return $CLJS.n(b)?yy(b,c,wy.h(a)):c};Sy=function(a,b){var c=Hx(b),d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.Py.h(a);e=$CLJS.n(d)?$CLJS.B(e):null;return $CLJS.n(d)?$CLJS.n(e)?yy(e,b,wy.h(a)):b:c};
Vy=function(a,b){var c=Ty.h(a),d=$CLJS.B($CLJS.Py.h(a));d=$CLJS.sd(d)?Ix(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Hx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=0;e=zx(e);for(var k=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(Ow.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.sd($CLJS.Ji.h(e))&&($CLJS.Sa(Mx.h(Uy.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=yy(b,e,wy.h(a));if($CLJS.F.g(sy,$CLJS.B(k)))return d;
f+=1;var l=Ow.h(e);e=k;k=l}};Wy=function(a,b){var c=Ty.h(a),d=$CLJS.B($CLJS.Py.h(a));d=$CLJS.sd(d)?Ix(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Hx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);for(var f=0;;){var k=$CLJS.sd(e)&&($CLJS.Sa(Mx.h(Uy.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=yy(b,zx($CLJS.B(e)),zx($CLJS.C(e)));if($CLJS.F.g(ty,$CLJS.B(k)))return d;f+=1;e=$CLJS.C(e)}};
Xy=function(a,b){var c=Ty.h(a),d=$CLJS.B($CLJS.Py.h(a)),e=$CLJS.sd(d)?Ix(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(e,0,null);d=0;e=$CLJS.H(e,1,null);for(var f=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(Ow.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.sd($CLJS.Ji.h(e))&&($CLJS.Sa(Mx.h(Uy.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=yy(b,e,wy.h(a));if($CLJS.F.g(sy,$CLJS.B(f)))return $CLJS.hd(f);d+=1;var k=Ow.h(e);e=f;f=k}};
aaa=function(a,b){var c=Ty.h(a),d=$CLJS.B($CLJS.Py.h(a)),e=$CLJS.sd(d)?Ix(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.H(e,0,null);d=0;for(e=$CLJS.H(e,1,null);;){var f=$CLJS.sd($CLJS.Ji.h(e))&&($CLJS.Sa(Mx.h(Uy.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Ji.h(e);f=$CLJS.n(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(f),new Fx(Gx.h(e),$CLJS.C(f),Ow.h(e)+1,null,null,null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=yy(b,zx(e),f);
if($CLJS.F.g(ty,$CLJS.B(e)))return f;e=f;d+=1}};caa=function(a,b){if($CLJS.n(Mx.h(Uy.h(a)))){var c=$CLJS.Py.h(a),d=$CLJS.E(c),e=1<d?$CLJS.Tj.h(vy.h($CLJS.B($CLJS.B(c)))):$CLJS.n(Mx.h(a))?"(":null,f=$CLJS.bd(c,1<d?1:0);c=2<d?$CLJS.Tj.h(vy.h($CLJS.B($CLJS.bd(c,2)))):$CLJS.n(Mx.h(a))?")":null;d=Hx(b);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var k=nx,l=ix;nx+=1;ix=0;try{cx(e,c),yy(f,zx(b),wy.h(a)),gx()}finally{ix=l,nx=k}}a=d}else a=baa(a,b);return a};
Yy=function(a,b,c){for(var d=$CLJS.tf;;){if($CLJS.sd(a))return new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);var e=$CLJS.B(a);a:{var f=new $CLJS.Ea,k=$CLJS.Rv;$CLJS.Rv=new $CLJS.sc(f);try{var l=new $CLJS.Q(null,2,5,$CLJS.R,[yy(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Rv=k}l=void 0}b=$CLJS.H(l,0,null);e=$CLJS.H(l,1,null);if($CLJS.F.g(sy,$CLJS.B(b)))return new $CLJS.Q(null,2,5,$CLJS.R,[d,$CLJS.hd(b)],null);a=$CLJS.C(a);d=$CLJS.$d.g(d,e)}};
baa=function(a,b){var c=function(){var Z=$CLJS.Lu.h(a);return $CLJS.n(Z)?Yy(Z,b,wy.h(a)):null}(),d=$CLJS.H(c,0,null);d=$CLJS.H(d,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var Z=Zy.h(a);return $CLJS.n(Z)?Rx(Z,e):null}();var f=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var Z=$CLJS.B($y.h(f));return $CLJS.n(Z)?Z:0}();var l=function(){var Z=$CLJS.B(az.h(f));return $CLJS.n(Z)?Z:$CLJS.Zv($CLJS.Rv,$CLJS.ck)}(),m=$CLJS.Py.h(a);k=Yy(m,k,wy.h(a));var t=$CLJS.H(k,
0,null);k=$CLJS.H(k,1,null);var u=function(){var Z=$CLJS.E(t)-1+($CLJS.n(Mx.h(a))?1:0)+($CLJS.n(Nx.h(a))?1:0);return 1>Z?1:Z}();m=$CLJS.Od($CLJS.zk,$CLJS.Ze.g($CLJS.E,t));var v=Xx.h(a),x=Wx.h(a),A=Yx.h(a),D=m+u*x;v=D<=v?v:v+A*(1+$CLJS.Td(D-v-1,A));var J=v-m;m=function(){var Z=$CLJS.Td(J,u);return x>Z?x:Z}();A=J-m*u;m=$CLJS.P.g($CLJS.p,$CLJS.Ve(m,Zx.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Zv(bw.h($CLJS.q($CLJS.q($CLJS.Rv))),$CLJS.aw)+c+v>l:d)&&$x.l($CLJS.G([d]));c=A;for(var M=t,V=function(){var Z=Mx.h(a);
return $CLJS.n(Z)?Z:$CLJS.F.g($CLJS.E(M),1)&&$CLJS.Sa(Nx.h(a))}();;)if($CLJS.z(M))$x.l($CLJS.G([[$CLJS.p.h($CLJS.Sa(V)?$CLJS.B(M):null),$CLJS.p.h($CLJS.n(function(){var Z=V;return $CLJS.n(Z)?Z:(Z=$CLJS.C(M))?Z:Nx.h(a)}())?m:null),$CLJS.p.h(0<c?Zx.h(a):null)].join("")])),--c,M=d=$CLJS.n(V)?M:$CLJS.C(M),V=!1;else break;return k};bz=function(a,b){this.Ba=a;this.Zh=b;this.C=1074135040;this.I=0};daa=function(a){return new bz(a,$CLJS.N)};cz=function(a,b){this.Ba=a;this.$h=b;this.C=1074135040;this.I=0};
eaa=function(a){return new cz(a,$CLJS.N)};
faa=function(a,b){var c=$CLJS.B(a);return $CLJS.P.g($CLJS.p,$CLJS.B(Vv(function(d){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.Q(null,2,5,$CLJS.R,[[d.substring(0,e),$CLJS.bd(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?Ev(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
dz=function(a,b,c){this.Ba=a;this.Id=b;this.ai=c;this.C=1074135040;this.I=0};gaa=function(a){return new dz(a,$CLJS.Oe(!0),$CLJS.N)};ez=function(a,b,c){this.Ba=a;this.ud=b;this.bi=c;this.C=1074135040;this.I=0};haa=function(a){return new ez(a,$CLJS.Oe(!1),$CLJS.N)};iaa=function(a,b){var c=$CLJS.n(Mx.h(a))?$CLJS.vx:ux;xx(c,$CLJS.My.h(a));return b};jaa=function(a,b){a=$CLJS.n(Mx.h(a))?$CLJS.n(Nx.h(a))?px:rx:$CLJS.n(Nx.h(a))?qx:sx;tx(a);return b};
laa=function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null),d=$CLJS.H(a,2,null);a=new RegExp(kaa.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.B(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.g(",",$CLJS.bd(b,0))?new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b.substring(1),a+1,!0],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b,a,!1],null)],null)):$CLJS.n(d)?Ex("Badly formed parameters in format directive",
c):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null)],null)};maa=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F.g(b.length,0)?null:$CLJS.F.g(b.length,1)&&$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.bd(b,0))?Ox:$CLJS.F.g(b.length,1)&&$CLJS.F.g("#",$CLJS.bd(b,0))?Px:$CLJS.F.g(b.length,2)&&$CLJS.F.g("'",$CLJS.bd(b,0))?$CLJS.bd(b,1):parseInt(b,10),a],null)};
oaa=function(a,b){return Vv(function(c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null);var f=$CLJS.I.g(naa,$CLJS.B(d));return $CLJS.n(f)?$CLJS.Gd(c,f)?Ex(['Flag "',$CLJS.p.h($CLJS.B(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[d.substring(1),e+1,$CLJS.S.j(c,f,new $CLJS.Q(null,2,5,$CLJS.R,[!0,e],null))],
null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null)},new $CLJS.Q(null,3,5,$CLJS.R,[a,b,$CLJS.N],null))};
paa=function(a,b){var c=fz.h(a);$CLJS.n(function(){var d=$CLJS.Sa(Nx.h(c));return d?Nx.h(b):d}())&&Ex(['"@" is an illegal flag for format directive "',$CLJS.p.h(gz.h(a)),'"'].join(""),$CLJS.bd(Nx.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(Mx.h(c));return d?Mx.h(b):d}())&&Ex(['":" is an illegal flag for format directive "',$CLJS.p.h(gz.h(a)),'"'].join(""),$CLJS.bd(Mx.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(hz.h(c));return d?(d=Nx.h(b),$CLJS.n(d)?Mx.h(b):d):d}())&&Ex(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(gz.h(a)),'"'].join(""),function(){var d=$CLJS.bd(Mx.h(b),1),e=$CLJS.bd(Nx.h(b),1);return d<e?d:e}())};
raa=function(a,b,c,d){paa(a,c);$CLJS.E(b)>$CLJS.E(vy.h(a))&&Ex(Cx(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([gz.h(a),$CLJS.E(b),$CLJS.E(vy.h(a))])),$CLJS.hd($CLJS.B(b)));$CLJS.eh($CLJS.Ze.j(function(e,f){var k=$CLJS.B(e);return null==k||$CLJS.Gd(qaa,k)||$CLJS.F.g($CLJS.hd($CLJS.hd(f)),$CLJS.Xa(k))?null:Ex(["Parameter ",$CLJS.Zg($CLJS.B(f)),' has bad type in directive "',$CLJS.p.h(gz.h(a)),'": ',$CLJS.p.h($CLJS.Xa(k))].join(""),
$CLJS.hd(e))},b,vy.h(a)));return $CLJS.Xk.l($CLJS.G([$CLJS.Wf.g($CLJS.N,$CLJS.ae(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.zd(l)){var m=$CLJS.kc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.H(x,0,null);u.add(new $CLJS.Q(null,2,5,$CLJS.R,[A,new $CLJS.Q(null,2,5,$CLJS.R,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.lc(l))):
$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);m=$CLJS.H(m,0,null);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[u,new $CLJS.Q(null,2,5,$CLJS.R,[m,d],null)],null),k($CLJS.Kc(l)))}return null}},null,null)}(vy.h(a))}())),$CLJS.ab(function(e,f){return $CLJS.P.j($CLJS.S,e,f)},$CLJS.N,$CLJS.bf(function(e){return $CLJS.B($CLJS.bd(e,1))},$CLJS.Mv($CLJS.Pg(vy.h(a)),b))),c]))};
taa=function(a,b){b=Vv(laa,new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null));a=$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);b=oaa(b,c);$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);c=$CLJS.B(d);var f=$CLJS.I.g(saa,c.toUpperCase()),k=$CLJS.n(f)?raa(f,$CLJS.Ze.g(maa,a),b,e):null;$CLJS.Sa(c)&&Ex("Format string ended in the middle of a directive",e);$CLJS.Sa(f)&&Ex(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.Q(null,2,5,$CLJS.R,[new Lx(function(){var l=iz.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.F.g("\n",gz.h(f))&&$CLJS.Sa(Mx.h(k)))a:{var t=new $CLJS.Q(null,2,5,$CLJS.R,[" ","\t"],null);t=$CLJS.td(t)?$CLJS.Yg(t):$CLJS.Vg([t]);for(var u=0;;){var v;(v=$CLJS.F.g(u,$CLJS.E(l)))||(v=$CLJS.bd(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Sa(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.Q(null,2,5,$CLJS.R,[l.substring(t),
m+t],null)}()],null)};jz=function(a,b){return new Lx(function(c,d){$x.l($CLJS.G([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Tj,a],null),b,null,null,null)};mz=function(a,b){var c=uaa(kz.h(lz.h(a)),$CLJS.Mu.h(a),b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new Lx(xy.h(a),lz.h(a),$CLJS.Xk.l($CLJS.G([vy.h(a),Xv(b,$CLJS.Mu.h(a))])),$CLJS.Mu.h(a),null,null,null),c],null)};
vaa=function(a,b,c){return Vv(function(d){if($CLJS.sd(d))return Ex("No closing bracket found.",b);var e=$CLJS.B(d);d=$CLJS.C(d);if($CLJS.n($CLJS.nz.h(kz.h(lz.h(e)))))e=mz(e,d);else if($CLJS.F.g($CLJS.nz.h(a),gz.h(lz.h(e))))e=new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[oz,vy.h(e),null,d],null)],null);else{var f=$CLJS.Ou.h(kz.h(lz.h(e)));f=$CLJS.n(f)?Mx.h(vy.h(e)):f;e=$CLJS.n(f)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Lu,null,vy.h(e),d],null)],null):
$CLJS.n($CLJS.Ou.h(kz.h(lz.h(e))))?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Ou,null,null,d],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[e,d],null)}return e},c)};
uaa=function(a,b,c){return $CLJS.hd(Vv(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);d=$CLJS.H(d,2,null);var k=vaa(a,b,d);d=$CLJS.H(k,0,null);var l=$CLJS.H(k,1,null);k=$CLJS.H(l,0,null);var m=$CLJS.H(l,1,null),t=$CLJS.H(l,2,null);l=$CLJS.H(l,3,null);return $CLJS.F.g(k,oz)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pz.l($CLJS.Ye,$CLJS.G([e,$CLJS.Ce([$CLJS.n(f)?$CLJS.Lu:$CLJS.Py,new $CLJS.Q(null,1,5,$CLJS.R,[d],null),Uy,m])])),l],null)],null):$CLJS.F.g(k,$CLJS.Lu)?
$CLJS.n($CLJS.Lu.h(e))?Ex('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Sa($CLJS.Lu.h(a))?Ex('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.g(qz,$CLJS.Lu.h(a))&&$CLJS.z($CLJS.Py.h(e))?Ex('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.g(qz,$CLJS.Lu.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,2,[$CLJS.Lu,new $CLJS.Q(null,1,5,
$CLJS.R,[d],null),Zy,t],null)])),!1,l],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.Py,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!0,l],null)],null):$CLJS.F.g(k,$CLJS.Ou)?$CLJS.n(f)?Ex('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Sa(rz.h(a))?Ex('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.pz.l($CLJS.Ye,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.Py,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.h(null,1,[$CLJS.Py,$CLJS.tf],null),!1,c],null)))};waa=function(a){return $CLJS.B(Vv(function(b){var c=$CLJS.B(b);b=$CLJS.C(b);var d=kz.h(lz.h(c));return $CLJS.n($CLJS.nz.h(d))?mz(c,b):new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)},a))};
yx=function(a){var b=Dx;Dx=a;try{return waa($CLJS.B(Vv(function(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.Q(null,2,5,$CLJS.R,[jz(d,c),new $CLJS.Q(null,2,5,$CLJS.R,["",c+d.length],null)],null):0===e?taa(d.substring(1),c+1):new $CLJS.Q(null,2,5,$CLJS.R,[jz(d.substring(0,e),c),new $CLJS.Q(null,2,5,$CLJS.R,[d.substring(e),e+c],null)],null)},new $CLJS.Q(null,2,5,$CLJS.R,[a,0],null))))}finally{Dx=
b}};Bx=function(a,b){Uv(function(c,d){if(uy(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=Rx(vy.h(c),d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=Wv(e);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=$CLJS.S.j(e,wy,d);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.P.g(xy.h(c),new $CLJS.Q(null,3,5,$CLJS.R,[e,d,f],null))],null)},b,a);return null};
tz=function(a){var b=$CLJS.B(a);b=sz.h?sz.h(b):sz.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.F.g(2,$CLJS.E(a)):b)?($CLJS.$b($CLJS.Rv,b),$CLJS.lx($CLJS.hd(a)),!0):null};
uz=function(a){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("[","]");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.lx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Rv," ");tx(sx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Rv,"...");break}gx()}finally{ix=c,nx=b}}return null};
vz=function(a){var b=$CLJS.xd(a)?null:function(){var m=new $CLJS.Fc(function(){return $CLJS.zh},xaa,$CLJS.Ag([$CLJS.mk,$CLJS.Oi,$CLJS.T,$CLJS.hk,$CLJS.oi,$CLJS.Mi,$CLJS.gj,$CLJS.ok,$CLJS.ni,$CLJS.U,$CLJS.gk],[!0,$CLJS.fi,yaa,"cljs/core.cljs",15,1,10543,10543,$CLJS.W(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Yi],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.zh)?$CLJS.zh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{c=nx;b=ix;nx+=1;ix=0;try{cx(e,"}");e=0;for(var f=$CLJS.z(d);;){if($CLJS.Sa($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{d=nx;var k=ix;nx+=1;ix=0;try{cx(null,null),$CLJS.lx($CLJS.B($CLJS.B(f))),$CLJS.$b($CLJS.Rv," "),tx(sx),ix=0,$CLJS.lx($CLJS.B($CLJS.C($CLJS.B(f)))),gx()}finally{ix=k,nx=d}}if($CLJS.C(f)){$CLJS.$b($CLJS.Rv,", ");tx(sx);d=e+1;var l=$CLJS.C(f);e=d;f=l;continue}}}else $CLJS.$b($CLJS.Rv,
"...");break}gx()}finally{ix=b,nx=c}}return null};wz=function(a){return $CLJS.$b($CLJS.Rv,$CLJS.Eh.l($CLJS.G([a])))};zz=function(a){return a instanceof $CLJS.If?xz:(null!=a?a.C&32768||$CLJS.r===a.Wf||(a.C?0:$CLJS.Wa($CLJS.Fv,a)):$CLJS.Wa($CLJS.Fv,a))?yz:a instanceof $CLJS.w?$CLJS.Ai:$CLJS.Cd(a)?$CLJS.vj:$CLJS.wd(a)?$CLJS.bk:$CLJS.yd(a)?$CLJS.Ej:$CLJS.ud(a)?$CLJS.Si:null==a?null:$CLJS.Wh};
Az=function(a){return $CLJS.yd(a)?new $CLJS.Q(null,2,5,$CLJS.R,["[","]"],null):new $CLJS.Q(null,2,5,$CLJS.R,["(",")"],null)};
zaa=function(a){if($CLJS.vd(a)){var b=Az(a),c=$CLJS.H(b,0,null),d=$CLJS.H(b,1,null),e=$CLJS.z(a),f=$CLJS.B(e),k=$CLJS.C(e);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var l=nx,m=ix,t=nx+1;nx=t;ix=0;try{cx(c,d);(function(){var mb=Bz("~w~:i");return function(){function Qb(jc){var Tb=null;if(0<arguments.length){Tb=0;for(var rc=Array(arguments.length-0);Tb<rc.length;)rc[Tb]=arguments[Tb+0],++Tb;Tb=new $CLJS.y(rc,0,null)}return Ab.call(this,Tb)}function Ab(jc){jc=zx(jc);return Bx(mb,jc)}Qb.A=0;Qb.B=function(jc){jc=
$CLJS.z(jc);return Ab(jc)};Qb.l=Ab;return Qb}()})()(f);for(var u=k;;)if($CLJS.z(u)){(function(){var mb=Bz(" ");return function(Qb,Ab,jc){return function(){function Tb(Vb){var Sb=null;if(0<arguments.length){Sb=0;for(var Vd=Array(arguments.length-0);Sb<Vd.length;)Vd[Sb]=arguments[Sb+0],++Sb;Sb=new $CLJS.y(Vd,0,null)}return rc.call(this,Sb)}function rc(Vb){Vb=zx(Vb);return Bx(jc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u," ",mb,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.B(u);if($CLJS.vd(v)){var x=Az(v),A=$CLJS.H(x,0,null),D=$CLJS.H(x,1,null);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var J=nx,M=ix,V=nx+1;nx=V;ix=0;try{cx(A,D);if($CLJS.F.g($CLJS.E(v),3)&&$CLJS.hd(v)instanceof $CLJS.K){var Z=v,fa=$CLJS.H(Z,0,null),Da=$CLJS.H(Z,1,null),Ha=$CLJS.H(Z,2,null);(function(){var mb=Bz("~w ~w ");return function(Qb,Ab,jc){return function(){function Tb(Vb){var Sb=null;if(0<arguments.length){Sb=0;for(var Vd=Array(arguments.length-0);Sb<Vd.length;)Vd[Sb]=arguments[Sb+
0],++Sb;Sb=new $CLJS.y(Vd,0,null)}return rc.call(this,Sb)}function rc(Vb){Vb=zx(Vb);return Bx(jc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~w ~w ",mb,Z,fa,Da,Ha,J,M,V,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(fa,Da);$CLJS.vd(Ha)?function(){var mb=$CLJS.yd(Ha)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",Qb="string"===typeof mb?Bz(mb):mb;return function(Ab,jc,Tb){return function(){function rc(Sb){var Vd=null;if(0<arguments.length){Vd=0;
for(var Iw=Array(arguments.length-0);Vd<Iw.length;)Iw[Vd]=arguments[Vd+0],++Vd;Vd=new $CLJS.y(Iw,0,null)}return Vb.call(this,Vd)}function Vb(Sb){Sb=zx(Sb);return Bx(Tb,Sb)}rc.A=0;rc.B=function(Sb){Sb=$CLJS.z(Sb);return Vb(Sb)};rc.l=Vb;return rc}()}(u,mb,Qb,Z,fa,Da,Ha,J,M,V,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(Ha):$CLJS.lx(Ha)}else $CLJS.P.g(function(){var mb=Bz("~w ~:i~@{~w~^ ~:_~}");return function(Qb,Ab,jc){return function(){function Tb(Vb){var Sb=null;if(0<arguments.length){Sb=0;for(var Vd=
Array(arguments.length-0);Sb<Vd.length;)Vd[Sb]=arguments[Sb+0],++Sb;Sb=new $CLJS.y(Vd,0,null)}return rc.call(this,Sb)}function rc(Vb){Vb=zx(Vb);return Bx(jc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~w ~:i~@{~w~^ ~:_~}",mb,J,M,V,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);gx()}finally{ix=M,nx=J}}$CLJS.C(u)&&function(){var mb=Bz("~_");return function(Qb,Ab,jc){return function(){function Tb(Vb){var Sb=null;if(0<arguments.length){Sb=0;for(var Vd=Array(arguments.length-
0);Sb<Vd.length;)Vd[Sb]=arguments[Sb+0],++Sb;Sb=new $CLJS.y(Vd,0,null)}return rc.call(this,Sb)}function rc(Vb){Vb=zx(Vb);return Bx(jc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~_",mb,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.lx(v),$CLJS.C(u)&&function(){var mb=Bz("~:_");return function(Qb,Ab,jc){return function(){function Tb(Vb){var Sb=null;if(0<arguments.length){Sb=0;for(var Vd=Array(arguments.length-0);Sb<Vd.length;)Vd[Sb]=arguments[Sb+0],++Sb;
Sb=new $CLJS.y(Vd,0,null)}return rc.call(this,Sb)}function rc(Vb){Vb=zx(Vb);return Bx(jc,Vb)}Tb.A=0;Tb.B=function(Vb){Vb=$CLJS.z(Vb);return rc(Vb)};Tb.l=rc;return Tb}()}(u,"~:_",mb,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.C(u)}else break;gx()}finally{ix=m,nx=l}}}else $CLJS.lx(a)};
Aaa=function(a,b){$CLJS.z(a)&&($CLJS.n(b)?function(){var c=Bz(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=zx(f);return Bx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()():function(){var c=Bz(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=zx(f);return Bx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Bz("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=zx(f);return Bx(c,f)}d.A=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;
return d}()}()(a))};Baa=function(a){$CLJS.z(a)&&function(){var b=Bz(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.y(k,0,null)}return d.call(this,f)}function d(e){e=zx(e);return Bx(b,e)}c.A=0;c.B=function(e){e=$CLJS.z(e);return d(e)};c.l=d;return c}()}()(a)};
Dz=function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=$CLJS.wd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var f=
nx,k=ix;nx+=1;ix=0;try{cx("(",")"),function(){var l=Bz("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=zx(u);return Bx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Bz(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=zx(u);return Bx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Bz(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=zx(u);return Bx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.yd($CLJS.B(e))?Aaa(e,$CLJS.n(c)?c:d):Baa(e),gx()}finally{ix=k,nx=f}}return null}return Cz(a)};
Caa=function(a){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("[","]");for(var d=0;;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.z(a)){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var e=nx,f=ix;nx+=1;ix=0;try{cx(null,null),$CLJS.lx($CLJS.B(a)),$CLJS.C(a)&&($CLJS.$b($CLJS.Rv," "),tx(qx),$CLJS.lx($CLJS.hd(a))),gx()}finally{ix=f,nx=e}}if($CLJS.C($CLJS.Kc(a))){$CLJS.$b($CLJS.Rv," ");tx(sx);e=d+1;var k=$CLJS.C($CLJS.Kc(a));d=e;a=k;continue}}}else $CLJS.$b($CLJS.Rv,"...");
break}gx()}finally{ix=c,nx=b}}};
Ez=function(a){var b=$CLJS.B(a);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var c=nx,d=ix;nx+=1;ix=0;try{cx("(",")"),$CLJS.C(a)&&$CLJS.yd($CLJS.hd(a))?(function(){var e=Bz("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=zx(l);return Bx(e,l)}f.A=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()(b),Caa($CLJS.hd(a)),function(){var e=
Bz(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=zx(l);return Bx(e,l)}f.A=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()($CLJS.C($CLJS.Kc(a)))):Cz(a),gx()}finally{ix=d,nx=c}}return null};
Cz=function(a){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("(",")");xx(ux,1);for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.lx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Rv," ");tx(sx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Rv,"...");break}gx()}finally{ix=c,nx=b}}return null};$CLJS.le.prototype.Jc=$CLJS.ua(2,function(){return $CLJS.Sa(this.Rb)});$CLJS.Ue.prototype.Jc=$CLJS.ua(1,function(){return!1});
$CLJS.Bh.prototype.Jc=$CLJS.ua(0,function(){return $CLJS.Sa(this.f)});$CLJS.Rv=null;Fz=function Fz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Fz.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};Fz.l=function(a){a=$CLJS.z(a);for(var b=$CLJS.cc($CLJS.Yf);;)if(a){var c=$CLJS.C(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.B(a))].join(""));var d=$CLJS.C(c);b=$CLJS.Zf.j(b,$CLJS.B(a),$CLJS.B(c));a=d}else return $CLJS.ec(b)};
Fz.A=0;Fz.B=function(a){return this.l($CLJS.z(a))};$CLJS.pz=function pz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return pz.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
$CLJS.pz.l=function(a,b){if($CLJS.n($CLJS.Je($CLJS.Rd,b))){var c=function(d,e){var f=$CLJS.yb(e),k=$CLJS.zb(e);if($CLJS.Gd(d,f)){e=$CLJS.S.j;var l=$CLJS.I.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.S,d,f,k)}else d=$CLJS.S.j(d,f,k);return d};return $CLJS.Od(function(d,e){return $CLJS.ab(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.z(e))},b)}return null};$CLJS.pz.A=1;$CLJS.pz.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
$CLJS.fy=function fy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return fy.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};$CLJS.fy.l=function(a){return $CLJS.wh(a,$CLJS.S.j($CLJS.Na(),$CLJS.Ga,!1))};$CLJS.fy.A=0;$CLJS.fy.B=function(a){return this.l($CLJS.z(a))};ow=new $CLJS.K(null,"trailing-white-space","trailing-white-space",1496006996);Gz=new $CLJS.K(null,"relative-to","relative-to",-470100051);Ew=new $CLJS.K(null,"intra-block-nl","intra-block-nl",1808826875);
Jy=new $CLJS.K(null,"exponentchar","exponentchar",1986664222);Daa=new $CLJS.w(null,"when-let","when-let",-1383043480,null);qx=new $CLJS.K(null,"miser","miser",-556060186);$CLJS.vx=new $CLJS.K(null,"current","current",-1088038603);Eaa=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);Faa=new $CLJS.w(null,"..","..",-300507420,null);rw=new $CLJS.K(null,"end-pos","end-pos",-1643883926);Cw=new $CLJS.K(null,"per-line-prefix","per-line-prefix",846941813);xz=new $CLJS.K(null,"queue","queue",1455835879);
Gaa=new $CLJS.w(null,"if-let","if-let",1803593690,null);ex=new $CLJS.K(null,"end","end",-268185958);Px=new $CLJS.K(null,"remaining-arg-count","remaining-arg-count",-1216589335);Mw=new $CLJS.K(null,"logical-blocks","logical-blocks",-1466339776);Haa=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);Iaa=new $CLJS.w(null,"when-first","when-first",821699168,null);bx=new $CLJS.K(null,"start-block-t","start-block-t",-373430594);Jaa=new $CLJS.w(null,"binding","binding",-2114503176,null);
kz=new $CLJS.K(null,"bracket-info","bracket-info",-1600092774);Qw=new $CLJS.K(null,"writing","writing",-1486865108);Ox=new $CLJS.K(null,"parameter-from-args","parameter-from-args",-758446196);$w=new $CLJS.K(null,"logical-block-callback","logical-block-callback",1612691194);Oy=new $CLJS.K(null,"selector","selector",762528866);Kaa=new $CLJS.w(null,"struct","struct",325972931,null);Dw=new $CLJS.K(null,"indent","indent",-148200125);Laa=new $CLJS.w(null,"loop","loop",1244978678,null);
az=new $CLJS.K(null,"max-columns","max-columns",1742323262);hz=new $CLJS.K(null,"both","both",-393648840);Hz=new $CLJS.K(null,"colnum","colnum",2023796854);Maa=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.Iz=new $CLJS.K(null,"length","length",588987862);py=new $CLJS.K(null,"char-format","char-format",-1016499218);Jz=new $CLJS.K(null,"prefix","prefix",-265908465);ty=new $CLJS.K(null,"colon-up-arrow","colon-up-arrow",244853007);dx=new $CLJS.K(null,"suffix","suffix",367373057);
Nx=new $CLJS.K(null,"at","at",1476951349);nw=new $CLJS.K(null,"nl-t","nl-t",-1608382114);gz=new $CLJS.K(null,"directive","directive",793559132);Yw=new $CLJS.K(null,"buffer-level","buffer-level",928864731);px=new $CLJS.K(null,"mandatory","mandatory",542802336);Ww=new $CLJS.K(null,"pretty-writer","pretty-writer",-1222834267);uw=new $CLJS.K(null,"done-nl","done-nl",-381024340);Naa=new $CLJS.w(null,"condp","condp",1054325175,null);Gx=new $CLJS.K(null,"seq","seq",-1817803783);
Oaa=new $CLJS.w(null,"defn","defn",-126010802,null);Yx=new $CLJS.K(null,"colinc","colinc",-584873385);sy=new $CLJS.K(null,"up-arrow","up-arrow",1705310333);oz=new $CLJS.K(null,"right-bracket","right-bracket",951856080);Paa=new $CLJS.K(null,"radix","radix",857016463);qz=new $CLJS.K(null,"first","first",-644103046);Xw=new $CLJS.K(null,"sections","sections",-886710106);$CLJS.ax=new $CLJS.K(null,"start","start",-355208981);Qaa=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Raa=new $CLJS.w(null,"defn-","defn-",1097765044,null);Saa=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);ux=new $CLJS.K(null,"block","block",664686210);Taa=new $CLJS.w(null,"when","when",1064114221,null);Zy=new $CLJS.K(null,"else-params","else-params",-832171646);$CLJS.Kz=new $CLJS.K(null,"count","count",2139924085);$CLJS.nz=new $CLJS.K(null,"right","right",-452581833);sx=new $CLJS.K(null,"linear","linear",872268697);Uaa=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
Zx=new $CLJS.K(null,"padchar","padchar",2018584530);Xx=new $CLJS.K(null,"mincol","mincol",1230695445);Vaa=new $CLJS.K(null,"not-delivered","not-delivered",1599158697);ww=new $CLJS.K(null,"miser-width","miser-width",-1310049437);Waa=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);yaa=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);rz=new $CLJS.K(null,"allows-separator","allows-separator",-818967742);Nw=new $CLJS.K(null,"buffering","buffering",-876713613);
Lz=new $CLJS.K(null,"arg1","arg1",951899358);wy=new $CLJS.K(null,"base-args","base-args",-1268706822);Mz=new $CLJS.K(null,"arg3","arg3",-1486822496);Nz=new $CLJS.K(null,"arg2","arg2",1729550917);dy=new $CLJS.K(null,"commainterval","commainterval",-1980061083);Xaa=new $CLJS.K(null,"right-margin","right-margin",-810413306);Pw=new $CLJS.K(null,"buffer-blob","buffer-blob",-1830112173);Yaa=new $CLJS.w(null,"with-open","with-open",172119667,null);
xaa=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Zaa=new $CLJS.K(null,"lines","lines",-700165781);wx=new $CLJS.K(null,"indent-t","indent-t",528318969);Uy=new $CLJS.K(null,"right-params","right-params",-1790676237);Mx=new $CLJS.K(null,"colon","colon",-965200945);$aa=new $CLJS.w(null,"if-not","if-not",-265415609,null);xy=new $CLJS.K(null,"func","func",-238706040);$CLJS.Oz=new $CLJS.K(null,"last","last",1105735132);yz=new $CLJS.K(null,"deref","deref",-145586795);
aba=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);Ty=new $CLJS.K(null,"max-iterations","max-iterations",2021275563);bba=new $CLJS.w(null,"cond","cond",1606708055,null);Wx=new $CLJS.K(null,"minpad","minpad",323570901);zw=new $CLJS.K(null,"logical-block","logical-block",-581022564);cba=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);fx=new $CLJS.K(null,"end-block-t","end-block-t",1544648735);Pz=new $CLJS.K(null,"stream","stream",1534941648);
vy=new $CLJS.K(null,"params","params",710516235);dba=new $CLJS.K(null,"circle","circle",1903212362);sw=new $CLJS.K(null,"start-pos","start-pos",668789086);fz=new $CLJS.K(null,"flags","flags",1775418075);rx=new $CLJS.K(null,"fill","fill",883462889);Vw=new $CLJS.K(null,"buffer-block","buffer-block",-10937307);iz=new $CLJS.K(null,"generator-fn","generator-fn",811851656);xw=new $CLJS.K(null,"start-col","start-col",668080143);Dy=new $CLJS.K(null,"w","w",354169001);$CLJS.My=new $CLJS.K(null,"n","n",562130025);
lz=new $CLJS.K(null,"def","def",-1043430536);$CLJS.Fy=new $CLJS.K(null,"k","k",-2146297393);Iy=new $CLJS.K(null,"e","e",1381269198);Ey=new $CLJS.K(null,"d","d",1972142424);Gy=new $CLJS.K(null,"overflowchar","overflowchar",-1620088106);$y=new $CLJS.K(null,"min-remaining","min-remaining",962687677);ey=new $CLJS.K(null,"commachar","commachar",652859327);Qz=new $CLJS.K(null,"section","section",-300141526);Rz=new $CLJS.K(null,"pretty","pretty",-1916372486);eba=new $CLJS.w(null,"let","let",358118826,null);
fw=new $CLJS.K(null,"parent","parent",-878878779);$CLJS.Py=new $CLJS.K(null,"clauses","clauses",1454841241);fba=new $CLJS.w(null,"defonce","defonce",-1681484013,null);bw=new $CLJS.K(null,"base","base",185279322);mw=new $CLJS.K(null,"type-tag","type-tag",-1873863267);gba=new $CLJS.w(null,"locking","locking",1542862874,null);Ow=new $CLJS.K(null,"pos","pos",-864607220);hba=new $CLJS.K(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.aw=new $CLJS.K(null,"cur","cur",1153190599);var $x=function $x(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return $x.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)},kx,Uw,Tz,Uz,Vz,Tx,Sx,nx,ix,Wz;$x.l=function(a){return $CLJS.$b($CLJS.Rv,$CLJS.P.g($CLJS.fy,a))};$x.A=0;$x.B=function(a){return this.l($CLJS.z(a))};var Qv=function Qv(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Qv.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
Qv.l=function(a){return $CLJS.$b($CLJS.Rv,$CLJS.P.g($CLJS.Eh,a))};Qv.A=0;Qv.B=function(a){return this.l($CLJS.z(a))};$CLJS.g=dw.prototype;$CLJS.g.P=function(a,b){return new dw(this.Ba,this.Jf,this.Xc,b)};$CLJS.g.O=function(){return this.Xh};$CLJS.g.Qb=function(){return this.Xc};$CLJS.g.pc=function(){return Gv(this.Ba)};
$CLJS.g.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?$v(this,$CLJS.aw,$CLJS.Zv(this,$CLJS.aw)+$CLJS.E(b)):($v(this,$CLJS.aw,$CLJS.E(b)-a-1),$v(this,$CLJS.gj,$CLJS.Zv(this,$CLJS.gj)+$CLJS.E($CLJS.bf(function(c){return $CLJS.F.g(c,"\n")},b)))),$CLJS.$b($CLJS.Zv(this,bw),b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return cw(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=ew.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.I.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[fw,this.parent],null),new $CLJS.Q(null,2,5,$CLJS.R,[Qz,this.Cb],null),new $CLJS.Q(null,2,5,$CLJS.R,[xw,this.tb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Dw,this.sb],null),new $CLJS.Q(null,2,5,$CLJS.R,[uw,this.vb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ew,this.wb],null),new $CLJS.Q(null,2,5,$CLJS.R,
[Jz,this.prefix],null),new $CLJS.Q(null,2,5,$CLJS.R,[Cw,this.Ab],null),new $CLJS.Q(null,2,5,$CLJS.R,[dx,this.Db],null),new $CLJS.Q(null,2,5,$CLJS.R,[$w,this.xb],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Eu(this,10,new $CLJS.Q(null,10,5,$CLJS.R,[fw,Qz,xw,Dw,uw,Ew,Jz,Cw,dx,$w],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.E(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.parent,b.parent)&&$CLJS.F.g(this.Cb,b.Cb)&&$CLJS.F.g(this.tb,b.tb)&&$CLJS.F.g(this.sb,b.sb)&&$CLJS.F.g(this.vb,b.vb)&&$CLJS.F.g(this.wb,b.wb)&&$CLJS.F.g(this.prefix,b.prefix)&&$CLJS.F.g(this.Ab,b.Ab)&&$CLJS.F.g(this.Db,b.Db)&&$CLJS.F.g(this.xb,b.xb)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,10,[dx,null,Dw,null,fw,null,Qz,null,uw,null,xw,null,Jz,null,Cw,null,$w,null,Ew,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(fw,b):$CLJS.fe.call(null,fw,b))?new ew(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Qz,b):$CLJS.fe.call(null,Qz,b))?new ew(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(xw,b):$CLJS.fe.call(null,xw,b))?new ew(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new ew(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(uw,b):$CLJS.fe.call(null,uw,b))?new ew(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ew,b):$CLJS.fe.call(null,Ew,b))?new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jz,b):$CLJS.fe.call(null,Jz,b))?new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Cw,b):$CLJS.fe.call(null,Cw,b))?new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(dx,b):$CLJS.fe.call(null,dx,b))?new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($w,b):$CLJS.fe.call(null,$w,b))?new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Pf(fw,this.parent),new $CLJS.Pf(Qz,this.Cb),new $CLJS.Pf(xw,this.tb),new $CLJS.Pf(Dw,this.sb),new $CLJS.Pf(uw,this.vb),new $CLJS.Pf(Ew,this.wb),new $CLJS.Pf(Jz,this.prefix),new $CLJS.Pf(Cw,this.Ab),new $CLJS.Pf(dx,this.Db),new $CLJS.Pf($w,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new ew(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=hw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.$b;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.I.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.kj,this.data],null),new $CLJS.Q(null,2,5,$CLJS.R,[ow,this.$b],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[rw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[mw,$CLJS.kj,ow,sw,rw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.data,b.data)&&$CLJS.F.g(this.$b,b.$b)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,5,[rw,null,ow,null,mw,null,sw,null,$CLJS.kj,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new hw(this.ca,this.data,this.$b,this.ba,this.aa,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new hw(c,this.data,this.$b,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.kj,b):$CLJS.fe.call(null,$CLJS.kj,b))?new hw(this.ca,c,this.$b,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(ow,b):$CLJS.fe.call(null,ow,b))?new hw(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new hw(this.ca,this.data,
this.$b,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(rw,b):$CLJS.fe.call(null,rw,b))?new hw(this.ca,this.data,this.$b,this.ba,c,this.S,this.G,null):new hw(this.ca,this.data,this.$b,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Pf(mw,this.ca),new $CLJS.Pf($CLJS.kj,this.data),new $CLJS.Pf(ow,this.$b),new $CLJS.Pf(sw,this.ba),new $CLJS.Pf(rw,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new hw(this.ca,this.data,this.$b,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=iw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,this.type],null),new $CLJS.Q(null,2,5,$CLJS.R,[zw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[rw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[mw,$CLJS.fj,zw,sw,rw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.type,b.type)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,5,[rw,null,$CLJS.fj,null,zw,null,mw,null,sw,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new iw(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new iw(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.fj,b):$CLJS.fe.call(null,$CLJS.fj,b))?new iw(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(zw,b):$CLJS.fe.call(null,zw,b))?new iw(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new iw(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(rw,b):$CLJS.fe.call(null,rw,b))?new iw(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new iw(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Pf(mw,this.ca),new $CLJS.Pf($CLJS.fj,this.type),new $CLJS.Pf(zw,this.ea),new $CLJS.Pf(sw,this.ba),new $CLJS.Pf(rw,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new iw(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=jw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[zw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[rw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[mw,zw,sw,rw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[rw,null,zw,null,mw,null,sw,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new jw(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new jw(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(zw,b):$CLJS.fe.call(null,zw,b))?new jw(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new jw(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(rw,b):$CLJS.fe.call(null,rw,b))?new jw(this.ca,this.ea,this.ba,c,this.S,this.G,null):new jw(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(mw,this.ca),new $CLJS.Pf(zw,this.ea),new $CLJS.Pf(sw,this.ba),new $CLJS.Pf(rw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new jw(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=kw.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[zw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[rw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[mw,zw,sw,rw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[rw,null,zw,null,mw,null,sw,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new kw(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new kw(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(zw,b):$CLJS.fe.call(null,zw,b))?new kw(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new kw(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(rw,b):$CLJS.fe.call(null,rw,b))?new kw(this.ca,this.ea,this.ba,c,this.S,this.G,null):new kw(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(mw,this.ca),new $CLJS.Pf(zw,this.ea),new $CLJS.Pf(sw,this.ba),new $CLJS.Pf(rw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new kw(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=lw.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Vb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[mw,this.ca],null),new $CLJS.Q(null,2,5,$CLJS.R,[zw,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Gz,this.Vb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,this.offset],null),new $CLJS.Q(null,2,5,$CLJS.R,[sw,this.ba],null),new $CLJS.Q(null,2,5,$CLJS.R,[rw,this.aa],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,6,new $CLJS.Q(null,6,5,$CLJS.R,[mw,zw,Gz,$CLJS.Mu,sw,rw],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ca,b.ca)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.Vb,b.Vb)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,6,[$CLJS.Mu,null,rw,null,Gz,null,zw,null,mw,null,sw,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new lw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(mw,b):$CLJS.fe.call(null,mw,b))?new lw(c,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(zw,b):$CLJS.fe.call(null,zw,b))?new lw(this.ca,c,this.Vb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Gz,b):$CLJS.fe.call(null,Gz,b))?new lw(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Mu,b):$CLJS.fe.call(null,$CLJS.Mu,
b))?new lw(this.ca,this.ea,this.Vb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(sw,b):$CLJS.fe.call(null,sw,b))?new lw(this.ca,this.ea,this.Vb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(rw,b):$CLJS.fe.call(null,rw,b))?new lw(this.ca,this.ea,this.Vb,this.offset,this.ba,c,this.S,this.G,null):new lw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Pf(mw,this.ca),new $CLJS.Pf(zw,this.ea),new $CLJS.Pf(Gz,this.Vb),new $CLJS.Pf($CLJS.Mu,this.offset),new $CLJS.Pf(sw,this.ba),new $CLJS.Pf(rw,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new lw(this.ca,this.ea,this.Vb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var pw=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("cljs.pprint","write-token"),function(f,k){return mw.h(k)},e,a,b,c,d)}();
pw.m(null,bx,function(a,b){var c=$w.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.ax):c.call(null,$CLJS.ax));b=zw.h(b);c=Jz.h(b);$CLJS.n(c)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(a))),$CLJS.aw);$CLJS.Pe(xw.h(b),a);return $CLJS.Pe(Dw.h(b),a)});pw.m(null,fx,function(a,b){var c=$w.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(ex):c.call(null,ex));b=dx.h(zw.h(b));return $CLJS.n(b)?$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),b):null});
pw.m(null,wx,function(a,b){var c=zw.h(b),d=Dw.h(c),e=$CLJS.Mu.h(b);b=Gz.h(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(ux,b):$CLJS.F.call(null,ux,b)))a=$CLJS.q(xw.h(c));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.vx,b):$CLJS.F.call(null,$CLJS.vx,b)))a=$CLJS.Zv(bw.h($CLJS.q($CLJS.q(a))),$CLJS.aw);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Pe(d,e+a)});pw.m(null,Pw,function(a,b){return $CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),$CLJS.kj.h(b))});
pw.m(null,nw,function(a,b){var c=$CLJS.F.g($CLJS.fj.h(b),px);c||(c=(c=!$CLJS.F.g($CLJS.fj.h(b),rx))?$CLJS.q(uw.h(zw.h(b))):c);$CLJS.n(c)?Fw.g?Fw.g(a,b):Fw.call(null,a,b):(b=ow.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.$b(bw.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Gh.v($CLJS.q(a),$CLJS.S,ow,null)});
var Sz=function(){var a=$CLJS.Oe($CLJS.N),b=$CLJS.Oe($CLJS.N),c=$CLJS.Oe($CLJS.N),d=$CLJS.Oe($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));return new $CLJS.Xh($CLJS.xh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.fj.h(f)},e,a,b,c,d)}();Sz.m(null,sx,function(a,b,c){a=zw.h(a);return vw(b,a,c)});Sz.m(null,qx,function(a,b,c){a=zw.h(a);return yw(b,a,c)});
Sz.m(null,rx,function(a,b,c,d){a=zw.h(a);var e=$CLJS.q(Ew.h(a));return $CLJS.n(e)?e:(d=!tw(b,d))?d:yw(b,a,c)});Sz.m(null,px,function(){return!0});
var Hw=function Hw(a,b){var d=Gw(b);b=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);$CLJS.n(b)&&qw(a,b,!1);if($CLJS.n(e)){d=Aw(e);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=$CLJS.B(e),k=Sz.v(f,a,b,Bw(e));$CLJS.n(k)&&(Fw(a,f),e=$CLJS.C(e));tw(a,e)?a=e:(e=Hw.g?Hw.g(a,b):Hw.call(null,a,b),$CLJS.F.g(e,b)?(qw(a,b,!1),a=d):a=$CLJS.Wf.g($CLJS.tf,$CLJS.Ye.g(e,d)));return a}return null};$CLJS.g=Sw.prototype;$CLJS.g.P=function(a,b){return new Sw(this.Ba,this.Jf,this.gi,this.hh,this.Xc,b)};$CLJS.g.O=function(){return this.Yh};
$CLJS.g.Qb=function(){return this.Xc};
$CLJS.g.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a))){var c=Rw(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.ri.h($CLJS.q($CLJS.q(this)));if($CLJS.F.g(d,Qw))return Lw(this),$CLJS.$b(bw.h($CLJS.q($CLJS.q(this))),b),$CLJS.Gh.v($CLJS.q(this),$CLJS.S,ow,a);d=Ow.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.E(c);$CLJS.Gh.v($CLJS.q(this),$CLJS.S,Ow,c);return Kw(this,new hw(Pw,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.g($CLJS.ri.h($CLJS.q($CLJS.q(this))),Qw)?(Lw(this),b=$CLJS.$b(bw.h($CLJS.q($CLJS.q(this))),b)):$CLJS.F.g(b,"\n")?b=Rw(this,"\n"):(a=Ow.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Gh.v($CLJS.q(this),$CLJS.S,Ow,c),b=Jv(b),b=Kw(this,new hw(Pw,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.pc=function(){this.sf(null);return Gv(bw.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.sf=function(){return $CLJS.F.g($CLJS.ri.h($CLJS.q($CLJS.q(this))),Nw)?(qw(this,$CLJS.Ku.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Gh.v($CLJS.q(this),$CLJS.S,$CLJS.Ku,$CLJS.tf)):Lw(this)};$CLJS.jx=!0;kx=null;$CLJS.Tw=72;Uw=40;Tz=null;Uz=null;Vz=null;Tx=null;Sx=10;nx=0;ix=null;Wz=function Wz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Wz.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
Wz.l=function(a,b){var c=$CLJS.Xk.l($CLJS.G([new $CLJS.h(null,1,[Pz,!0],null),$CLJS.P.g(Fz,b)]));b=Sx;var d=Uz,e=$CLJS.Ma,f=$CLJS.ih,k=Tz,l=Uw,m=kx,t=$CLJS.jx,u=Tx,v=$CLJS.Ia,x=$CLJS.Tw,A=Vz,D=bw.g(c,Sx),J=dba.g(c,Uz),M=$CLJS.Iz.g(c,$CLJS.Ma),V=$CLJS.Qu.g(c,$CLJS.ih),Z=Zaa.g(c,Tz),fa=ww.g(c,Uw),Da=$CLJS.Ii.g(c,kx),Ha=Rz.g(c,$CLJS.jx),mb=Paa.g(c,Tx),Qb=$CLJS.Ga.g(c,$CLJS.Ia),Ab=Xaa.g(c,$CLJS.Tw),jc=hba.g(c,Vz);Sx=D;Uz=J;$CLJS.Ma=M;$CLJS.ih=V;Tz=Z;Uw=fa;kx=Da;$CLJS.jx=Ha;Tx=mb;$CLJS.Ia=Qb;$CLJS.Tw=
Ab;Vz=jc;try{var Tb=new $CLJS.Ea,rc=$CLJS.Gd(c,Pz)?Pz.h(c):!0,Vb=!0===rc||null==rc?new $CLJS.sc(Tb):rc;if($CLJS.n($CLJS.jx)){var Sb=$CLJS.Sa($CLJS.hx(Vb));c=$CLJS.Rv;$CLJS.Rv=Sb?$CLJS.Zw(Vb):Vb;try{$CLJS.lx(a),$CLJS.Yv()}finally{$CLJS.Rv=c}}else{Sb=$CLJS.Rv;$CLJS.Rv=Vb;try{Qv.call(null,a)}finally{$CLJS.Rv=Sb}}!0===rc&&$CLJS.mh($CLJS.p.h(Tb));return null==rc?$CLJS.p.h(Tb):null}finally{Vz=A,$CLJS.Tw=x,$CLJS.Ia=v,Tx=u,$CLJS.jx=t,kx=m,Uw=l,Tz=k,$CLJS.ih=f,$CLJS.Ma=e,Uz=d,Sx=b}};Wz.A=1;
Wz.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var Dx=null;$CLJS.g=Fx.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "seq":return this.sc;case "rest":return this.pb;case "pos":return this.Zb;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Gx,this.sc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ji,this.pb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ow,this.Zb],null)],null),this.G))};$CLJS.g.Ca=function(){return new $CLJS.Eu(this,3,new $CLJS.Q(null,3,5,$CLJS.R,[Gx,$CLJS.Ji,Ow],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.sc,b.sc)&&$CLJS.F.g(this.pb,b.pb)&&$CLJS.F.g(this.Zb,b.Zb)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,3,[Ow,null,Gx,null,$CLJS.Ji,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new Fx(this.sc,this.pb,this.Zb,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Gx,b):$CLJS.fe.call(null,Gx,b))?new Fx(c,this.pb,this.Zb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Ji,b):$CLJS.fe.call(null,$CLJS.Ji,b))?new Fx(this.sc,c,this.Zb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ow,b):$CLJS.fe.call(null,Ow,b))?new Fx(this.sc,this.pb,c,this.S,this.G,null):new Fx(this.sc,this.pb,this.Zb,this.S,$CLJS.S.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Pf(Gx,this.sc),new $CLJS.Pf($CLJS.Ji,this.pb),new $CLJS.Pf(Ow,this.Zb)],null),this.G))};$CLJS.g.P=function(a,b){return new Fx(this.sc,this.pb,this.Zb,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=Lx.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.K?b.T:null){case "func":return this.cc;case "def":return this.bc;case "params":return this.jc;case "offset":return this.offset;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.kh(b,function(d){return $CLJS.kh(b,$CLJS.qh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[xy,this.cc],null),new $CLJS.Q(null,2,5,$CLJS.R,[lz,this.bc],null),new $CLJS.Q(null,2,5,$CLJS.R,[vy,this.jc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Mu,this.offset],null)],null),this.G))};
$CLJS.g.Ca=function(){return new $CLJS.Eu(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[xy,lz,vy,$CLJS.Mu],null),$CLJS.n(this.G)?$CLJS.qc(this.G):$CLJS.Fe())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.cc,b.cc)&&$CLJS.F.g(this.bc,b.bc)&&$CLJS.F.g(this.jc,b.jc)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,4,[$CLJS.Mu,null,xy,null,vy,null,lz,null],null),null),b)?$CLJS.xk.g($CLJS.Jb($CLJS.Wf.g($CLJS.N,this),this.S),b):new Lx(this.cc,this.bc,this.jc,this.offset,this.S,$CLJS.De($CLJS.xk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.K?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Gd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(xy,b):$CLJS.fe.call(null,xy,b))?new Lx(c,this.bc,this.jc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(lz,b):$CLJS.fe.call(null,lz,b))?new Lx(this.cc,c,this.jc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(vy,b):$CLJS.fe.call(null,vy,b))?new Lx(this.cc,this.bc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Mu,b):$CLJS.fe.call(null,$CLJS.Mu,b))?new Lx(this.cc,this.bc,this.jc,c,this.S,
this.G,null):new Lx(this.cc,this.bc,this.jc,this.offset,this.S,$CLJS.S.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.Ye.g(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Pf(xy,this.cc),new $CLJS.Pf(lz,this.bc),new $CLJS.Pf(vy,this.jc),new $CLJS.Pf($CLJS.Mu,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new Lx(this.cc,this.bc,this.jc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.yd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var Ux=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),hy=new $CLJS.Q(null,20,5,$CLJS.R,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),Xz=new $CLJS.Q(null,20,5,$CLJS.R,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),iy=new $CLJS.Q(null,10,5,$CLJS.R,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),iba=new $CLJS.Q(null,10,5,$CLJS.R,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),ky=new $CLJS.Q(null,22,5,$CLJS.R," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),jba=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),kba=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),ny=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);bz.prototype.P=function(a,b){return new bz(this.Ba,b)};bz.prototype.O=function(){return this.Zh};bz.prototype.pc=function(){return Gv(this.Ba)};
bz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.$b(this.Ba,Jv(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};cz.prototype.P=function(a,b){return new cz(this.Ba,b)};cz.prototype.O=function(){return this.$h};cz.prototype.pc=function(){return Gv(this.Ba)};
cz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,b.toUpperCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.$b(this.Ba,Jv(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};dz.prototype.P=function(a,b){return new dz(this.Ba,this.Id,b)};dz.prototype.O=function(){return this.ai};dz.prototype.pc=function(){return Gv(this.Ba)};
dz.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.$b(this.Ba,faa(b.toLowerCase(),$CLJS.q(this.Id))),0<b.length?$CLJS.Pe(this.Id,$CLJS.za($CLJS.bd(b,$CLJS.E(b)-1))):null;if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=Jv(b),a=$CLJS.n($CLJS.q(this.Id))?b.toUpperCase():b,$CLJS.$b(this.Ba,a),$CLJS.Pe(this.Id,$CLJS.za(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
ez.prototype.P=function(a,b){return new ez(this.Ba,this.ud,b)};ez.prototype.O=function(){return this.bi};ez.prototype.pc=function(){return Gv(this.Ba)};
ez.prototype.Kc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Sa($CLJS.q(this.ud))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.$b(this.Ba,[b.substring(0,a),$CLJS.bd(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Pe(this.ud,!0)):$CLJS.$b(this.Ba,b)):$CLJS.$b(this.Ba,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=Jv(b),a=$CLJS.Sa($CLJS.q(this.ud)),
$CLJS.n(a?Ev(b):a)?($CLJS.Pe(this.ud,!0),$CLJS.$b(this.Ba,b.toUpperCase())):$CLJS.$b(this.Ba,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var saa=$CLJS.Ag("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[gz,"A",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Yx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return ay($CLJS.fy,a,b)}}],null),new $CLJS.h(null,
5,[gz,"S",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Yx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return ay($CLJS.Eh,a,b)}}],null),new $CLJS.h(null,5,[gz,"D",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,
2,5,$CLJS.R,[" ",String],null),ey,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),dy,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return gy(10,a,b)}}],null),new $CLJS.h(null,5,[gz,"B",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),ey,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),dy,new $CLJS.Q(null,
2,5,$CLJS.R,[3,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return gy(2,a,b)}}],null),new $CLJS.h(null,5,[gz,"O",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),ey,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),dy,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,
null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return gy(8,a,b)}}],null),new $CLJS.h(null,5,[gz,"X",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),ey,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),dy,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){return gy(16,a,b)}}],null),new $CLJS.h(null,
5,[gz,"R",vy,new $CLJS.h(null,5,[bw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),ey,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),dy,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(a){return $CLJS.n($CLJS.B(bw.h(a)))?function(b,c){return gy(bw.h(b),b,c)}:$CLJS.n(function(){var b=Nx.h(a);return $CLJS.n(b)?
Mx.h(a):b}())?function(b,c){return my(jba,c)}:$CLJS.n(Nx.h(a))?function(b,c){return my(kba,c)}:$CLJS.n(Mx.h(a))?function(b,c){b=Hx(c);c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if($CLJS.F.g(0,c))$x.l($CLJS.G(["zeroth"]));else{var d=by(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(ky)){var e=$CLJS.Ze.g(jy,$CLJS.Pv(1,d));e=ly(e,1);var f=$CLJS.id(d);d=$CLJS.Td(f,100);f=Kv(f,100);var k=0<d?[$CLJS.p.h($CLJS.bd(hy,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.bd(Xz,f);else{t=$CLJS.Td(f,10);
var u=Kv(f,10);t=0<t&&!(0<u)?$CLJS.bd(iba,t):[$CLJS.p.h(0<t?$CLJS.bd(iy,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.bd(Xz,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");$x.l($CLJS.G([[0>c?"minus ":null,$CLJS.sd(e)||$CLJS.sd(d)?$CLJS.sd(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else gy(10,new $CLJS.h(null,5,[Xx,0,Zx," ",ey,",",dy,3,Mx,!0],null),zx(new $CLJS.Q(null,1,5,$CLJS.R,[c],null))),e=Kv(c,100),c=11<e||19>e,e=Kv(e,10),$x.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Hx(c);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.F.g(0,b))$x.l($CLJS.G(["zero"]));else{var d=by(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(ky)?(d=$CLJS.Ze.g(jy,d),d=ly(d,0),$x.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):gy(10,new $CLJS.h(null,5,[Xx,0,Zx," ",ey,",",dy,3,Mx,!0],null),zx(new $CLJS.Q(null,1,5,$CLJS.R,[b],null)))}return c}}],null),new $CLJS.h(null,5,[gz,"P",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,
null,hz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){b=$CLJS.n(Mx.h(a))?Jx(b,-1):b;a=$CLJS.n(Nx.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,["y","ies"],null):new $CLJS.Q(null,2,5,$CLJS.R,["","s"],null);var c=Hx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);$x.l($CLJS.G([$CLJS.F.g(b,1)?$CLJS.B(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[gz,"C",vy,new $CLJS.h(null,1,[py,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,
hz,null],null),null),kz,$CLJS.N,iz,function(a){return $CLJS.n(Mx.h(a))?oy:$CLJS.n(Nx.h(a))?qy:ry}],null),new $CLJS.h(null,5,[gz,"F",vy,new $CLJS.h(null,5,[Dy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Fy,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Gy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Nx,null],null),null),kz,$CLJS.N,iz,function(){return Hy}],
null),new $CLJS.h(null,5,[gz,"E",vy,new $CLJS.h(null,7,[Dy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Iy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Fy,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Gy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Jy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Nx,null],null),null),kz,$CLJS.N,
iz,function(){return Ky}],null),new $CLJS.h(null,5,[gz,"G",vy,new $CLJS.h(null,7,[Dy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Ey,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Iy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Fy,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Gy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Jy,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Nx,null],
null),null),kz,$CLJS.N,iz,function(){return Ly}],null),new $CLJS.h(null,5,[gz,"$",vy,new $CLJS.h(null,4,[Ey,new $CLJS.Q(null,2,5,$CLJS.R,[2,Number],null),$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Dy,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return Ny}],null),new $CLJS.h(null,5,[gz,"%",vy,new $CLJS.h(null,1,[$CLJS.Kz,new $CLJS.Q(null,
2,5,$CLJS.R,[1,Number],null)],null),fz,$CLJS.Ug,kz,$CLJS.N,iz,function(){return function(a,b){a=$CLJS.Kz.h(a);for(var c=0;;)if(c<a)Sv(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[gz,"\x26",vy,new $CLJS.h(null,1,[$CLJS.Kz,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Rz,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){a=$CLJS.Kz.h(a);0<a&&((null!=$CLJS.Rv?$CLJS.Rv.C&32768||$CLJS.r===$CLJS.Rv.Wf||($CLJS.Rv.C?0:$CLJS.Wa($CLJS.Fv,
$CLJS.Rv)):$CLJS.Wa($CLJS.Fv,$CLJS.Rv))?$CLJS.F.g(0,$CLJS.Zv(bw.h($CLJS.q($CLJS.q($CLJS.Rv))),$CLJS.aw))||Sv():Sv());--a;for(var c=0;;)if(c<a)Sv(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[gz,"|",vy,new $CLJS.h(null,1,[$CLJS.Kz,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),fz,$CLJS.Ug,kz,$CLJS.N,iz,function(){return function(a,b){a=$CLJS.Kz.h(a);for(var c=0;;)if(c<a)$x.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[gz,"~",vy,new $CLJS.h(null,1,[$CLJS.My,
new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),fz,$CLJS.Ug,kz,$CLJS.N,iz,function(){return function(a,b){a=$CLJS.My.h(a);$x.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[gz,"\n",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Mx,null,Nx,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){$CLJS.n(Nx.h(a))&&Sv();return b}}],null),new $CLJS.h(null,5,[gz,"T",vy,new $CLJS.h(null,2,[Hz,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Yx,new $CLJS.Q(null,
2,5,$CLJS.R,[1,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Nx,null,Rz,null],null),null),kz,$CLJS.N,iz,function(a){return $CLJS.n(Nx.h(a))?function(b,c){var d=Hz.h(b);b=Yx.h(b);var e=d+$CLJS.Zv(bw.h($CLJS.q($CLJS.q($CLJS.Rv))),$CLJS.aw);e=0<b?Kv(e,b):0;d+=$CLJS.F.g(0,e)?0:b-e;$x.l($CLJS.G([$CLJS.P.g($CLJS.p,$CLJS.Ve(d," "))]));return c}:function(b,c){var d=Hz.h(b);b=Yx.h(b);var e=$CLJS.Zv(bw.h($CLJS.q($CLJS.q($CLJS.Rv))),$CLJS.aw);d=e<d?d-e:$CLJS.F.g(b,0)?0:b-Kv(e-d,b);$x.l($CLJS.G([$CLJS.P.g($CLJS.p,
$CLJS.Ve(d," "))]));return c}}],null),new $CLJS.h(null,5,[gz,"*",vy,new $CLJS.h(null,1,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,2,[Mx,null,Nx,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){var c=$CLJS.My.h(a);return $CLJS.n(Nx.h(a))?Kx(b,c):Jx(b,$CLJS.n(Mx.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[gz,"?",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Nx,null],null),null),kz,$CLJS.N,iz,function(a){return $CLJS.n(Nx.h(a))?
function(b,c){var d=Ix(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return yy(c,d,wy.h(b))}:function(b,c){var d=Ix(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=Hx(d);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);d=zx(d);yy(c,d,wy.h(b));return e}}],null),new $CLJS.h(null,5,[gz,"(",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,new $CLJS.h(null,3,[$CLJS.nz,")",rz,null,$CLJS.Lu,null],null),iz,function(a){var b=$CLJS.n(function(){var c=Nx.h(a);return $CLJS.n(c)?
Mx.h(a):c}())?eaa:$CLJS.n(Mx.h(a))?gaa:$CLJS.n(Nx.h(a))?haa:daa;return function(c,d){a:{var e=$CLJS.B($CLJS.Py.h(c)),f=$CLJS.Rv;$CLJS.Rv=b.h?b.h($CLJS.Rv):b.call(null,$CLJS.Rv);try{var k=yy(e,d,wy.h(c));break a}finally{$CLJS.Rv=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[gz,")",vy,$CLJS.N,fz,$CLJS.Ug,kz,$CLJS.N,iz,function(){return null}],null),new $CLJS.h(null,5,[gz,"[",vy,new $CLJS.h(null,1,[Oy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,2,
[Mx,null,Nx,null],null),null),kz,new $CLJS.h(null,3,[$CLJS.nz,"]",rz,!0,$CLJS.Lu,$CLJS.Oz],null),iz,function(a){return $CLJS.n(Mx.h(a))?Ry:$CLJS.n(Nx.h(a))?Sy:Qy}],null),new $CLJS.h(null,5,[gz,";",vy,new $CLJS.h(null,2,[$y,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),az,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Mx,null],null),null),kz,new $CLJS.h(null,1,[$CLJS.Ou,!0],null),iz,function(){return null}],null),new $CLJS.h(null,5,[gz,"]",vy,$CLJS.N,
fz,$CLJS.Ug,kz,$CLJS.N,iz,function(){return null}],null),new $CLJS.h(null,5,[gz,"{",vy,new $CLJS.h(null,1,[Ty,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,new $CLJS.h(null,2,[$CLJS.nz,"}",rz,!1],null),iz,function(a){var b=Nx.h(a);b=$CLJS.n(b)?Mx.h(a):b;return $CLJS.n(b)?aaa:$CLJS.n(Mx.h(a))?Wy:$CLJS.n(Nx.h(a))?Xy:Vy}],null),new $CLJS.h(null,5,[gz,"}",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Mx,null],
null),null),kz,$CLJS.N,iz,function(){return null}],null),new $CLJS.h(null,5,[gz,"\x3c",vy,new $CLJS.h(null,4,[Xx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Yx,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Wx,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Zx,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,4,[Mx,null,Nx,null,hz,null,Rz,null],null),null),kz,new $CLJS.h(null,3,[$CLJS.nz,"\x3e",rz,!0,$CLJS.Lu,qz],null),iz,function(){return caa}],null),new $CLJS.h(null,
5,[gz,"\x3e",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Mx,null],null),null),kz,$CLJS.N,iz,function(){return null}],null),new $CLJS.h(null,5,[gz,"^",vy,new $CLJS.h(null,3,[Lz,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Nz,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Mz,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Mx,null],null),null),kz,$CLJS.N,iz,function(){return function(a,b){var c=Lz.h(a),d=Nz.h(a),e=Mz.h(a),f=$CLJS.n(Mx.h(a))?
ty:sy;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.F.g(c,d)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.n(c)?$CLJS.F.g(c,0)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:($CLJS.n(Mx.h(a))?$CLJS.sd($CLJS.Ji.h(wy.h(a))):$CLJS.sd($CLJS.Ji.h(b)))?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b}}],null),new $CLJS.h(null,5,[gz,"W",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,4,[Mx,null,Nx,null,hz,null,Rz,null],null),null),
kz,$CLJS.N,iz,function(a){if($CLJS.n(function(){var c=Nx.h(a);return $CLJS.n(c)?c:Mx.h(a)}())){var b=$CLJS.Ye.g($CLJS.n(Nx.h(a))?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Qu,null,$CLJS.Iz,null],null):$CLJS.tf,$CLJS.n(Mx.h(a))?new $CLJS.Q(null,2,5,$CLJS.R,[Rz,!0],null):$CLJS.tf);return function(c,d){d=Hx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.P.j(Wz,c,b))?new $CLJS.Q(null,2,5,$CLJS.R,[sy,d],null):d}}return function(c,d){d=Hx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.lx(c))?
new $CLJS.Q(null,2,5,$CLJS.R,[sy,d],null):d}}],null),new $CLJS.h(null,5,[gz,"_",vy,$CLJS.N,fz,new $CLJS.Tg(null,new $CLJS.h(null,3,[Mx,null,Nx,null,hz,null],null),null),kz,$CLJS.N,iz,function(){return jaa}],null),new $CLJS.h(null,5,[gz,"I",vy,new $CLJS.h(null,1,[$CLJS.My,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null)],null),fz,new $CLJS.Tg(null,new $CLJS.h(null,1,[Mx,null],null),null),kz,$CLJS.N,iz,function(){return iaa}],null)]),kaa=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,qaa=new $CLJS.Tg(null,new $CLJS.h(null,
2,[Px,null,Ox,null],null),null),naa=new $CLJS.h(null,2,[":",Mx,"@",Nx],null),Ax=function Ax(a){for(;;){if($CLJS.sd(a))return!1;var c=Rz.h(fz.h(lz.h($CLJS.B(a))));$CLJS.n(c)||(c=$CLJS.Je(Ax,$CLJS.B($CLJS.Py.h(vy.h($CLJS.B(a))))),c=$CLJS.n(c)?c:$CLJS.Je(Ax,$CLJS.B($CLJS.Lu.h(vy.h($CLJS.B(a))))));if($CLJS.n(c))return!0;a=$CLJS.C(a)}},Bz=$CLJS.Hh(yx),sz=new $CLJS.h(null,6,[$CLJS.Kj,"'",$CLJS.Oj,"#'",$CLJS.Nu,"@",$CLJS.Pu,"~",Saa,"@",Qaa,"~"],null);
(function(){var a=Bz("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=zx(d);return Bx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()})();
var Yz=function(){var a=Bz("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=zx(d);return Bx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),Zz=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),lba=function(){var a=Bz("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=zx(d);return Bx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),$z,mba=$CLJS.Oe($CLJS.N),nba=$CLJS.Oe($CLJS.N),oba=$CLJS.Oe($CLJS.N),pba=$CLJS.Oe($CLJS.N),qba=$CLJS.I.j($CLJS.N,$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
$z=new $CLJS.Xh($CLJS.xh.g("cljs.pprint","simple-dispatch"),zz,qba,mba,nba,oba,pba);Lv($z,$CLJS.vj,function(a){if($CLJS.Sa(tz(a)))if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("(",")");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.lx($CLJS.B(e)),$CLJS.C(e))){$CLJS.$b($CLJS.Rv," ");tx(sx);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.$b($CLJS.Rv,"...");break}gx()}finally{ix=c,nx=b}}return null});Lv($z,$CLJS.Ej,uz);Lv($z,$CLJS.bk,vz);
Lv($z,$CLJS.Si,Yz);Lv($z,null,function(){return $CLJS.$b($CLJS.Rv,$CLJS.Eh.l($CLJS.G([null])))});Lv($z,$CLJS.Wh,wz);kx=$z;
var aA=function(){var a=Bz("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=zx(d);return Bx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),bA=function(){var a=Bz("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=zx(d);return Bx(a,d)}b.A=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),cA=$CLJS.N,rba=function(a){return $CLJS.Wf.g($CLJS.N,$CLJS.$e($CLJS.Rd,$CLJS.G([function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.zd(e)){var f=$CLJS.kc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.Q(null,
2,5,$CLJS.R,[t,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.h($CLJS.Zg($CLJS.B(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.lc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce(new $CLJS.Q(null,2,5,$CLJS.R,[l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.h($CLJS.Zg($CLJS.B(l))),$CLJS.hd(l)],null)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.Wf.g($CLJS.N,$CLJS.Ze.g(function(b){var c=$CLJS.H(b,0,null),d=$CLJS.H(b,
1,null);var e=$CLJS.ge(c);e=$CLJS.n(e)?e:$CLJS.Gd(new $CLJS.Tg(null,new $CLJS.h(null,24,[$CLJS.Ti,"null",$CLJS.jj,"null",$CLJS.ik,"null",$CLJS.kk,"null",$CLJS.ej,"null",$CLJS.Gj,"null",$CLJS.Cj,"null",$CLJS.Hj,"null",$CLJS.ki,"null",$CLJS.Rj,"null",$CLJS.uj,"null",$CLJS.mj,"null",$CLJS.Yj,"null",$CLJS.jk,"null",$CLJS.Li,"null",$CLJS.Hi,"null",$CLJS.cj,"null",$CLJS.oj,"null",$CLJS.Ui,"null",$CLJS.Oj,"null",$CLJS.Kj,"null",$CLJS.dk,"null",$CLJS.Di,"null",$CLJS.ak,"null"],null),null),c);return $CLJS.Sa(e)?
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.xh.g("clojure.core",$CLJS.Zg(c)),d],null):b},a))}($CLJS.Ag([$CLJS.Ui,$CLJS.Hi,Iaa,$CLJS.Rj,Naa,Faa,Haa,Oaa,Laa,Kaa,Maa,$aa,Uaa,$CLJS.ak,Taa,Yaa,Waa,fba,Daa,$CLJS.mj,aba,bba,eba,$CLJS.Lj,Raa,gba,Eaa,Gaa,Jaa,cba],[aA,function(a){var b=$CLJS.hd(a),c=$CLJS.B($CLJS.Kc($CLJS.Kc(a)));if($CLJS.yd(b)){a=cA;cA=$CLJS.F.g(1,$CLJS.E(b))?$CLJS.Ce([$CLJS.B(b),"%"]):$CLJS.Wf.g($CLJS.N,$CLJS.Ze.j(function(d,e){return new $CLJS.Q(null,2,5,$CLJS.R,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Nv(1,$CLJS.E(b)+1)));try{return function(){var d=Bz("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.y(m,0,null)}return f.call(this,l)}function f(k){k=zx(k);return Bx(d,k)}e.A=0;e.B=function(k){k=$CLJS.z(k);return f(k)};e.l=f;return e}()}()(c)}finally{cA=a}}else return Cz(a)},Ez,bA,function(a){if(3<$CLJS.E(a)){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,
"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("(",")");xx(ux,1);$CLJS.P.g(function(){var l=Bz("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=zx(u);return Bx(l,u)}m.A=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.z($CLJS.Te(3,a));;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,
"#");else{a=nx;var f=ix;nx+=1;ix=0;try{cx(null,null),$CLJS.lx($CLJS.B(e)),$CLJS.C(e)&&($CLJS.$b($CLJS.Rv," "),tx(qx),$CLJS.lx($CLJS.hd(e))),gx()}finally{ix=f,nx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.$b($CLJS.Rv," ");tx(sx);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.$b($CLJS.Rv,"...");break}gx()}finally{ix=c,nx=b}}return null}return Cz(a)},aA,Dz,Dz,Ez,aA,Ez,bA,bA,aA,bA,Ez,Ez,aA,Ez,function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=
"string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=$CLJS.wd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{d=nx;var k=ix;nx+=1;ix=0;try{cx("(",")");(function(){var m=Bz("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=zx(v);return Bx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.z(f))&&function(){var m=Bz("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,
x)}function u(v){v=zx(v);return Bx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&Cx(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.n(e)?e:$CLJS.z(f)]));$CLJS.n(e)&&function(){var m=Bz("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=zx(v);return Bx(m,v)}t.A=0;t.B=function(v){v=$CLJS.z(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.z(f));for(a=f;;){zaa($CLJS.B(a));var l=$CLJS.C(a);if(l)b=l,tx(sx),a=b;else break}gx()}finally{ix=k,nx=d}}return null}return $CLJS.lx(a)},Ez,function(a){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{var b=nx,c=ix;nx+=1;ix=0;try{cx("(",")");xx(ux,1);$CLJS.lx($CLJS.B(a));if($CLJS.C(a)){$CLJS.$b($CLJS.Rv," ");tx(sx);for(var d=0,e=$CLJS.C(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{a=nx;var f=ix;nx+=1;ix=0;try{cx(null,null),$CLJS.lx($CLJS.B(e)),
$CLJS.C(e)&&($CLJS.$b($CLJS.Rv," "),tx(qx),$CLJS.lx($CLJS.hd(e))),gx()}finally{ix=f,nx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.$b($CLJS.Rv," ");tx(sx);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.$b($CLJS.Rv,"...");break}}gx()}finally{ix=c,nx=b}}return null},Ez,Dz,Dz,aA,aA,Ez,Ez,aA]))),dA,sba=$CLJS.Oe($CLJS.N),tba=$CLJS.Oe($CLJS.N),uba=$CLJS.Oe($CLJS.N),vba=$CLJS.Oe($CLJS.N),wba=$CLJS.I.j($CLJS.N,$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));
dA=new $CLJS.Xh($CLJS.xh.g("cljs.pprint","code-dispatch"),zz,wba,sba,tba,uba,vba);Lv(dA,$CLJS.vj,function(a){if($CLJS.Sa(tz(a))){var b=rba.call(null,$CLJS.B(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Cz(a)}return null});Lv(dA,$CLJS.Ai,function(a){var b=a.h?a.h(cA):a.call(null,cA);return $CLJS.n(b)?$x.l($CLJS.G([b])):$CLJS.n(Vz)?$x.l($CLJS.G([$CLJS.Zg(a)])):Qv.call(null,a)});Lv(dA,$CLJS.Ej,uz);Lv(dA,$CLJS.bk,vz);Lv(dA,$CLJS.Si,Yz);Lv(dA,xz,lba);
Lv(dA,yz,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.Xa(a).name;var e=$CLJS.gh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?Zz.h?Zz.h(e):Zz.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.xa(a)),": "].join("");if($CLJS.n(ox()))$CLJS.$b($CLJS.Rv,"#");else{c=nx;d=ix;nx+=1;ix=0;try{cx(b,"\x3e");xx(ux,-(b.length-2));tx(sx);var f=null!=a?a.I&1||$CLJS.r===a.dj?!0:a.I?!1:$CLJS.Wa(Hv,a):$CLJS.Wa(Hv,a);var k=f?!Iv(a):f;$CLJS.lx(k?Vaa:$CLJS.q(a));gx()}finally{ix=d,nx=c}}return null});Lv(dA,null,Qv);
Lv(dA,$CLJS.Wh,wz);kx=$z;