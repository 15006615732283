var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var R7,S7,T7,kpa,lpa,Z7,$7,b8,c8,mpa,d8,e8,npa,opa;$CLJS.Q7=function(a){return $CLJS.T5.h(a)};R7=function(a){return $CLJS.S.j(a,$CLJS.xj,$CLJS.k5)};S7=function(a,b){return $CLJS.F.g($CLJS.bG.h(a),$CLJS.Ul(b,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qG,$CLJS.aG,$CLJS.pG],null)))};T7=function(a){var b=new $CLJS.h(null,3,[$CLJS.mG,$CLJS.p.h($CLJS.wG()),$CLJS.iD,$CLJS.iD.h(a),$CLJS.yi,$CLJS.rC($CLJS.yi,$CLJS.iD)(a)],null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fD,b,$CLJS.T.h(a)],null)};
kpa=function(a){return $CLJS.f2(a,new $CLJS.h(null,1,[$CLJS.qG,$CLJS.rh],null))};
$CLJS.U7=function(a,b){var c=$CLJS.Je(function(e){return $CLJS.fJ(function(f){return $CLJS.F.g($CLJS.I.g(f,e),a)},b)},new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.k0,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.BC($CLJS.xA);if($CLJS.n($CLJS.AC("metabase.lib.field",c))){var d=$CLJS.YF("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Eh.l($CLJS.G([a])),$CLJS.Eh.l($CLJS.G([$CLJS.Hk.g($CLJS.k0,b)]))]));d instanceof Error?$CLJS.zC("metabase.lib.field",c,$CLJS.fy(),d):$CLJS.zC("metabase.lib.field",
c,$CLJS.fy.l($CLJS.G([d])),null)}return null};
lpa=function(a,b,c){if($CLJS.n(V7))return null;var d=V7;V7=!0;try{var e=$CLJS.P2(a,b),f=$CLJS.n(e)?$CLJS.hY(a,e):$CLJS.hY(a,b),k=function(){var m=$CLJS.W7.h(f);if($CLJS.n(m))return m;m=$CLJS.sC(f,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PW,$CLJS.eY],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.pX.h(f);if($CLJS.n(u))return u;u=$CLJS.HQ.h(f);if($CLJS.n(u))return u;u=$CLJS.BR.h(f);return $CLJS.n(u)?u:$CLJS.FG.h(f)}())?$CLJS.c2.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.BC($CLJS.xA);if($CLJS.n($CLJS.AC("metabase.lib.field",
m))){var t=$CLJS.YF("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Eh.l($CLJS.G([c]))]));return t instanceof Error?$CLJS.zC("metabase.lib.field",m,$CLJS.fy(),t):$CLJS.zC("metabase.lib.field",m,$CLJS.fy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(k);return m?$CLJS.U7(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.S.j($CLJS.S.j($CLJS.i4($CLJS.xk.l(l,$CLJS.Ri,$CLJS.G([$CLJS.DM,$CLJS.X7,$CLJS.Y7])),null),$CLJS.T,function(){var m=$CLJS.k0.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.sM,$CLJS.Y_):l:null}finally{V7=d}};
Z7=function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.O(d);d=$CLJS.I.g(e,$CLJS.WQ);var f=$CLJS.H(c,2,null);c=$CLJS.Xk.l($CLJS.G([function(){var k=$CLJS.iD.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.iD,k],null):null}(),function(){var k=$CLJS.rC($CLJS.yi,$CLJS.iD)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.yi,k],null):null}(),function(){var k=$CLJS.XS.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.X7,k],null):null}(),function(){var k=$CLJS.bI.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.Y7,k],null):null}(),$CLJS.Ed(f)?function(){var k=$CLJS.T2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.xj,$CLJS.qM,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.xj,$CLJS.qM,$CLJS.T,f],null):function(){var k=lpa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.xj,$CLJS.qM,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.i4(c,d):c};$7=function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.Y7);return $CLJS.n($CLJS.n(b)?$CLJS.Gd($CLJS.cJ,b):b)?$CLJS.pj:$CLJS.rC($CLJS.yi,$CLJS.iD)(a)};
$CLJS.a8=function(a,b,c,d){$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null),f=$CLJS.O(e),k=$CLJS.I.g(f,$CLJS.mG),l=$CLJS.I.g(f,$CLJS.iD),m=$CLJS.I.g(f,$CLJS.XS),t=$CLJS.I.g(f,$CLJS.yi);e=$CLJS.I.g(f,$CLJS.WQ);var u=$CLJS.I.g(f,$CLJS.gP),v=$CLJS.I.g(f,$CLJS.bI),x=$CLJS.Xk.l;k=new $CLJS.h(null,2,[$CLJS.xj,$CLJS.qM,$CLJS.Q1,k],null);f=$CLJS.cG.h(f);a=$CLJS.n(f)?f:$CLJS.u1.j(a,b,d);c=x.call($CLJS.Xk,$CLJS.G([k,c,new $CLJS.h(null,1,[$CLJS.cG,a],null)]));c=$CLJS.n(t)?$CLJS.S.j(c,$CLJS.yi,t):c;l=$CLJS.n(l)?$CLJS.S.j(c,
$CLJS.iD,l):c;v=$CLJS.n(v)?$CLJS.S.j(l,$CLJS.Y7,v):l;m=$CLJS.n(m)?$CLJS.S.j(v,$CLJS.X7,m):v;u=$CLJS.n(u)?$CLJS.S.j(m,$CLJS.CM,u):m;return $CLJS.n(e)?$CLJS.i4(u,e):u};b8=function(a,b,c){return $CLJS.n($CLJS.Je(function(d){return $CLJS.F.g($CLJS.li.h(d),c)},a))?$CLJS.Hk.g(function(d){var e=$CLJS.xk.g(d,b);return $CLJS.F.g($CLJS.li.h(d),c)?$CLJS.S.j(e,b,!0):e},a):a};
c8=function(a){var b=$CLJS.n($CLJS.A2.h(a))?null:function(){var d=$CLJS.sM.h(a),e=new $CLJS.Tg(null,new $CLJS.h(null,3,[$CLJS.o0,null,$CLJS.u0,null,$CLJS.Y_,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.Xk.l($CLJS.G([new $CLJS.h(null,3,[$CLJS.mG,$CLJS.p.h($CLJS.wG()),$CLJS.iD,$CLJS.iD.h(a),$CLJS.yi,$7(a)],null),function(){var d=$CLJS.t2(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.WQ,d],null):null}(),function(){var d=$CLJS.Y7.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.bI,d],
null):null}(),function(){var d=$CLJS.X7.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.XS,d],null):null}(),function(){var d=$CLJS.CM.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.gP,d],null):null}()]));b=($CLJS.n(b)?$CLJS.rC($CLJS.k0,$CLJS.T):$CLJS.rC($CLJS.Ri,$CLJS.T))(a);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,c,b],null)};
mpa=function(a,b){return $CLJS.bf(function(c){return $CLJS.F.g($CLJS.sM.h(c),$CLJS.e0)},$CLJS.c2.v(a,b,$CLJS.hY(a,b),new $CLJS.h(null,3,[$CLJS.K1,!1,$CLJS.R1,!0,$CLJS.H1,!1],null)))};$CLJS.Y7=new $CLJS.K("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);d8=new $CLJS.K(null,"parent-id","parent-id",-1400729131);$CLJS.W7=new $CLJS.K("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
e8=new $CLJS.K("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);npa=new $CLJS.K(null,"earliest","earliest",-1928154382);$CLJS.X7=new $CLJS.K("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);opa=new $CLJS.K(null,"latest","latest",24323665);var rpa;$CLJS.g2.m(null,$CLJS.WH,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rh.h(b),$CLJS.f2(c,new $CLJS.h(null,2,[$CLJS.bI,$CLJS.rh,$CLJS.XS,kpa],null)),a],null)});var V7=!1,f8=function f8(a,b){var d=$CLJS.l2(a,d8.h(b));a=$CLJS.n(d8.h(d))?f8.g?f8.g(a,d):f8.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.I.g(a,$CLJS.T);return $CLJS.Ik.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.W1.m(null,$CLJS.qM,function(a,b,c){return $7(c)});$CLJS.W1.m(null,$CLJS.WH,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null);d=$CLJS.O(d);d=$CLJS.I.g(d,$CLJS.bI);$CLJS.H(c,2,null);c=Z7(a,b,c);c=$CLJS.n(d)?$CLJS.S.j(c,$CLJS.Y7,d):c;return $CLJS.X1.j(a,b,c)});$CLJS.Y1.m(null,$CLJS.qM,function(a,b,c){a=$CLJS.O(c);b=$CLJS.I.g(a,$CLJS.T);return $CLJS.S.j(a,$CLJS.T,b)});$CLJS.Y1.m(null,$CLJS.WH,function(a,b,c){var d=Z7(a,b,c);b=$CLJS.a8(a,b,d,c);return $CLJS.n(d8.h(b))?f8(a,b):b});
$CLJS.S1.m(null,$CLJS.qM,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.I.g(c,$CLJS.cG),f=$CLJS.I.g(c,$CLJS.T),k=$CLJS.I.g(c,$CLJS.li),l=$CLJS.I.g(c,$CLJS.X7),m=$CLJS.I.g(c,$CLJS.x0),t=$CLJS.I.g(c,$CLJS.CM),u=$CLJS.I.g(c,$CLJS.DM);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.r2.g($CLJS.q2,f):$CLJS.p.h(f);$CLJS.F.g(d,$CLJS.v1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.l2(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.vC($CLJS.cG.h($CLJS.x1.j(a,b,t)),$CLJS.rY,"")):(u=$CLJS.s2(a,u),a=$CLJS.u1.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.t2(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.SC($CLJS.vC($CLJS.Zg(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.Q5(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.S1.m(null,$CLJS.WH,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.O(e);e=$CLJS.I.g(f,$CLJS.XS);var k=$CLJS.I.g(f,$CLJS.WQ),l=$CLJS.I.g(f,$CLJS.bI);f=$CLJS.I.g(f,$CLJS.gP);$CLJS.H(c,2,null);c=Z7(a,b,c);c=$CLJS.n(k)?$CLJS.S.j(c,$CLJS.x0,k):c;l=$CLJS.n(l)?$CLJS.S.j(c,$CLJS.li,l):c;e=$CLJS.n(e)?$CLJS.S.j(l,$CLJS.X7,e):l;e=$CLJS.n(f)?$CLJS.S.j(e,$CLJS.CM,f):e;return $CLJS.n(e)?$CLJS.u1.v(a,b,e,d):$CLJS.xG("[Unknown Field]")});
$CLJS.T1.m(null,$CLJS.qM,function(a,b,c){a=$CLJS.O(c);return $CLJS.I.g(a,$CLJS.T)});$CLJS.T1.m(null,$CLJS.WH,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=Z7(a,b,c);return $CLJS.n(c)?$CLJS.U1.j(a,b,c):"unknown_field"});
$CLJS.Z1.m(null,$CLJS.qM,function(a,b,c){return $CLJS.Xk.l($CLJS.G([function(){var d=$CLJS.Uh($CLJS.Z1,$CLJS.Wh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.sM.h(c),$CLJS.o0)?function(){var d=$CLJS.w0.h(c);return $CLJS.n(d)?(d=$CLJS.s1(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.pY,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.cG,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.k3.m(null,$CLJS.WH,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);$CLJS.H(a,2,null);return $CLJS.bI.h(b)});
$CLJS.k3.m(null,$CLJS.qM,function(a){return $CLJS.Y7.h(a)});$CLJS.h3.m(null,$CLJS.WH,function(a,b){$CLJS.H(a,0,null);var c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b)){var d=$CLJS.Gd($CLJS.cJ,b),e=$CLJS.fE(e8,$CLJS.yi,$CLJS.iD)(c);c=$CLJS.S.l(c,$CLJS.bI,b,$CLJS.G([$CLJS.yi,d?$CLJS.pj:e,e8,e]));return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,c,a],null)}b=e8.h(c);c=$CLJS.n(b)?$CLJS.xk.g($CLJS.S.j(c,$CLJS.yi,b),e8):c;c=$CLJS.xk.g(c,$CLJS.bI);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.WH,c,a],null)});
$CLJS.h3.m(null,$CLJS.qM,function(a,b){return $CLJS.n(b)?$CLJS.S.j(a,$CLJS.Y7,b):$CLJS.xk.g(a,$CLJS.Y7)});$CLJS.m3.m(null,$CLJS.WH,function(a,b,c){return $CLJS.n3.j(a,b,Z7(a,b,c))});
$CLJS.m3.m(null,$CLJS.qM,function(a,b,c){if($CLJS.Dk.g($CLJS.sM.h(c),$CLJS.e0)){a=$CLJS.rC($CLJS.yi,$CLJS.iD)(c);b=null==c?null:$CLJS.uP.h(c);if(null==b)var d=null;else try{var e=$CLJS.gF.h($CLJS.fj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.I.g(f,npa),l=$CLJS.I.g(f,opa),m=$CLJS.jpa.l($CLJS.G([$CLJS.P7.h(k),$CLJS.P7.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Ds.g?$CLJS.Ds.g(1,m):$CLJS.Ds.call(null,1,m))?$CLJS.nv:$CLJS.n($CLJS.Ds.g?$CLJS.Ds.g(31,m):$CLJS.Ds.call(null,31,m))?$CLJS.pv:$CLJS.n($CLJS.Ds.g?
$CLJS.Ds.g(365,m):$CLJS.Ds.call(null,365,m))?$CLJS.hj:$CLJS.qj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.hE(a,$CLJS.gF)?$CLJS.Qla:$CLJS.hE(a,$CLJS.IE)?$CLJS.Pla:$CLJS.hE(a,$CLJS.GF)?$CLJS.Ola:$CLJS.tf;d=$CLJS.n(d)?b8(e,$CLJS.Wh,d):e;return $CLJS.n($CLJS.Y7.h(c))?b8(d,$CLJS.C1,$CLJS.Y7.h(c)):d}return $CLJS.tf});
$CLJS.T5.m(null,$CLJS.WH,function(a){var b=null==a?null:$CLJS.tG(a);b=null==b?null:$CLJS.XS.h(b);return null==b?null:$CLJS.S.l(b,$CLJS.xj,$CLJS.S5,$CLJS.G([$CLJS.R5,function(c,d){return Z7(c,d,a)}]))});$CLJS.T5.m(null,$CLJS.qM,function(a){var b=null==a?null:$CLJS.X7.h(a);return null==b?null:$CLJS.S.l(b,$CLJS.xj,$CLJS.S5,$CLJS.G([$CLJS.R5,$CLJS.Le(a)]))});$CLJS.N5.m(null,$CLJS.WH,function(a,b){return $CLJS.uG(a,$CLJS.sG,$CLJS.G([$CLJS.XS,b]))});
$CLJS.N5.m(null,$CLJS.qM,function(a,b){return $CLJS.sG(a,$CLJS.X7,b)});$CLJS.U5.m(null,$CLJS.WH,function(a,b,c){return $CLJS.V5.j(a,b,Z7(a,b,c))});
$CLJS.U5.m(null,$CLJS.qM,function(a,b,c){b=$CLJS.O(c);c=$CLJS.I.g(b,$CLJS.yi);var d=$CLJS.I.g(b,$CLJS.uP),e=$CLJS.I.g(b,$CLJS.Pj);if($CLJS.Dk.g($CLJS.sM.h(b),$CLJS.e0)){var f=function(){var m=null==a?null:$CLJS.j2($CLJS.p1(a));m=null==m?null:$CLJS.ZB.h(m);return null==m?null:$CLJS.Gd(m,$CLJS.XS)}(),k=$CLJS.sC(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fj,$CLJS.Jj],null)),l=$CLJS.Q7(b);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.zd(v)){var x=$CLJS.kc(v),
A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var M=0;;)if(M<A){var V=$CLJS.kd(x,M),Z=D,fa=V;V=S7(V,l)?$CLJS.S.j(fa,$CLJS.C1,!0):fa;Z.add(V);M+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.lc(v))):$CLJS.re($CLJS.te(D),null)}var J=$CLJS.B(v);return $CLJS.ce(function(){var M=J;return S7(J,l)?$CLJS.S.j(M,$CLJS.C1,!0):M}(),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.On.h(k);return $CLJS.n(t)?$CLJS.ck.h(k):t}return f}())?null:$CLJS.hE(e,$CLJS.rj)?$CLJS.Hk.g(R7,
new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.P5(),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("Bin every 0.1 degrees"),$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.pG,$CLJS.pG,.1],null)],null),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("Bin every 1 degree"),$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.pG,$CLJS.pG,1],null)],null),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("Bin every 10 degrees"),$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.pG,$CLJS.pG,10],null)],null),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("Bin every 20 degrees"),
$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.pG,$CLJS.pG,20],null)],null)],null)):$CLJS.hE(c,$CLJS.Jj)&&!$CLJS.hE(e,$CLJS.gi)?$CLJS.Hk.g(R7,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.P5(),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("10 bins"),$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.aG,$CLJS.aG,10],null)],null),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("50 bins"),$CLJS.bG,new $CLJS.h(null,2,[$CLJS.qG,$CLJS.aG,$CLJS.aG,50],null)],null),new $CLJS.h(null,2,[$CLJS.cG,$CLJS.xG("100 bins"),$CLJS.bG,new $CLJS.h(null,2,
[$CLJS.qG,$CLJS.aG,$CLJS.aG,100],null)],null)],null)):null)}return $CLJS.tf});$CLJS.RX.m(null,$CLJS.WH,function(a){return a});
$CLJS.RX.m(null,$CLJS.qM,function(a){a=$CLJS.O(a);var b=$CLJS.I.g(a,$CLJS.sM);switch(b instanceof $CLJS.K?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.mG,$CLJS.p.h($CLJS.wG()),$CLJS.yi,$CLJS.rC($CLJS.yi,$CLJS.iD)(a)],null),a=$CLJS.Q1.h(a),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LH,b,a],null);case "source/expressions":return T7(a);case "source/breakouts":return $CLJS.Gd(a,$CLJS.NG)?T7(a):c8(a);default:return c8(a)}});
$CLJS.ppa=function(){function a(e,f,k){k=$CLJS.De($CLJS.Hk.g($CLJS.TX,k));var l=mpa(e,f),m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ml(function(t){return $CLJS.Y2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.tf);m=$CLJS.Gk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.Ze.h($CLJS.TX),m):null;return $CLJS.qY.l(e,f,$CLJS.sG,$CLJS.G([$CLJS.FG,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.g8=function(){function a(d,e){return $CLJS.FG.h($CLJS.hY(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.qpa=function(){function a(d,e){var f=$CLJS.c2.v(d,e,$CLJS.hY(d,e),new $CLJS.h(null,3,[$CLJS.K1,!1,$CLJS.R1,!1,$CLJS.H1,!1],null)),k=$CLJS.g8.g(d,e);return $CLJS.sd(k)?$CLJS.Hk.g(function(l){return $CLJS.S.j(l,$CLJS.m0,!0)},f):$CLJS.f4(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
rpa=function(){function a(d,e,f){var k=$CLJS.hY(d,e),l=$CLJS.F.g($CLJS.UD(f),$CLJS.LH)?$CLJS.a2:$CLJS.c2;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.Y2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.spa=function(){function a(d,e,f){f=$CLJS.U_.j(d,e,f);return rpa.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();