var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Z2=function(a,b){a=a instanceof $CLJS.K?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.K?b.T:null,b){case "equal-to":return $CLJS.xG("Equal to");case "default":return $CLJS.xG("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.K?b.T:null,b){case "not-equal-to":return $CLJS.xG("Not equal to");case "excludes":return $CLJS.xG("Excludes");case "default":return $CLJS.xG("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.K?b.T:null,b){case "after":return $CLJS.xG("After");case "default":return $CLJS.xG("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.K?b.T:null,b){case "before":return $CLJS.xG("Before");case "default":return $CLJS.xG("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.K?b.T:null,b){case "is-empty":return $CLJS.xG("Is empty");
case "default":return $CLJS.xG("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.K?b.T:null,b){case "not-empty":return $CLJS.xG("Not empty");case "default":return $CLJS.xG("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.K?b.T:null,b){case "default":return $CLJS.xG("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},$2=function(a,b){switch(a instanceof $CLJS.K?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return Z2(a,b)}},wla=new $CLJS.K(null,"after","after",594996914),xla=new $CLJS.K(null,"equal-to","equal-to",608296653),yla=new $CLJS.K(null,"excludes","excludes",-1791725945),zla=new $CLJS.K(null,"not-equal-to","not-equal-to",
-343181655),Ala=new $CLJS.K(null,"before","before",-1633692388);$CLJS.a3=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.xj,$CLJS.vL,$CLJS.PH,d,$CLJS.wL,e],null)}function b(d){return c.g?c.g(d,$CLJS.Wh):c.call(null,d,$CLJS.Wh)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.b3=new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.Cs),$CLJS.a3.h($CLJS.Gs),$CLJS.a3.h($CLJS.xH),$CLJS.a3.h($CLJS.Es),$CLJS.a3.h($CLJS.Is),$CLJS.a3.g($CLJS.wH,$CLJS.yH),$CLJS.a3.g($CLJS.IH,$CLJS.FH)],null);$CLJS.Bla=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.yH),$CLJS.a3.h($CLJS.FH),$CLJS.a3.h($CLJS.VH),$CLJS.a3.h($CLJS.OH),$CLJS.a3.h($CLJS.NH),$CLJS.a3.h($CLJS.gI)],null);
$CLJS.Cla=new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.a3.g($CLJS.nI,yla),$CLJS.a3.h($CLJS.Js),$CLJS.a3.g($CLJS.Gs,Ala),$CLJS.a3.g($CLJS.Cs,wla),$CLJS.a3.h($CLJS.xH),$CLJS.a3.g($CLJS.wH,$CLJS.yH),$CLJS.a3.g($CLJS.IH,$CLJS.FH)],null);$CLJS.Dla=new $CLJS.Q(null,8,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.DH),$CLJS.a3.h($CLJS.Cs),$CLJS.a3.h($CLJS.Gs),$CLJS.a3.h($CLJS.xH),$CLJS.a3.h($CLJS.Es),$CLJS.a3.h($CLJS.Is)],null);
$CLJS.Ela=new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.a3.g($CLJS.Js,xla),$CLJS.a3.g($CLJS.nI,zla),$CLJS.a3.h($CLJS.Cs),$CLJS.a3.h($CLJS.Gs),$CLJS.a3.h($CLJS.xH),$CLJS.a3.h($CLJS.Es),$CLJS.a3.h($CLJS.Is),$CLJS.a3.g($CLJS.wH,$CLJS.yH),$CLJS.a3.g($CLJS.IH,$CLJS.FH)],null);
$CLJS.Fla=new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.VH),$CLJS.a3.h($CLJS.OH),$CLJS.a3.h($CLJS.wH),$CLJS.a3.h($CLJS.IH),$CLJS.a3.h($CLJS.yH),$CLJS.a3.h($CLJS.FH),$CLJS.a3.h($CLJS.NH),$CLJS.a3.h($CLJS.gI)],null);$CLJS.Gla=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.wH),$CLJS.a3.h($CLJS.IH),$CLJS.a3.h($CLJS.yH),$CLJS.a3.h($CLJS.FH)],null);
$CLJS.Hla=new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.g($CLJS.wH,$CLJS.yH),$CLJS.a3.g($CLJS.IH,$CLJS.FH)],null);$CLJS.Ila=new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.a3.h($CLJS.Js),$CLJS.a3.h($CLJS.nI),$CLJS.a3.h($CLJS.wH),$CLJS.a3.h($CLJS.IH)],null);$CLJS.c3=new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.S.j($CLJS.a3.h($CLJS.Js),$CLJS.Wh,!0),$CLJS.a3.h($CLJS.Cs),$CLJS.a3.h($CLJS.Gs),$CLJS.a3.h($CLJS.Es),$CLJS.a3.h($CLJS.Is),$CLJS.a3.h($CLJS.nI)],null);
$CLJS.S1.m(null,$CLJS.vL,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.I.g(b,$CLJS.PH);b=$CLJS.I.g(b,$CLJS.wL);d=d instanceof $CLJS.K?d.T:null;switch(d){case "default":return $2(a,b);case "long":return Z2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.Z1.m(null,$CLJS.vL,function(a,b,c){c=$CLJS.O(c);a=$CLJS.I.g(c,$CLJS.PH);b=$CLJS.I.g(c,$CLJS.wL);c=$CLJS.I.g(c,$CLJS.Wh);a=new $CLJS.h(null,3,[$CLJS.P1,$CLJS.PC(a),$CLJS.cG,$2(a,b),$CLJS.w1,Z2(a,b)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});