var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.tools.reader.js");require("./cljs.tools.reader.edn.js");
'use strict';var aC,bC,Uba;$CLJS.$B=function(a){this.fd=a;this.J=null;this.C=2153775104;this.I=2048};aC=function(a){a=parseInt(a,10);return $CLJS.Sa(isNaN(a))?a:null};bC=function(a,b,c,d){if(!(a<=b&&b<=c))throw Error([$CLJS.p.h(d)," Failed:  ",$CLJS.p.h(a),"\x3c\x3d",$CLJS.p.h(b),"\x3c\x3d",$CLJS.p.h(c)].join(""));return b};
Uba=function(a){var b=$CLJS.fh(Sba,a);$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null),d=$CLJS.H(b,2,null),e=$CLJS.H(b,3,null),f=$CLJS.H(b,4,null),k=$CLJS.H(b,5,null),l=$CLJS.H(b,6,null),m=$CLJS.H(b,7,null),t=$CLJS.H(b,8,null),u=$CLJS.H(b,9,null),v=$CLJS.H(b,10,null);if($CLJS.Sa(b))throw Error(["Unrecognized date/time syntax: ",$CLJS.p.h(a)].join(""));a=aC(c);b=function(){var M=aC(d);return $CLJS.n(M)?M:1}();c=function(){var M=aC(e);return $CLJS.n(M)?M:1}();var x=function(){var M=aC(f);return $CLJS.n(M)?
M:0}(),A=function(){var M=aC(k);return $CLJS.n(M)?M:0}(),D=function(){var M=aC(l);return $CLJS.n(M)?M:0}(),J=function(){a:if($CLJS.F.g(3,$CLJS.E(m)))var M=m;else if(3<$CLJS.E(m))M=m.substring(0,3);else for(M=new $CLJS.Ea(m);;)if(3>M.getLength())M=M.append("0");else{M=M.toString();break a}M=aC(M);return $CLJS.n(M)?M:0}();t=($CLJS.F.g(t,"-")?-1:1)*(60*function(){var M=aC(u);return $CLJS.n(M)?M:0}()+function(){var M=aC(v);return $CLJS.n(M)?M:0}());return new $CLJS.Q(null,8,5,$CLJS.R,[a,bC(1,b,12,"timestamp month field must be in range 1..12"),
bC(1,c,Tba(b,0===(a%4+4)%4&&(0!==(a%100+100)%100||0===(a%400+400)%400)),"timestamp day field must be in range 1..last day in month"),bC(0,x,23,"timestamp hour field must be in range 0..23"),bC(0,A,59,"timestamp minute field must be in range 0..59"),bC(0,D,$CLJS.F.g(A,59)?60:59,"timestamp second field must be in range 0..60"),bC(0,J,999,"timestamp millisecond field must be in range 0..999"),t],null)};$CLJS.g=$CLJS.$B.prototype;$CLJS.g.$f=$CLJS.r;$CLJS.g.toString=function(){return this.fd};
$CLJS.g.equiv=function(a){return this.V(null,a)};$CLJS.g.V=function(a,b){return(a=null!=b?$CLJS.r===b.$f?!0:!1:!1)?this.fd===b.fd:a};$CLJS.g.da=function(a,b){return $CLJS.$b(b,['#uuid "',$CLJS.p.h(this.fd),'"'].join(""))};$CLJS.g.fa=function(){null==this.J&&(this.J=$CLJS.Bc(this.fd));return this.J};$CLJS.g.lc=function(a,b){if(b instanceof $CLJS.$B)return $CLJS.Ca(this.fd,b.fd);throw Error(["Cannot compare ",$CLJS.p.h(this)," to ",$CLJS.p.h(b)].join(""));};
$CLJS.cC=new $CLJS.K(null,"pattern","pattern",242135423);$CLJS.dC=new $CLJS.K(null,"replacement","replacement",-1836238839);var Tba,Sba;Tba=function(){var a=new $CLJS.Q(null,13,5,$CLJS.R,[null,31,28,31,30,31,30,31,31,30,31,30,31],null),b=new $CLJS.Q(null,13,5,$CLJS.R,[null,31,29,31,30,31,30,31,31,30,31,30,31],null);return function(c,d){return $CLJS.I.g($CLJS.n(d)?b:a,c)}}();Sba=/(\d\d\d\d)(?:-(\d\d)(?:-(\d\d)(?:[T](\d\d)(?::(\d\d)(?::(\d\d)(?:[.](\d+))?)?)?)?)?)?(?:[Z]|([-+])(\d\d):(\d\d))?/;
$CLJS.eC=$CLJS.Oe($CLJS.Xk.l($CLJS.G([new $CLJS.h(null,4,[new $CLJS.w(null,"inst","inst",-2008473268,null),function(a){if("string"===typeof a){var b=Uba(a);if($CLJS.n(b)){a=$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null),d=$CLJS.H(b,2,null),e=$CLJS.H(b,3,null),f=$CLJS.H(b,4,null),k=$CLJS.H(b,5,null),l=$CLJS.H(b,6,null);b=$CLJS.H(b,7,null);b=new Date(Date.UTC(a,c-1,d,e,f,k,l)-6E4*b)}else throw Error(["Unrecognized date/time syntax: ",$CLJS.p.h(a)].join(""));return b}throw Error("Instance literal expects a string for its timestamp.");
},$CLJS.Uj,function(a){if("string"===typeof a)return new $CLJS.$B(a.toLowerCase());throw Error("UUID literal expects a string as its representation.");},new $CLJS.w(null,"queue","queue",-1198599890,null),function(a){if($CLJS.yd(a))return $CLJS.Wf.g($CLJS.Ok,a);throw Error("Queue literal expects a vector for its elements.");},new $CLJS.w(null,"js","js",-886355190,null),function(a){if($CLJS.yd(a)){var b=[];a=$CLJS.z(a);for(var c=null,d=0,e=0;;)if(e<d){var f=c.X(null,e);b.push(f);e+=1}else if(a=$CLJS.z(a))c=
a,$CLJS.zd(c)?(a=$CLJS.kc(c),e=$CLJS.lc(c),c=a,d=$CLJS.E(a),a=e):(a=$CLJS.B(c),b.push(a),a=$CLJS.C(c),c=null,d=0),e=0;else break;return b}if($CLJS.wd(a)){b={};a=$CLJS.z(a);c=null;for(e=d=0;;)if(e<d){var k=c.X(null,e);f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);f=$CLJS.Zg(f);b[f]=k;e+=1}else if(a=$CLJS.z(a))$CLJS.zd(a)?(d=$CLJS.kc(a),a=$CLJS.lc(a),c=d,d=$CLJS.E(d)):(d=$CLJS.B(a),c=$CLJS.H(d,0,null),d=$CLJS.H(d,1,null),c=$CLJS.Zg(c),b[c]=d,a=$CLJS.C(a),c=null,d=0),e=0;else break;return b}throw Error("JS literal expects a vector or map containing only string or unqualified keyword keys");
}],null),$CLJS.N])));