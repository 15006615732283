var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var gJ,hJ,Nda,jJ,Oda,Pda,Qda,kJ,iJ;$CLJS.eJ=function(a,b){return $CLJS.ec($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.tf),d))},$CLJS.cc($CLJS.N),b))};$CLJS.fJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
gJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.hE(b,$CLJS.IE)?$CLJS.RI:$CLJS.hE(b,$CLJS.GF)?$CLJS.VF:$CLJS.hE(b,$CLJS.gF)?$CLJS.WF:null;return $CLJS.n(b)?$CLJS.dH(b,a):!0};
hJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.eJ(function(d){return $CLJS.hE($CLJS.AG(d),$CLJS.TE)},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.Dk.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.AG($CLJS.B(a));return $CLJS.Je(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(gJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
Nda=function(a){return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.vu,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.zj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Us,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iJ],null)],
null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Us,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,iJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.xu,function(b){b=$CLJS.O(b);b=$CLJS.I.g(b,$CLJS.wj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(hJ(b))].join("")}],null),$CLJS.Ke(hJ)],null)],null)};
jJ=function(a){var b=$CLJS.R,c=Nda(a);a=new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.vu,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,a],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.lG],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)],null)],null);
return new $CLJS.Q(null,3,5,b,[$CLJS.ht,c,a],null)};Oda=function(a){return $CLJS.Od($CLJS.gH,$CLJS.Ze.g(function(b){var c=$CLJS.AG(b),d=$CLJS.hE(c,$CLJS.MG);b=d?$CLJS.dH($CLJS.$H,b):d;return $CLJS.n(b)?$CLJS.Jj:c},a))};Pda=function(a){a=$CLJS.fJ(function(b){return!$CLJS.hE(b,$CLJS.TE)},$CLJS.Ze.g($CLJS.AG,a));return $CLJS.hE(a,$CLJS.MG)?$CLJS.Qj:a};Qda=function(a){return $CLJS.n($CLJS.Je(function(b){return $CLJS.hE($CLJS.AG(b),$CLJS.TE)},a))?Pda(a):Oda(a)};
kJ=new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.lJ=new $CLJS.K("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);iJ=new $CLJS.K("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.K("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ot,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ci,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.GG,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.IG],null)],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.K(null,"intervals","intervals",2096054013),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ms,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.X,iJ],null)],null)],null)],null),new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.et,new $CLJS.h(null,1,[$CLJS.vu,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.AG(a);return $CLJS.Ie(function(d){return gJ(d,c)},b)}],null)],null));
$CLJS.Y(kJ,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Us,new $CLJS.h(null,1,[$CLJS.On,2],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)],null));$CLJS.wI.g($CLJS.Ms,jJ($CLJS.Ms));$CLJS.wI.g($CLJS.lu,jJ($CLJS.lu));$CLJS.nH($CLJS.Ns,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,kJ],null)]));$CLJS.nH($CLJS.rI,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.St,kJ],null)]));
for(var mJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ms,$CLJS.lu,$CLJS.Ns],null)),nJ=null,oJ=0,pJ=0;;)if(pJ<oJ){var Rda=nJ.X(null,pJ);$CLJS.eH(Rda,$CLJS.lJ);pJ+=1}else{var qJ=$CLJS.z(mJ);if(qJ){var rJ=qJ;if($CLJS.zd(rJ)){var sJ=$CLJS.kc(rJ),Sda=$CLJS.lc(rJ),Tda=sJ,Uda=$CLJS.E(sJ);mJ=Sda;nJ=Tda;oJ=Uda}else{var Vda=$CLJS.B(rJ);$CLJS.eH(Vda,$CLJS.lJ);mJ=$CLJS.C(rJ);nJ=null;oJ=0}pJ=0}else break}$CLJS.zG.m(null,$CLJS.lJ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return Qda(a)});
$CLJS.lH($CLJS.vH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));$CLJS.eH($CLJS.vH,$CLJS.RG);
for(var tJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tI,$CLJS.oH,$CLJS.JH],null)),uJ=null,vJ=0,wJ=0;;)if(wJ<vJ){var Wda=uJ.X(null,wJ);$CLJS.lH(Wda,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));wJ+=1}else{var xJ=$CLJS.z(tJ);if(xJ){var yJ=xJ;if($CLJS.zd(yJ)){var zJ=$CLJS.kc(yJ),Xda=$CLJS.lc(yJ),Yda=zJ,Zda=$CLJS.E(zJ);tJ=Xda;uJ=Yda;vJ=Zda}else{var $da=$CLJS.B(yJ);$CLJS.lH($da,$CLJS.G([$CLJS.lu,$CLJS.DF,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));tJ=$CLJS.C(yJ);uJ=null;vJ=0}wJ=0}else break}
for(var AJ=$CLJS.z(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rH,$CLJS.UH,$CLJS.aI],null)),BJ=null,CJ=0,DJ=0;;)if(DJ<CJ){var aea=BJ.X(null,DJ);$CLJS.lH(aea,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));DJ+=1}else{var EJ=$CLJS.z(AJ);if(EJ){var FJ=EJ;if($CLJS.zd(FJ)){var GJ=$CLJS.kc(FJ),bea=$CLJS.lc(FJ),cea=GJ,dea=$CLJS.E(GJ);AJ=bea;BJ=cea;CJ=dea}else{var eea=$CLJS.B(FJ);$CLJS.lH(eea,$CLJS.G([$CLJS.lu,$CLJS.pj,new $CLJS.Q(null,
2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));AJ=$CLJS.C(FJ);BJ=null;CJ=0}DJ=0}else break}$CLJS.lH($CLJS.sH,$CLJS.G([new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.fl,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.X,$CLJS.XG],null)],null)]));
$CLJS.zG.m(null,$CLJS.sH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.hE($CLJS.AG(b),$CLJS.pj)&&$CLJS.hE($CLJS.AG(a),$CLJS.pj)?$CLJS.pj:$CLJS.DF});