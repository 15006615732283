var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var qma,rma,sma,e4,tma,j4,k4,l4,m4,uma,vma,wma,xma,yma,zma,Ama,Bma,Cma,w4,x4,y4,z4,Dma,Ema,v4,u4,Fma;$CLJS.c4=function(a,b){$CLJS.ab(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
qma=function(a){return function(b){var c=$CLJS.Re(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.vl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};rma=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.z($CLJS.sC(c,d)))?c:$CLJS.P.N($CLJS.XY,c,d,e,b)};$CLJS.d4=function(a,b){return $CLJS.$X(a)&&$CLJS.F.g($CLJS.B(a),b)};
sma=function(a,b){return $CLJS.B($CLJS.Sl(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.cC.h(c);c=$CLJS.dC.h(c);d=$CLJS.n($CLJS.gh(d,b))?$CLJS.vC(b,d,c):null}else d=null;return d},a))};e4=function(a){a=$CLJS.E2(a);var b=$CLJS.wC(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Gd($CLJS.q($CLJS.K2),$CLJS.E2(a).toLowerCase()):null,b=$CLJS.Sa(b)):b=null);return $CLJS.n(b)?a:sma($CLJS.Zb($CLJS.q($CLJS.G2)),a)};
$CLJS.f4=function(a,b,c,d){if($CLJS.z(c)){d=$CLJS.Hk.g($CLJS.TX,d);var e=$CLJS.Wf.j($CLJS.Ug,$CLJS.Ze.h(function(f){return $CLJS.Y2.v(a,b,f,c)}),d);return $CLJS.Hk.g(function(f){return $CLJS.S.j(f,$CLJS.m0,$CLJS.Gd(e,f))},c)}return null};$CLJS.g4=function(a){a=$CLJS.k3.h(a);return $CLJS.n(a)?new $CLJS.h(null,2,[$CLJS.xj,$CLJS.PI,$CLJS.li,a],null):null};$CLJS.h4=function(a){return $CLJS.F.g($CLJS.UD(a),$CLJS.NM)};
tma=function(a,b){return $CLJS.n($CLJS.FG.h(a))?$CLJS.Ik.j(a,$CLJS.FG,function(c){return $CLJS.vd(c)?$CLJS.Hk.g(function(d){return $CLJS.i4.g?$CLJS.i4.g(d,b):$CLJS.i4.call(null,d,b)},c):c}):a};
j4=function(a){if($CLJS.n(a)){var b=$CLJS.bf;a:try{if($CLJS.yd(a)&&4===$CLJS.E(a))try{if($CLJS.bd(a,0)instanceof $CLJS.K)try{var c=$CLJS.bd(a,2);if($CLJS.yd(c)&&3===$CLJS.E(c))try{var d=$CLJS.bd(c,0);if($CLJS.fe(d,$CLJS.WH))try{var e=$CLJS.bd(a,3);if($CLJS.yd(e)&&3===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.fe(f,$CLJS.WH)){$CLJS.bd(e,1);$CLJS.bd(e,2);$CLJS.bd(c,1);$CLJS.bd(c,2);$CLJS.bd(a,0);$CLJS.bd(a,1);var k=new $CLJS.Q(null,1,5,$CLJS.R,[!0],null)}else throw $CLJS.UW;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW)throw $CLJS.UW;throw l;}throw m;}else throw $CLJS.UW;
}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.UW){k=new $CLJS.Q(null,1,5,$CLJS.R,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ta,k)))}else a=null;return a};k4=function(a){if($CLJS.n(j4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};l4=function(a){return $CLJS.n(j4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
m4=function(a,b){var c=$CLJS.i4;if($CLJS.Sa(j4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[d,e,f,$CLJS.P.j(c,a,b)],null)};
uma=function(a,b,c){return $CLJS.sd($CLJS.VM.h(a))?a:$CLJS.n(b)?rma(a,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VM],null),function(d){return function l(f,k){try{if($CLJS.yd(k)&&3===$CLJS.E(k))try{var m=$CLJS.bd(k,0);if($CLJS.fe(m,$CLJS.WH))try{var t=$CLJS.bd(k,1);if(null!=t?t.C&256||$CLJS.r===t.kf||(t.C?0:$CLJS.Wa($CLJS.rb,t)):$CLJS.Wa($CLJS.rb,t))try{var u=$CLJS.I.j(t,$CLJS.WQ,$CLJS.eX);if($CLJS.F.g(u,b))return $CLJS.bd(k,2),$CLJS.i4.g?$CLJS.i4.g(k,c):$CLJS.i4.call(null,k,c);throw $CLJS.UW;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)throw $CLJS.UW;throw v;}throw x;}else throw $CLJS.UW;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.UW)return $CLJS.zY(l,f,k);throw v;}throw x;}}($CLJS.tf,d)}):$CLJS.Ik.j(a,$CLJS.VM,function(d){return $CLJS.Hk.g(function(e){return m4(e,$CLJS.G([c]))},d)})};
$CLJS.i4=function(a,b){var c=$CLJS.UD(a);switch(c instanceof $CLJS.K?c.T:null){case "field":return $CLJS.uG(a,$CLJS.sG,$CLJS.G([$CLJS.WQ,b]));case "metadata/column":return $CLJS.sG(a,$CLJS.z0,b);case "mbql/join":return c=$CLJS.t2(a),uma(tma($CLJS.sG(a,$CLJS.JG,b),b),c,b);default:return b=$CLJS.BC($CLJS.vA),$CLJS.n($CLJS.AC("metabase.lib.join",b))&&$CLJS.zC("metabase.lib.join",b,$CLJS.fy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Eh.l($CLJS.G([a]))])),null),a}};
$CLJS.n4=function(a,b,c){var d=$CLJS.S.l,e=$CLJS.rC($CLJS.X_,$CLJS.T)(c);a=$CLJS.u2($CLJS.JG.h(a),$CLJS.rC($CLJS.X_,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.S,c,$CLJS.X_,e,$CLJS.G([$CLJS.k0,b]))};vma=function(a,b){return $CLJS.Sa(b)?a:$CLJS.Hk.g(function(c){var d=l4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.t2(d))?null:m4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.o4=function(a,b){b=vma($CLJS.Hk.g($CLJS.UX,b),$CLJS.t2(a));return $CLJS.sG(a,$CLJS.VM,$CLJS.De(b))};
$CLJS.p4=function(a,b){b=b instanceof $CLJS.K?b:$CLJS.F.g(b,$CLJS.tf)?$CLJS.YB:$CLJS.De($CLJS.Wf.j($CLJS.tf,$CLJS.Fk.g($CLJS.Ze.h($CLJS.TX),function(){var c=$CLJS.t2(a);return $CLJS.n(c)?$CLJS.Ze.h(function(d){return $CLJS.i4(d,c)}):$CLJS.Rd}()),b));return $CLJS.sG(a,$CLJS.FG,b)};
wma=function(a,b){b=$CLJS.Sl(function(d){return $CLJS.Y2.g(d,a)},b);var c=$CLJS.fJ($CLJS.rC($CLJS.c1,$CLJS.d1),b);if($CLJS.n(c))return c;b=$CLJS.B(b);if($CLJS.n(b))return b;b=$CLJS.fJ($CLJS.c1,a);if($CLJS.n(b))return b;b=$CLJS.fJ($CLJS.d1,a);return $CLJS.n(b)?b:$CLJS.B(a)};xma=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.vC(a,/ id$/i,"")):null};
yma=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Bh(function(){return e4(b)});var d=new $CLJS.Bh(function(){return e4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
zma=function(a,b,c){var d=$CLJS.u1.g($CLJS.F.g($CLJS.xj.h(b),$CLJS.JX)?b:a,b),e=$CLJS.n(c)?xma($CLJS.u1.g(a,c)):null,f=yma(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.fh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
Ama=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.d4(l,$CLJS.WH)&&$CLJS.Dd($CLJS.Y2.v(a,b,l,e)))return $CLJS.i4(l,d);throw $CLJS.UW;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.UW)return $CLJS.zY(m,k,l);throw t;}throw u;}}($CLJS.tf,c)};
Bma=function(a,b,c,d,e,f){c=Ama(a,b,c,d,f);return function t(l,m){try{if($CLJS.yd(m)&&4===$CLJS.E(m))try{var u=$CLJS.bd(m,2);if($CLJS.d4(u,$CLJS.WH))try{var v=$CLJS.bd(m,3);if($CLJS.d4(v,$CLJS.WH)){var x=$CLJS.bd(m,3),A=$CLJS.bd(m,2),D=$CLJS.bd(m,0),J=$CLJS.bd(m,1),M=$CLJS.t2(A),V=$CLJS.t2(x);if($CLJS.Sa($CLJS.n(M)?M:V))return $CLJS.n($CLJS.Y2.v(a,b,x,e))?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,$CLJS.i4(A,d),x],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,$CLJS.i4(x,d)],null);if($CLJS.F.g(M,d)&&$CLJS.F.g(V,
d)){var Z=$CLJS.uG(A,$CLJS.xk,$CLJS.G([$CLJS.WQ])),fa=$CLJS.uG(x,$CLJS.xk,$CLJS.G([$CLJS.WQ])),Da=null==$CLJS.Y2.v(a,b,Z,e);var Ha=Da?$CLJS.Y2.v(a,b,fa,e):Da;return $CLJS.n(Ha)?new $CLJS.Q(null,4,5,$CLJS.R,[D,J,A,fa],null):new $CLJS.Q(null,4,5,$CLJS.R,[D,J,Z,x],null)}return m}throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){var mb=Qb;if(mb===$CLJS.UW)throw $CLJS.UW;throw mb;}throw Qb;}else throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.UW)throw $CLJS.UW;throw mb;}throw Qb;
}else throw $CLJS.UW;}catch(Qb){if(Qb instanceof Error){mb=Qb;if(mb===$CLJS.UW)return $CLJS.zY(t,l,m);throw mb;}throw Qb;}}($CLJS.tf,c)};Cma=function(a,b){var c=$CLJS.kY();$CLJS.c4(c,b);return c(a)};
$CLJS.q4=function(a,b,c){if($CLJS.Gd(c,$CLJS.JG))return c;var d=$CLJS.hY(a,b),e=$CLJS.c2.j(a,b,d),f=$CLJS.z($CLJS.bf($CLJS.Ta,function v(t,u){try{if($CLJS.yd(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Mk.j(u,0,1);if($CLJS.yd(x)&&1===$CLJS.E(x))try{var A=$CLJS.bd(x,0);if($CLJS.fe(A,$CLJS.WH))return new $CLJS.Q(null,1,5,$CLJS.R,[u],null);throw $CLJS.UW;}catch(J){if(J instanceof Error){var D=J;if(D===$CLJS.UW)throw $CLJS.UW;throw D;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.UW)throw $CLJS.UW;
throw D;}throw J;}else throw $CLJS.UW;}catch(J){if(J instanceof Error){D=J;if(D===$CLJS.UW)return $CLJS.cX(v,t,u);throw D;}throw J;}}($CLJS.tf,$CLJS.VM.h(c))));f=wma(e,f);var k=Cma(zma(a,c,f),$CLJS.Sl($CLJS.JG,$CLJS.fP.h(d))),l=$CLJS.a2.h($CLJS.n2.g(a,$CLJS.SM.h(c)));return $CLJS.i4($CLJS.Ik.j(c,$CLJS.VM,function(t){return $CLJS.Hk.g(function(u){return Bma(a,b,u,k,e,l)},t)}),k)};$CLJS.r4=function(a){return $CLJS.VM.h(a)};
$CLJS.s4=function(a){return $CLJS.Xk.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.xj,$CLJS.WM,$CLJS.qG,a],null),$CLJS.F.g(a,$CLJS.PG)?new $CLJS.h(null,1,[$CLJS.Wh,!0],null):null]))};$CLJS.t4=function(a,b){b=$CLJS.B($CLJS.SM.h(b));return $CLJS.n($CLJS.pX.h(b))?$CLJS.s1(a,$CLJS.pX.h(b)):$CLJS.n($CLJS.HQ.h(b))?$CLJS.q1(a,$CLJS.HQ.h(b)):null};
w4=function(a){a=$CLJS.eJ(function(d){return $CLJS.d1(d)?u4:$CLJS.c1(d)?v4:$CLJS.gv},a);var b=$CLJS.O(a);a=$CLJS.I.g(b,u4);var c=$CLJS.I.g(b,v4);b=$CLJS.I.g(b,$CLJS.gv);return $CLJS.Ye.l(a,c,$CLJS.G([b]))};x4=function(a,b,c,d){return $CLJS.Sa(c)?d:$CLJS.Hk.g(function(e){return $CLJS.n($CLJS.m0.h(e))?$CLJS.i3(e,$CLJS.g4(c)):e},$CLJS.f4(a,b,d,new $CLJS.Q(null,1,5,$CLJS.R,[c],null)))};y4=function(a,b,c,d){return $CLJS.fJ($CLJS.d1,$CLJS.c2.v(a,b,c,d))};
z4=function(a,b){var c=$CLJS.Ri.h(a);return $CLJS.n(c)?$CLJS.fJ(function(d){d=$CLJS.O(d);var e=$CLJS.I.g(d,$CLJS.l0);return $CLJS.c1(d)&&$CLJS.F.g(e,c)},b):null};Dma={};Ema=new $CLJS.K("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);v4=new $CLJS.K(null,"fk","fk",398442651);u4=new $CLJS.K(null,"pk","pk",-771936732);Fma=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.S1.m(null,$CLJS.NM,function(a,b,c){b=$CLJS.O(c);b=$CLJS.I.g(b,$CLJS.SM);b=$CLJS.H(b,0,null);c=$CLJS.O(b);b=$CLJS.I.g(c,$CLJS.HQ);c=$CLJS.I.g(c,$CLJS.pX);a=$CLJS.n(b)?$CLJS.cG.h($CLJS.q1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.y2(c):null;return $CLJS.n(a)?a:$CLJS.xG("Native Query")});$CLJS.Z1.m(null,$CLJS.NM,function(a,b,c){a=$CLJS.u1.j(a,b,c);c=$CLJS.JG.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.cG,a],null)});
$CLJS.Y1.m(null,$CLJS.NM,function(){throw $CLJS.ai("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.S1.m(null,$CLJS.WM,function(a,b,c){a=$CLJS.O(c);a=$CLJS.I.g(a,$CLJS.qG);a=a instanceof $CLJS.K?a.T:null;switch(a){case "left-join":return $CLJS.xG("Left outer join");case "right-join":return $CLJS.xG("Right outer join");case "inner-join":return $CLJS.xG("Inner join");case "full-join":return $CLJS.xG("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.Z1.m(null,$CLJS.WM,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.I.g(d,$CLJS.qG);c=$CLJS.I.g(d,$CLJS.Wh);a=new $CLJS.h(null,2,[$CLJS.P1,$CLJS.PC(e),$CLJS.cG,$CLJS.u1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.S.j(a,$CLJS.Wh,!0):a});
$CLJS.$1.m(null,$CLJS.NM,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.I.g(e,$CLJS.JG),k=$CLJS.I.j(e,$CLJS.FG,$CLJS.YB),l=$CLJS.I.g(e,$CLJS.SM);c=$CLJS.O(d);var m=$CLJS.I.g(c,$CLJS.M1);if($CLJS.F.g(k,$CLJS.YB))return null;var t="undefined"!==typeof $CLJS.GN&&"undefined"!==typeof Dma&&"undefined"!==typeof $CLJS.Gma&&"undefined"!==typeof $CLJS.A4?new $CLJS.Fc(function(){return $CLJS.A4},$CLJS.od(Fma,new $CLJS.h(null,1,[$CLJS.Vj,!0],null)),null):null,u=function(){var v=$CLJS.S.j(a,$CLJS.SM,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(k,$CLJS.pA)?$CLJS.a2.v(u,-1,$CLJS.qd(l),c):function(){return function A(x){return new $CLJS.le(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.zd(D)){var J=$CLJS.kc(D),M=$CLJS.E(J),V=$CLJS.oe(M);a:for(var Z=0;;)if(Z<M){var fa=$CLJS.kd(J,Z);fa=$CLJS.uG(fa,$CLJS.xk,$CLJS.G([$CLJS.WQ]));fa=$CLJS.t1.j(u,-1,fa);V.add(fa);Z+=1}else{J=!0;break a}return J?$CLJS.re($CLJS.te(V),A($CLJS.lc(D))):$CLJS.re($CLJS.te(V),null)}V=$CLJS.B(D);V=$CLJS.uG(V,$CLJS.xk,
$CLJS.G([$CLJS.WQ]));return $CLJS.ce($CLJS.t1.j(u,-1,V),A($CLJS.Kc(D)))}return null}},null,null)}(k)}();return $CLJS.Hk.g(function(v){v=$CLJS.S.j(v,$CLJS.x0,f);v=$CLJS.i4($CLJS.S.l(v,$CLJS.cG,$CLJS.u1.j(a,b,v),$CLJS.G([$CLJS.sM,$CLJS.h0])),f);return $CLJS.n4(e,m,v)},c)});$CLJS.b2.m(null,$CLJS.NM,function(a,b,c,d){return $CLJS.a2.v(a,b,$CLJS.S.j(c,$CLJS.FG,$CLJS.pA),d)});
var B4,Hma=$CLJS.Oe($CLJS.N),Ima=$CLJS.Oe($CLJS.N),Jma=$CLJS.Oe($CLJS.N),Kma=$CLJS.Oe($CLJS.N),Lma=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.aj,$CLJS.WD],null),$CLJS.aj,$CLJS.Nh.o?$CLJS.Nh.o():$CLJS.Nh.call(null));B4=new $CLJS.Xh($CLJS.xh.g("metabase.lib.join","join-clause-method"),$CLJS.UD,Lma,Hma,Ima,Jma,Kma);B4.m(null,$CLJS.NM,function(a){return a});B4.m(null,$CLJS.JX,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.xj,$CLJS.NM,$CLJS.SM,$CLJS.SM.h($CLJS.fY(a))],null))});
B4.m(null,$CLJS.wX,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.xj,$CLJS.NM,$CLJS.SM,new $CLJS.Q(null,1,5,$CLJS.R,[a],null)],null))});B4.m(null,$CLJS.KM,function(a){return $CLJS.SX(new $CLJS.h(null,2,[$CLJS.xj,$CLJS.NM,$CLJS.SM,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.h(null,2,[$CLJS.pX,$CLJS.Ri.h(a),$CLJS.xj,$CLJS.wX],null)],null)],null))});
B4.m(null,$CLJS.xM,function(a){var b=$CLJS.O(a),c=$CLJS.I.g(b,$CLJS.z0);a=$CLJS.I.g(b,Ema);b=B4.h(new $CLJS.h(null,3,[$CLJS.xj,$CLJS.wX,$CLJS.jD,new $CLJS.h(null,1,[$CLJS.mG,$CLJS.p.h($CLJS.wG())],null),$CLJS.HQ,$CLJS.Ri.h(b)],null));c=$CLJS.n(c)?$CLJS.i4(b,c):b;return $CLJS.n(a)?$CLJS.p4.g?$CLJS.p4.g(c,a):$CLJS.p4.call(null,c,a):c});
$CLJS.C4=function(){function a(d,e){return $CLJS.o4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.e3(B4.h(d),$CLJS.FG,$CLJS.pA)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Mma=function(){function a(d,e,f){var k=$CLJS.C4.h(f);f=$CLJS.sd($CLJS.r4.h?$CLJS.r4.h(k):$CLJS.r4.call(null,k))?function(){var m=$CLJS.t4.g?$CLJS.t4.g(d,k):$CLJS.t4.call(null,d,k);return $CLJS.D4.j?$CLJS.D4.j(d,e,m):$CLJS.D4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.o4(k,new $CLJS.Q(null,1,5,$CLJS.R,[f],null)):k;var l=$CLJS.q4(d,e,f);return $CLJS.qY.l(d,e,$CLJS.Ik,$CLJS.G([$CLJS.fP,function(m){return $CLJS.$d.g($CLJS.uf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.E4=function(){function a(d,e){return $CLJS.De($CLJS.I.g($CLJS.hY(d,e),$CLJS.fP))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Nma=function(){function a(d){d=$CLJS.j2($CLJS.p1(d));d=$CLJS.ZB.h(d);return $CLJS.Wf.j($CLJS.tf,$CLJS.Fk.g($CLJS.af($CLJS.Me($CLJS.Gd,d)),$CLJS.Ze.h($CLJS.s4)),new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.PG,$CLJS.EG,$CLJS.TG,$CLJS.WG],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Oma=function(){function a(d,e,f,k){var l=$CLJS.h4(f)?$CLJS.t2(f):null,m=$CLJS.Wf.j($CLJS.Ug,$CLJS.Fk.g($CLJS.Ze.h($CLJS.t2),qma(function(t){return $CLJS.Dk.g(t,l)})),$CLJS.E4.g(d,e));f=$CLJS.n(k)?k:$CLJS.h4(f)?k4($CLJS.B($CLJS.r4(f))):null;return w4(x4(d,e,f,$CLJS.Gk.g(function(t){t=$CLJS.t2(t);return $CLJS.n(t)?$CLJS.Gd(m,t):null},$CLJS.c2.v(d,e,$CLJS.hY(d,e),new $CLJS.h(null,1,[$CLJS.H1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.Pma=function(){function a(d,e,f,k,l){k=$CLJS.h4(f)?$CLJS.t4(d,f):f;var m=$CLJS.h4(f)?$CLJS.t2(f):null;f=$CLJS.n(l)?l:$CLJS.h4(f)?l4($CLJS.B($CLJS.r4(f))):null;f=$CLJS.n(f)?$CLJS.Sa(m)?$CLJS.uG(f,$CLJS.xk,$CLJS.G([$CLJS.WQ])):f:null;return w4(x4(d,e,f,$CLJS.Ze.g(function(t){t=$CLJS.S.j(t,$CLJS.sM,$CLJS.h0);return $CLJS.n(m)?$CLJS.i4(t,m):t},$CLJS.c2.v(d,e,k,new $CLJS.h(null,1,[$CLJS.H1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.Qma=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.c3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.c3};return b}();
$CLJS.D4=function(){function a(d,e,f){function k(u,v){return $CLJS.b4.l($CLJS.a3.h($CLJS.Js),u,$CLJS.G([v]))}var l=new $CLJS.h(null,2,[$CLJS.H1,!1,$CLJS.O1,!1],null),m=function(){var u=y4(d,e,f,l);if($CLJS.n(u)){if($CLJS.n(u)){var v=$CLJS.c2.j(d,e,$CLJS.hY(d,e));v=z4(u,v)}else v=null;return $CLJS.n(v)?k(v,u):null}return null}();if($CLJS.n(m))return m;m=y4(d,e,$CLJS.hY(d,e),null);if($CLJS.n(m)){var t=z4(m,$CLJS.c2.v(d,e,f,l));return $CLJS.n(t)?k(m,t):null}return null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Rma=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.h4(k)?k4($CLJS.B($CLJS.r4(k))):null;$CLJS.n(l)?(l=$CLJS.x1.j(e,f,l),l=$CLJS.sC(l,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.pY,$CLJS.cG],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.gY(e,f)){l=$CLJS.E4.g(e,f);var m=$CLJS.sd(l);k=m?m:$CLJS.h4(k)?$CLJS.F.g($CLJS.JG.h(k),$CLJS.JG.h($CLJS.B(l))):null;k=$CLJS.n(k)?$CLJS.l1(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.l1(e),k=$CLJS.q1(e,k),e=$CLJS.u1.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.xG("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Sma=function(){function a(d,e,f,k){f=$CLJS.UX.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);k=k instanceof $CLJS.K?k:$CLJS.li.h(k);e=$CLJS.gY(d,e);l=$CLJS.n3.j(d,e,l);d=$CLJS.n3.j(d,e,m);m=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.li,l)),k);d=null==k||$CLJS.Gd($CLJS.Yg($CLJS.Ze.g($CLJS.li,d)),k);f=m?$CLJS.Ik.v(f,2,$CLJS.i3,k):f;return d?$CLJS.Ik.v(f,3,$CLJS.i3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.V1.m(null,$CLJS.fP,function(a,b){var c=$CLJS.De($CLJS.E4.g(a,b));c=null==c?null:$CLJS.Ze.g(function(d){return $CLJS.u1.j(a,b,d)},c);return null==c?null:$CLJS.Gu(" + ",c)});